import React, { useState, useEffect, useContext, useRef } from "react";
// Material ui
import { Grid, Button, Box, makeStyles } from "@material-ui/core";
// Material ui icons
import { ChevronLeft, ChevronRight, Add } from "@material-ui/icons";
// Components
import MakeShiftDrawer, { Item } from "./MakeShiftDrawer";
import DisplayDevices from "./DisplayDevices";
import AddDeviceGroup from "./Modals/AddDeviceGroup";
import Loading from "./Loading";
import DeviceGroupsSettings from "./DeviceGroupsSettings";
import PendingCodes from "./Modals/PendingCodes";
// Utils
import { DeviceGroup } from "../@types/computer";
import useCompanyPage from "../hooks/useCompanyPage";
import useLocalStorage from "../hooks/useLocalStorage";
import { check } from "./Can";
import DataContext from "../context/DataContext";
import CompanyContext from "../context/CompanyContext";
import { getCompanyGroups } from "../utils/apiRequests";
import useRequestErrorHandler from "../hooks/useRequestErrorHandler";
import PrintQrCodesModal from "./Modals/PrintQrCodesModal";

const useStyles = makeStyles((theme) => ({
  buttons: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
  },
}));

const DisplayGroupsAndDevice: React.FC = () => {
  // Hooks
  const classes = useStyles();
  const { _id } = useCompanyPage();
  const [token] = useLocalStorage("token");
  const { user } = useContext(DataContext);
  const { groups, totalPendingCodes, setSelectedGroup } = useContext(CompanyContext);
  const groupsCtx = useRef(useContext(CompanyContext));
  const handleRequestErrors = useRef(useRequestErrorHandler());

  // States
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(0);
  const [deviceGroupModal, setDeviceGroupModal] = useState(false);
  const [pendingCodesModal, setPendingCodesModal] = useState(false);
  const [printQrCodesModal, setPrintQrCodesModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchGroups = async () => {
      setLoading(true);

      try {
        const groups = await getCompanyGroups(_id, token);
        groupsCtx.current.setGroups(groups.sort((a, b) => (a.name > b.name ? 1 : -1)));
      } catch (err) {
        handleRequestErrors.current(err);
      } finally {
        setLoading(false);
      }
    };

    fetchGroups();
  }, [_id, token]);

  useEffect(() => {
    if (selectedGroupIndex >= groups.length) {
      setSelectedGroupIndex(0);
      setOpenDrawer(false);
    }
  }, [groups.length, selectedGroupIndex]);

  // Display the group name and the total devices of the group
  const groupToListItemName = (group: DeviceGroup) => {
    return `${group.name} (${group.devices.length})`;
  };

  // Push "Add a group" to the end of the array and return it
  const sidebarData = () => {
    const data = groups.map((group): Item => ({ id: group._id, name: groupToListItemName(group) }));

    if (check(user.role, "devices:add-group")) {
      data.push({ id: "1", name: "Add a group", icon: <Add />, isSelectable: false });
    }

    return data;
  };

  // Check if a item needs to be selected or if it should show the dialog
  const handleSidebarChange = (index: number) => {
    if (index === groups.length) return setDeviceGroupModal(true);

    setSelectedGroupIndex(index);
    setSelectedGroup(groups[index]);
    toggleDrawer();
  };

  // Toggle the drawer state
  const toggleDrawer = () => setOpenDrawer((curr) => !curr);

  // Return the group that is current selected
  const getSelectedGroup = () => groups[selectedGroupIndex];

  return loading ? (
    <>
      <Loading message="Loading data..." />
    </>
  ) : (
    <>
      <Box display="flex" flexWrap="wrap" justifyContent="space-between" mb={1}>
        <Box>
          <Button
            onClick={toggleDrawer}
            startIcon={openDrawer ? <ChevronLeft /> : <ChevronRight />}
          >
            {getSelectedGroup()?.name || "Groups"}
          </Button>

          {selectedGroupIndex !== 0 && <DeviceGroupsSettings selectedGroup={getSelectedGroup()} />}
        </Box>

        <Box display="flex" flexWrap="wrap">
          <Button
            variant="outlined"
            className={classes.buttons}
            onClick={() => setPrintQrCodesModal(true)}
          >
            Print QR codes
          </Button>

          <Button
            variant="outlined"
            onClick={() => setPendingCodesModal(true)}
            disabled={totalPendingCodes <= 0}
            className={classes.buttons}
          >
            Activation codes ({totalPendingCodes})
          </Button>
        </Box>
      </Box>

      <Grid container spacing={1}>
        {/* Sidebar menu */}
        <Grid item>
          <MakeShiftDrawer open={openDrawer} data={sidebarData()} onSelect={handleSidebarChange} />
        </Grid>

        {/* Device cards */}
        <Grid item sm>
          {groups.map(
            (group, index) =>
              selectedGroupIndex === index && <DisplayDevices key={group._id} group={group} />
          )}
        </Grid>
      </Grid>

      {/* Modal to add a new device */}
      <AddDeviceGroup open={deviceGroupModal} onClose={() => setDeviceGroupModal(false)} />

      <PendingCodes open={pendingCodesModal} onClose={() => setPendingCodesModal(false)} />

      <PrintQrCodesModal open={printQrCodesModal} onClose={() => setPrintQrCodesModal(false)} />
    </>
  );
};

export default DisplayGroupsAndDevice;
