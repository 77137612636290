import React from "react";
import { Grid } from "@material-ui/core";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

interface Props {
  startValue: number | Date;
  startLabel?: string;
  startName?: string;
  endValue: number | Date;
  endLabel?: string;
  endName?: string;
  spacing?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  onChange: (date: MaterialUiPickersDate, label: string) => void;
  setError?: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
}

const DatePickerRange: React.FC<Props> = ({
  startValue,
  startLabel = "Start",
  startName,
  endValue,
  endLabel = "End",
  endName,
  spacing = 2,
  onChange,
  setError,
  disabled = false,
}) => {
  const handleError = (error: React.ReactNode) => {
    if (setError) {
      setError(error ? true : false);
    }
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid container spacing={spacing}>
        <Grid item xs={12} sm>
          <DateTimePicker
          name={startName}
            label={startLabel}
            inputVariant="outlined"
            fullWidth
            strictCompareDates
            showTodayButton
            ampm={false}
            disabled={disabled}
            maxDate={new Date(endValue)}
            onError={handleError}
            value={startValue}
            onChange={(date) => onChange(date, "start")}
          />
        </Grid>
        <Grid item xs={12} sm>
          <DateTimePicker
          name={endName}
            label={endLabel}
            inputVariant="outlined"
            fullWidth
            strictCompareDates
            showTodayButton
            ampm={false}
            minDate={new Date(startValue)}
            onError={handleError}
            disabled={disabled}
            value={endValue}
            onChange={(date) => onChange(date, "end")}
          />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default DatePickerRange;
