import React, { useContext, useMemo, useState, useRef, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
// Material ui
import { Breadcrumbs, Typography, makeStyles, Button } from "@material-ui/core";
// Icons
import { NavigateNext, Add, Edit } from "@material-ui/icons";
// Components
import UserGroupsForm from "../Modals/UserGroupsForm";
// Utils
import UserGroupContext from "../../context/UserGroupsContext";
import { getDetailsFromUserGroup } from "../../utils/apiRequests";
import useLocalStorage from "../../hooks/useLocalStorage";
import useRequestErrorHandler from "../../hooks/useRequestErrorHandler";

const linkStyle = {
  color: "inherit",
  textDecoration: "inherit",
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  },
  link: {
    ...linkStyle,
    "&:hover": { ...linkStyle },
    "&:focus": { ...linkStyle },
    "&:visited": { ...linkStyle },
    "&:link": { ...linkStyle },
    "&:active": { ...linkStyle },
  },
  buttonsContainer: {
    display: "flex",
    width: "auto",
    [theme.breakpoints.down("sm")]: { width: "100%" },
    [theme.breakpoints.down("xs")]: { flexWrap: "wrap" },
  },
  buttons: {
    margin: theme.spacing(1),
    [theme.breakpoints.down("sm")]: { width: "50%" },
    [theme.breakpoints.down("xs")]: { width: "100%" },
  },
}));

const pageTypes = { companies: "Companies", users: "Users" };

const Header: React.FC = () => {
  //Hooks
  const classes = useStyles();
  const { id, type } = useParams<{ id: string; type: string }>();
  const history = useHistory();
  const [token] = useLocalStorage("token");
  const isAdding = useRef(true);
  const ctx = useRef(useContext(UserGroupContext));
  const handleRequestErrors = useRef(useRequestErrorHandler());
  const {
    state: {
      groups,
      selectedGroup: { loading },
    },
  } = useContext(UserGroupContext);
  const selectedGroup = useMemo(
    () => groups.reduce((curr, acc) => (acc._id === id ? acc : curr), undefined),
    [groups, id]
  );

  // States
  const [openUserGroupForm, setOpenUserGroupForm] = useState(false);

  useEffect(() => {
    let active = true;
    let isFirst = true;

    const getUsersAndCompanies = async () => {
      ctx.current.dispatch({ type: "SET_LOADING", payload: true });

      try {
        const [{ users }, { companies }] = await Promise.all([
          getDetailsFromUserGroup(id, token, "users"),
          getDetailsFromUserGroup(id, token, "companies"),
        ]);

        if (active) {
          ctx.current.dispatch({
            type: "SET_SELECTED_GROUP",
            payload: { groupId: id, loading: false, users, companies, fetchedAt: new Date() },
          });
        }
      } catch (err) {
        handleRequestErrors.current(err);
        history.push("/dashboard/users/groups");
      } finally {
        ctx.current.dispatch({ type: "SET_LOADING", payload: false });
      }
    };

    const fetchInterval = setInterval(getUsersAndCompanies, 3 * 60 * 1000);

    if (isFirst) {
      getUsersAndCompanies();
      isFirst = false;
    }
    return () => {
      clearInterval(fetchInterval);
    };
  }, [history, id, token]);

  const openForm = (adding: boolean) => {
    isAdding.current = adding;
    setOpenUserGroupForm(true);
  };

  return (
    <div className={classes.root}>
      <Breadcrumbs separator={<NavigateNext />}>
        <Typography variant="h5" color="inherit">
          <Link to={`/dashboard/users/groups`} className={classes.link}>
            Groups
          </Link>
        </Typography>

        {type && (
          <Typography variant="h5" color="inherit">
            <Link to={`/dashboard/users/groups/${id}`} className={classes.link}>
              {selectedGroup?.name || "Group name"}
            </Link>
          </Typography>
        )}

        <Typography variant="h5" color="textPrimary">
          {type ? pageTypes[type] : selectedGroup?.name || "Group name"}
        </Typography>
      </Breadcrumbs>

      <div className={classes.buttonsContainer}>
        <Button
          variant="outlined"
          startIcon={<Edit />}
          className={classes.buttons}
          disabled={loading}
          onClick={() => openForm(false)}
        >
          Edit group
        </Button>

        <Button
          variant="outlined"
          startIcon={<Add />}
          onClick={() => openForm(true)}
          className={classes.buttons}
        >
          Add group
        </Button>
      </div>

      <UserGroupsForm
        open={openUserGroupForm}
        onClose={() => setOpenUserGroupForm(false)}
        group={isAdding.current ? undefined : selectedGroup}
      />
    </div>
  );
};

export default Header;
