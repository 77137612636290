import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import * as yup from "yup";
// Material ui
import { Button, Container, makeStyles, Typography } from "@material-ui/core";
// Components
import Loading from "../components/Loading";
import CustomTextField from "../components/Formik/CustomTextField";
// Utils
import api from "../utils/api";
import { errorMessages } from "../constants/form";
import { app } from "../constants/configs";
import useToast from "../hooks/useToast";
import useRequestErrorHandler from "../hooks/useRequestErrorHandler";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  submit: {
    marginTop: theme.spacing(2),
  },
}));

const getUserStatus = async (userId: string) => {
  const { data } = await api.get(`/auth/check-new-user/${userId}`);

  return data as { name: string; status: string };
};

const InvalidLinkMessage = () => (
  <>
    <Typography variant="h3" className="text-center">
      Invalid activation link
    </Typography>
    <Typography variant="subtitle1" className="text-center">
      You'll be redirect to the login page in few seconds...
    </Typography>
  </>
);

const initialValues = {
  password: "",
  repPassword: "",
};

const validationSchema = yup.object({
  password: yup
    .string()
    .required(errorMessages.requiredField)
    .min(8, errorMessages.atLeastXChar("Password", 8)),
  repPassword: yup.string().oneOf([yup.ref("password")], errorMessages.mustMatch("Password")),
});

const FirstAccess: React.FC = () => {
  // Hooks
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const toast = useToast();
  const handleRequestErrors = useRequestErrorHandler();
  const goHome = useCallback(
    (time?: number) => setTimeout(() => history.push("/"), time || 7000),
    [history]
  );

  // States
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({ name: "", isValid: true });

  document.title = `First access - ${app.shortName}`;

  useEffect(() => {
    let isCurrent = true;

    (async () => {
      try {
        const user = await getUserStatus(id);

        if (isCurrent) {
          setUser({ name: user.name, isValid: true });
        }
      } catch (err) {
        if (isCurrent) {
          setUser({ name: "", isValid: false });
          goHome();
        }
      } finally {
        if (isCurrent) setLoading(false);
      }
    })();

    return () => {
      isCurrent = false;
    };
  }, [goHome, id]);

  const handleSubmit = async (data: typeof initialValues) => {
    const { password } = data;

    try {
      await api.post(`/auth/register-new-user/${id}`, { password });

      toast({ open: true, type: "success", message: "Password successfully created" });
      goHome();
    } catch (err) {
      handleRequestErrors(err);
    }
  };

  return loading ? (
    <div className={classes.root}>
      <Loading />
    </div>
  ) : (
    <>
      <Container component="main" maxWidth="sm" className={classes.root}>
        {!user.isValid ? (
          <InvalidLinkMessage />
        ) : (
          <>
            <Typography variant="h4">
              Welcome to {app.longName}, {user.name}
            </Typography>
            <Typography variant="subtitle1">
              Please, input your password to access the dashboard
            </Typography>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form className="w-100">
                  <CustomTextField
                    name="password"
                    label="Password"
                    type="password"
                    required
                    disabled={isSubmitting}
                    fullWidth
                    autoComplete="current-password"
                    variant="outlined"
                    margin="normal"
                  />

                  <CustomTextField
                    name="repPassword"
                    label="Repeat password"
                    type="password"
                    required
                    disabled={isSubmitting}
                    fullWidth
                    autoComplete="current-password"
                    variant="outlined"
                    margin="normal"
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={isSubmitting}
                  >
                    Create password
                  </Button>
                </Form>
              )}
            </Formik>
          </>
        )}
      </Container>
    </>
  );
};

export default FirstAccess;
