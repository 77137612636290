import React from "react";
import { Computer } from "../../../@types/computer";
import EditComputerName from "./EditComputerName";

interface Props {
  computer: Computer;
}

const ComputerSettings: React.FC<Props> = ({ computer }) => {
  return (
    <div className="p-2">
      <EditComputerName computer={computer} />
    </div>
  );
};

export default ComputerSettings;
