import React from "react";
import { Redirect, useHistory } from "react-router-dom";
import useToast from "./useToast";

const useRequestErrorHandler = () => {
  // Hooks
  const history = useHistory();
  const toast = useToast();
  const currentUrl = window.location.href;

  const goToLogin = () => {
    window.localStorage.removeItem("token");
    return history.push(`/?return_to=${currentUrl}`);
  };

  return (err: any) => {
    if (
      err.response?.status === 401 ||
      err.status === 401 ||
      err.response?.data?.message?.includes("token")
    )
      return goToLogin();

    if (err.response?.status === 403 || err.status === 403) return <Redirect to="/dashboard" />;

    const message = err.response?.data?.message || err.message;

    toast({
      open: true,
      message: message || "An error has occurred. Please, contact the support",
      type: "error",
    });
  };
};

export default useRequestErrorHandler;
