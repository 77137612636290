import React from "react";
import {
  TableFooter as MuiTableFooter,
  TablePagination,
  TablePaginationProps,
  TableRow,
} from "@material-ui/core";

interface TableFooterProps {
  rowsPerPage: number;
  page: number;
  count: number;
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
  setPageSize: (pageSize: number) => void;
  rowsPerPageOptions?: TablePaginationProps["rowsPerPageOptions"];
}

export const TableFooter: React.FC<TableFooterProps> = ({ gotoPage, setPageSize, ...props }) => {
  return (
    <MuiTableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          onChangePage={(e, newPage) => gotoPage(newPage)}
          onChangeRowsPerPage={(e) => setPageSize(Number(e.target.value))}
          {...props}
        />
      </TableRow>
    </MuiTableFooter>
  );
};
