import React, { useState } from "react";
// Material ui
import { Snackbar, ThemeOptions } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
// Utils
import { UseToastProps } from "../hooks/useToast";

export const ThemeContext = React.createContext<{
  theme: ThemeOptions;
  setTheme: (newTheme: ThemeOptions) => void;
  setSnackbar: (newSnackbar: UseToastProps) => void;
}>({
  theme: {},
  setTheme: () => {},
  setSnackbar: () => {},
});

const ThemeProvider: React.FC = ({ children }) => {
  const [theme, setTheme] = useState<ThemeOptions>({});
  const [snackbar, setSnackbar] = useState<UseToastProps>({});

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme: (newTheme: ThemeOptions) => setTheme(newTheme),
        setSnackbar: (newSnackbar: UseToastProps) => setSnackbar(newSnackbar),
      }}
    >
      <Snackbar {...snackbar} onClose={() => setSnackbar((old) => ({ ...old, open: false }))}>
        <MuiAlert elevation={6} variant="filled" {...snackbar.alertProps} severity={snackbar.type}>
          {snackbar.message}
        </MuiAlert>
      </Snackbar>

      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
