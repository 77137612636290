import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
//Bootstrap
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
// Theme provider
import ThemeProvider from "./context/ThemeProvider";

ReactDOM.render(
  <ThemeProvider>
    <App />
  </ThemeProvider>,
  document.getElementById("root")
);
