import React from "react";
import { Page, Text, View, Document, StyleSheet, Link } from "@react-pdf/renderer";
import { Activity } from "../@types/computer";
import { User } from "../@types/users";

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 35,
  },
  title: {
    textAlign: "center",
    fontSize: 20,
    fontWeight: "extrabold",
  },
  userInformation: {
    marginTop: 14,
    marginBottom: 14,
    fontSize: 12,
  },
  table: {
    width: "auto",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableRowOdd: {
    margin: "auto",
    flexDirection: "row",
    backgroundColor: "#f2f2f2",
  },
  tableColHeader: {
    width: "25%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: 500,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
  },
  dateCell: {
    margin: 5,
    fontSize: 10,
    textAlign: "center",
  },
});

const convertTime = (time: string) => {
  const date = new Date(time);
  return date.toLocaleTimeString("pt-BR") + "\n" + date.toLocaleDateString("pt-BR");
};

// Create Document Component
const UserActivitiesPDF = (user: User, activities: Activity[]) => {
  const WEB_LINK = "https://continual-air-210502.web.app/dashboard";

  return (
    <Document>
      <Page style={styles.page}>
        <Text style={styles.title}>User activities report</Text>

        <View style={styles.userInformation}>
          <Text>User name: {user.name}</Text>
          <Text>User email: {user.email}</Text>
        </View>

        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Resource</Text>
            </View>

            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Action</Text>
            </View>

            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Company</Text>
            </View>

            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Timestamp</Text>
            </View>
          </View>

          {activities?.map((activity, index) => (
            <View
              style={index % 2 === 0 ? styles.tableRow : styles.tableRowOdd}
              key={index}
              wrap={false}
            >
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{activity.resource}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{activity.action}</Text>
              </View>
              <View style={styles.tableCol}>
                {activity.company ? (
                  <Link
                    src={`${WEB_LINK}/company/${activity.company._id}`}
                    style={styles.tableCell}
                  >
                    {activity.company.name}
                  </Link>
                ) : (
                  <Text style={styles.tableCell}>System</Text>
                )}
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.dateCell}>{convertTime(activity.timestamp)}</Text>
              </View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default UserActivitiesPDF;
