import rules from "../rbac-rules";
import PropTypes from "prop-types";

export const check = (role, action, data) => {
  const permissions = rules[role];
  if (!permissions) {
    // Role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;
  if (staticPermissions && staticPermissions.includes(action)) {
    // "Static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;
  if (dynamicPermissions) {
    const permissionsCondition = dynamicPermissions[action];
    if (!permissionsCondition) {
      // Dynamic rule not provided for action
      return false;
    }

    return permissionsCondition(data);
  }

  return false;
};

const Can = ({ role, perform, data, no, children }) => (check(role, perform, data) ? children : no);

Can.propTypes = {
  role: PropTypes.string.isRequired,
  perform: PropTypes.string.isRequired,
  no: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  children: PropTypes.element.isRequired,
  data: PropTypes.object,
};

Can.defaultProps = {
  no: null,
  children: null,
  data: null,
};

export default Can;
