const rules = {
  superAdmin: {
    static: [
      "companies:add",
      "companies:edit",
      "companies:delete",
      "devices:add",
      "devices:update",
      "devices:update-name",
      "devices:update-comment",
      "devices:move-devices",
      "devices:add-group",
      "devices:edit-group",
      "devices:delete-group",
      "devices:edit",
      "devices:delete",
      "devices:shutdown",
      "services:control",
      "services:add",
      "services:delete",
      "users:visit",
      "user-groups:visit",
      "qr-code:visit",
    ],
  },
  admin: {
    static: [
      "devices:add",
      "devices:update",
      "devices:update-name",
      "devices:update-comment",
      "devices:add-group",
      "devices:edit-group",
      "devices:delete-group",
      "devices:edit",
      "devices:delete",
      "devices:shutdown",
      "services:control",
      "services:add",
      "services:delete",
    ],
  },
  tech: {
    static: ["devices:update", "devices:update-name", "devices:update-comment", "services:control"],
  },
  viewer: {
    static: ["companies:visit"],
  },
};

export default rules;
