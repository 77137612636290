import React, { useState } from "react";
import clsx from "clsx";
// Material-ui
import { makeStyles, Drawer, Hidden, IconButton, Toolbar, useTheme } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
// Components
import ListCompanies from "./ListCompanies";
import { ChevronRight, ChevronLeft } from "@material-ui/icons";

// Styles
const drawerWidth = 260;
const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 6,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  containerToggleButton: {
    position: "relative",
    zIndex: theme.zIndex.drawer + 1,
  },
  toggleButton: {
    position: "fixed",
    left: (props) => props.drawerWidth,
    top: 76,
    marginLeft: -12,
    background: "#6f6f6f",
    padding: 0,
    transition: "ease 195ms",
    "&:hover": {
      background: "#9c9c9c",
    },
  },
}));

const DrawerMenu = ({ open, toggleDrawer, companies, history }) => {
  // States
  const [expandDrawer, setExpandDrawer] = useState(false);

  // Hooks
  const theme = useTheme();
  const classes = useStyles({ drawerWidth: expandDrawer ? drawerWidth : theme.spacing(7) + 6 });

  const toggleExpandDrawer = () => setExpandDrawer(!expandDrawer);

  return (
    <>
      {/* View for mobile */}
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          open={open}
          onClose={toggleDrawer}
          classes={{ paper: classes.drawerPaper }}
          ModalProps={{ keepMounted: true }}
        >
          <div className="d-flex flex-row align-items-center p-2">
            <IconButton
              onClick={toggleDrawer}
              color="primary"
              size="small"
              className={classes.closeMenuButton}
            >
              <CloseIcon />
            </IconButton>
          </div>

          <div>
            <ListCompanies companies={companies} history={history} toggleDrawer={toggleDrawer} />
          </div>
        </Drawer>
      </Hidden>

      {/* View for web */}
      <Hidden xsDown implementation="css">
        <div className={classes.containerToggleButton}>
          <IconButton onClick={toggleExpandDrawer} className={classes.toggleButton}>
            {expandDrawer ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </div>

        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: expandDrawer,
            [classes.drawerClose]: !expandDrawer,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: expandDrawer,
              [classes.drawerClose]: !expandDrawer,
            }),
          }}
        >
          <Toolbar className={classes.toolbar} />

          <ListCompanies
            companies={companies}
            history={history}
            expand={{ open: expandDrawer, set: (open) => setExpandDrawer(open) }}
          />
        </Drawer>
      </Hidden>
    </>
  );
};

export default DrawerMenu;
