import React, { useEffect, useRef } from "react";
import { useHistory, Redirect, useLocation } from "react-router-dom";
import * as yup from "yup";
// Material ui
import { Typography, makeStyles, Button, Container, Avatar } from "@material-ui/core";
// Formik
import { Formik, Form, FormikHelpers } from "formik";
// Components
import CustomTextField from "../components/Formik/CustomTextField";
// Icons
import { LockOutlined } from "@material-ui/icons";
// Utils
import api from "../utils/api";
import useLocalStorage from "../hooks/useLocalStorage";
import useRequestErrorHandler from "../hooks/useRequestErrorHandler";
import { app } from "../constants/configs";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    background: "white",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const NewLogin: React.FC = () => {
  // Hooks
  const classes = useStyles();
  const [token, setLocalStorage] = useLocalStorage("token");
  const history = useHistory();
  const location = useLocation();
  const isFirst = useRef(true);
  const isCurrent = useRef(true);
  const handleRequestErrors = useRequestErrorHandler();

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = yup.object({
    email: yup.string().email("Invalid email").required("Email is a required field"),
    password: yup.string().required("Password is a required field"),
  });

  document.title = `Login - ${app.shortName}`;

  useEffect(() => {
    return () => {
      isCurrent.current = false;
    };
  }, []);

  // Check if the user is logged in
  if (token !== "" && isFirst.current) {
    return <Redirect to="/dashboard" />;
  } else {
    isFirst.current = false;
  }

  const handleSubmit = async (
    data: typeof initialValues,
    { setSubmitting }: FormikHelpers<typeof initialValues>
  ) => {
    const { email, password } = data;
    setSubmitting(true);

    try {
      const { data } = await api.post("/auth/login", { email, password });
      setLocalStorage(data.token);

      const returnTo = new URLSearchParams(location.search).get("return_to");

      if (returnTo) {
        const url = new URL(returnTo);

        if (url.origin === window.location.origin) {
          return history.push(url.pathname);
        }
      }

      return history.push("/dashboard");
    } catch (err) {
      handleRequestErrors(err);
    } finally {
      if (isCurrent.current) setSubmitting(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs" className={classes.root}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlined />
        </Avatar>

        <Typography component="h1" variant="h5">
          Sign in
        </Typography>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className={classes.form}>
              <CustomTextField
                name="email"
                label="Email"
                type="email"
                required
                disabled={isSubmitting}
                fullWidth
                autoComplete="email"
                variant="outlined"
                margin="normal"
              />

              <CustomTextField
                name="password"
                label="Password"
                type="password"
                required
                disabled={isSubmitting}
                fullWidth
                autoComplete="current-password"
                variant="outlined"
                margin="normal"
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isSubmitting}
              >
                Sign in
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
};

export default NewLogin;
