import React, { useMemo, useContext } from "react";
import { useHistory } from "react-router-dom";
// Material ui
import { makeStyles, Typography, Divider, Theme } from "@material-ui/core";
import { TreeView, TreeItem } from "@material-ui/lab";
// Icons
import { Group, ArrowDropDown, ArrowRight, Business, Person, Poll } from "@material-ui/icons";
// Components
import CustomTreeItem from "../CustomTreeItem";
// Utils
import UserGroupContext from "../../context/UserGroupsContext";
import { UserGroup } from "../../@types/users";

interface Props {
  fullWidth?: true;
}

interface GroupTreeView {
  groups: UserGroup[];
}

const drawerWidth = 230;
const useStyles = makeStyles<Theme, { fullWidth: boolean }>((theme) => ({
  drawer: {
    height: "100%",
    minHeight: "100%",
    width: (props) => (props.fullWidth ? "100%" : drawerWidth),
    minWidth: (props) => (props.fullWidth ? "100%" : drawerWidth),
    background: theme.palette.background.paper,
  },
  title: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  treeView: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    "& .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label": {
      background: "transparent",
    },
  },
}));

const useGroupTreeViewStyles = makeStyles((theme) => ({
  groupItem: { marginBottom: theme.spacing(1) },
}));

// Component to display a list of TreeItems (UserGroups)
const DisplayGroupTreeView: React.FC<GroupTreeView> = ({ groups }) => {
  const history = useHistory();
  const classes = useGroupTreeViewStyles();

  return (
    <>
      {groups.map((group) => (
        <TreeItem
          key={group._id}
          nodeId={group._id}
          label={group.name}
          className={classes.groupItem}
        >
          <CustomTreeItem
            nodeId={`${group._id}-dashboard`}
            labelIcon={Poll}
            labelText="Dashboard"
            className="mt-2"
            onClick={() => history.push(`/dashboard/users/groups/${group._id}`)}
          />

          <CustomTreeItem
            nodeId={`${group._id}-companies`}
            labelIcon={Business}
            labelText="Companies"
            labelInfo={group.companies.length.toString()}
            className="mt-2"
            onClick={() => history.push(`/dashboard/users/groups/${group._id}/companies`)}
          />

          <CustomTreeItem
            nodeId={`${group._id}-users`}
            labelIcon={Person}
            labelText="Users"
            labelInfo={group.users.length.toString()}
            className="mt-2"
            onClick={() => history.push(`/dashboard/users/groups/${group._id}/users`)}
          />
        </TreeItem>
      ))}
    </>
  );
};

// Filter the groups by their status
const filterGroups = (group: UserGroup, status: "active" | "disabled") => group.status === status;

// Sort the groups in alphabetical order
const sortGroups = (a: UserGroup, b: UserGroup) => (a.name > b.name ? 1 : -1);

const Drawer: React.FC<Props> = ({ fullWidth = false }) => {
  // Hooks
  const classes = useStyles({ fullWidth });
  const {
    state: { groups },
  } = useContext(UserGroupContext);
  const [sortedActiveGroups, sortedDisabledGroups] = useMemo(
    () => [
      groups.filter((group) => filterGroups(group, "active")).sort(sortGroups),
      groups.filter((group) => filterGroups(group, "disabled")).sort(sortGroups),
    ],
    [groups]
  );

  return (
    <div className={classes.drawer}>
      <div className={classes.title}>
        <Group />

        <Typography variant="h6" className="ml-2">
          Groups
        </Typography>
      </div>

      <Divider className="mx-3" />

      <TreeView
        defaultCollapseIcon={<ArrowDropDown />}
        defaultExpandIcon={<ArrowRight />}
        className={classes.treeView}
      >
        <DisplayGroupTreeView groups={sortedActiveGroups} />

        {sortedDisabledGroups.length > 0 && (
          <>
            <div className="d-flex align-items-center text-center">
              <Divider className="flex-fill" />

              <Typography className="flex-fill">Disabled</Typography>

              <Divider className="flex-fill" />
            </div>

            <DisplayGroupTreeView groups={sortedDisabledGroups} />
          </>
        )}
      </TreeView>
    </div>
  );
};

export default Drawer;
