import React, { useContext, useEffect, useState } from "react";
// Material ui
import { Button, DialogActions, DialogContent } from "@material-ui/core";
// Components
import DialogBasic from "./DialogBasic";
import TransferList, { Item } from "../TransferList";
// Utils
import CompanyContext from "../../context/CompanyContext";
import { deviceTypes } from "../../utils/deviceUtils";
import useApi from "../../hooks/useApi";
import useCompanyPage from "../../hooks/useCompanyPage";

interface Props {
  open: boolean;
  onClose: () => void;
}

const PrintQrCodesModal: React.FC<Props> = ({ open, onClose }) => {
  // Hooks
  const { deviceCards } = useContext(CompanyContext);
  const company = useCompanyPage();
  const [api, handleRequestErrors] = useApi();

  // States
  const [unselectedDevices, setUnselectedDevices] = useState<Item[]>([]);
  const [selectedDevices, setSelectedDevices] = useState<Item[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!open || unselectedDevices.length || selectedDevices.length) return;

    setUnselectedDevices(
      deviceCards.map(({ id, name, type }) => ({
        id,
        label: name,
        secondaryLabel: deviceTypes.reduce(
          (prev, curr) => (curr.name === type ? curr.formalName : prev),
          type
        ),
      }))
    );
  }, [deviceCards, open, selectedDevices.length, unselectedDevices.length]);

  if (!open) return null;

  const handleClose = () => {
    setUnselectedDevices([]);
    setSelectedDevices([]);
    onClose();
  };

  const handlePrint = async () => {
    setLoading(true);

    try {
      const { data } = await api.post(
        `/devices/${company._id}/qrcodes`,
        { devices: selectedDevices.map(({ id, label }) => ({ id, name: label })) },
        { responseType: "blob" }
      );

      const pdf = new Blob([data], { type: "application/pdf" });
      saveAs(pdf, "qr-codes.pdf");

      setLoading(false);
      handleClose();
    } catch (err) {
      setLoading(false);
      handleRequestErrors(err);
    }
  };

  return (
    <DialogBasic open={open} onClose={handleClose} title="Print QR Codes" fullWidth={false}>
      <DialogContent>
        <TransferList
          leftList={unselectedDevices}
          setLeftList={setUnselectedDevices}
          rightList={selectedDevices}
          setRightList={setSelectedDevices}
          disabled={loading}
        />
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Cancel
        </Button>

        <Button color="primary" disabled={loading} onClick={handlePrint}>
          Print
        </Button>
      </DialogActions>
    </DialogBasic>
  );
};

export default PrintQrCodesModal;
