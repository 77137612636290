import React from "react";
// Material ui
import { CircularProgress, TextField } from "@material-ui/core";
import { Autocomplete, AutocompleteProps } from "@material-ui/lab";
// Formik
import { FieldAttributes, useField } from "formik";

type Props = Omit<AutocompleteProps<any, any, any, any>, "renderInput"> &
  FieldAttributes<{}> & {
    label: string;
    variant?: "standard" | "filled" | "outlined";
    helperText?: string;
    onChange?: (e: any, newValue: any) => void;
  };

const CustomAutocomplete: React.FC<Props> = ({ label, variant, helperText, ...props }) => {
  const [field, meta, helpers] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";

  return (
    <Autocomplete
      {...props}
      {...field}
      onChange={(e, newValues) => {
        helpers.setValue(newValues);
        if (props.onChange) props.onChange(e, newValues);
      }}
      renderInput={(inputProps) => (
        <TextField
          {...inputProps}
          label={label}
          variant={variant}
          helperText={errorText.toString() || helperText}
          error={!!errorText}
          InputProps={{
            ...inputProps.InputProps,
            endAdornment: (
              <>
                {props.loading ? <CircularProgress size={20} /> : null}
                {inputProps.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default CustomAutocomplete;
