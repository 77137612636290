import MUIDataTable from "mui-datatables";
import React from "react";
import { Company } from "../../@types/company";

interface Props {
  title?: string;
  data: Company[];
  loading?: boolean;
}

const columns = [
  { name: "id", label: "ID", options: { filter: false } },
  { name: "name", label: "Name", options: { filter: false } },
  { name: "addedDevices", label: "Added devices" },
  { name: "maxDevices", label: "Max devices" },
];

const CompaniesTable: React.FC<Props> = ({ title = "", data, loading = false }) => {
  return (
    <MUIDataTable
      title={title}
      columns={columns}
      data={
        loading
          ? [["Loading companies.."]]
          : data.map((comp, index) => ({
              id: ++index,
              name: comp.name,
              addedDevices: comp.totalDevices!,
              maxDevices: comp.maxDevices || "Not configured",
            }))
      }
      options={{ download: false, print: false, selectableRows: "none" }}
    />
  );
};

export default CompaniesTable;
