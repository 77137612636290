import Axios, { AxiosInstance } from "axios";
import { backend } from "../utils/configs";
import useLocalStorage from "./useLocalStorage";
import useRequestErrorHandler from "./useRequestErrorHandler";

type UseApiProps = (options?: {
  skipAuthorization?: boolean;
}) => [AxiosInstance, (err: any) => void];

const useApi: UseApiProps = ({ skipAuthorization = false } = {}) => {
  const [token] = useLocalStorage("token");
  const handleErrorRequest = useRequestErrorHandler();

  const api = Axios.create({
    baseURL: backend.api,
    headers: !skipAuthorization ? { Authorization: `Bearer ${token}` } : undefined,
  });

  return [api, handleErrorRequest];
};

export default useApi;
