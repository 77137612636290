import React, { useContext, useState, useMemo } from "react";
import semver from "semver";
// Material ui
import { IconButton, Tooltip } from "@material-ui/core";
// Icons
import { GetApp, Power } from "@material-ui/icons";
// Components
import Can from "./Can";
// Hooks
import useToast from "../hooks/useToast";
import useRequestErrorHandler from "../hooks/useRequestErrorHandler";
// Utils
import DataContext from "../context/DataContext";
import { Computer } from "../@types/computer";
import { newApi } from "../utils/api";

interface Props {
  computer: Computer;
  updateDisabled?: boolean;
  displayIcon?: boolean;
}

const DisplayConnectorVersion: React.FC<Props> = ({ computer, displayIcon = false }) => {
  // Hooks
  const { user, system } = useContext(DataContext);
  const toast = useToast();
  const requestErrorHandler = useRequestErrorHandler();

  // States
  const [open, setOpen] = useState(false);
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(false);

  const { connectorVersion } = computer;

  // Check if the device is able to be updated
  useMemo(() => {
    const { commands, connected, connectorVersion } = computer;

    setIsUpdateDisabled(
      (commands && commands.updateConnector) ||
        (connected && !connected.connected) ||
        connectorVersion === "1.0.0"
        ? true
        : false
    );
  }, [computer]);

  // Display a message saying about the update
  const getUpdatePhrase = () => {
    if (connectorVersion === "1.0.0")
      return "Unfortunately this connector doesn't support auto-update, you'll need to update it manually";

    if (computer.connected && !computer.connected.connected)
      return "The computer is disconnected, please connect it again to update the connector";

    if (computer.commands && computer.commands.updateConnector)
      return "The connector is being updated";

    return "New version available, click here to update it";
  };

  // Send command to update the connector
  const handleUpdate = async () => {
    if (!isUpdateDisabled) {
      try {
        const { data } = await newApi().post(`/devices/computers/${computer.id}/update-client`);

        toast({
          open: true,
          type: "success",
          message: data.message || "Command successfully sent",
        });
      } catch (err) {
        requestErrorHandler(err);
      }
    }
  };

  return (
    <div className="d-flex align-items-center">
      {displayIcon && <Power className="mr-2" />}

      <p className="mt-auto mb-auto">
        <strong>Connector version:</strong> {connectorVersion}
      </p>

      {semver.lt(connectorVersion, system.connectorVersion) && (
        <Can role={user.role} perform="devices:update">
          <Tooltip
            title={<span style={{ fontSize: "12px" }}>{getUpdatePhrase()}</span>}
            arrow
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
          >
            <span onClick={() => setOpen(true)}>
              <IconButton onClick={handleUpdate} disabled={isUpdateDisabled}>
                <GetApp />
              </IconButton>
            </span>
          </Tooltip>
        </Can>
      )}
    </div>
  );
};

export default DisplayConnectorVersion;
