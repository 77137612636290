import React, { useState, useEffect, useCallback, useContext, useRef } from "react";
// Material ui
import {
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Typography,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
// Components
import DialogBasic from "./DialogBasic";
import TransferList from "../TransferList";
// Utils
import api from "../../utils/api";
import useCompanyPage from "../../hooks/useCompanyPage";
import useLocalStorage from "../../hooks/useLocalStorage";
import { DeviceGroup } from "../../@types/computer";
import { getDeviceTypeNames, getDeviceCards } from "../../utils/deviceUtils";
import { getCompanyGroups } from "../../utils/apiRequests";
import CompanyContext from "../../context/CompanyContext";
import useToast from "../../hooks/useToast";
import useRequestErrorHandler from "../../hooks/useRequestErrorHandler";

interface Props {
  open: boolean;
  onClose: () => void;
  group?: DeviceGroup;
}

interface ItemType {
  id: string;
  label: string;
  secondaryLabel?: string;
}

const useStyles = makeStyles((theme) => ({
  errorDevices: {
    color: theme.palette.error.dark,
    fontWeight: "bold",
    textAlign: "center",
    marginTop: theme.spacing(0.5),
  },
}));

const fetchDevices = async (id: string, token: string) => {
  const { data } = await api.get(`/devices/nameAndType/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data.devices.map((device) => ({
    id: device.id,
    label: device.name,
    secondaryLabel: getDeviceTypeNames(device.type).formalName,
  })) as ItemType[];
};

const AddDeviceGroup: React.FC<Props> = ({ open, onClose, group }) => {
  // Hooks
  const [token] = useLocalStorage("token");
  const classes = useStyles();
  const company = useCompanyPage();
  const { setGroups, setDeviceCards } = useContext(CompanyContext);
  const toast = useToast();
  const handleRequestErrors = useRef(useRequestErrorHandler());

  // States
  const [isEditing, setIsEditing] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [leftList, setLeftList] = useState<ItemType[]>([]);
  const [rightList, setRightList] = useState<ItemType[]>([]);
  const [errorGroupName, setErrorGroupName] = useState("");
  const [errorDevices, setErrorDevices] = useState("");
  const [loading, setLoading] = useState(false);

  // Rearrange devices if it will edit a group
  const rearrangeDevices = useCallback(() => {
    if (group) {
      setIsEditing(true);
      setGroupName(group.name);

      // Remove the selected IDs from the left list and add it to an temp var
      const selectedDevices: ItemType[] = [];
      setLeftList((value) =>
        value.filter((item) => {
          if (group.devices.includes(item.id)) {
            selectedDevices.push(item);
            return false;
          } else {
            return true;
          }
        })
      );

      // Add the values from the temp var to the right list
      setRightList(selectedDevices);
    }
  }, [group]);

  useEffect(() => {
    const getDevices = async () => {
      setLoading(true);

      try {
        const devices = await fetchDevices(company._id, token);
        setLeftList(devices);

        if (group) {
          rearrangeDevices();
        }
      } catch (err) {
        handleRequestErrors.current(err);
      } finally {
        setLoading(false);
      }
    };

    if (open) {
      getDevices();
    }
  }, [company._id, token, open, rearrangeDevices, group]);

  // Clean all of the error messages
  const cleanErrorMessages = () => {
    setErrorGroupName("");
    setErrorDevices("");
  };

  // Handle modal close
  const handleModalClose = () => {
    cleanErrorMessages();
    setLeftList([]);
    setRightList([]);
    setGroupName("");

    onClose();
  };

  // Validate form values
  const validateFormValues = () => {
    cleanErrorMessages();

    if (loading) return false;

    if (groupName.trim() === "") {
      setErrorGroupName("This field is required");
      return false;
    }

    if (rightList.length <= 0) {
      setErrorDevices("You must select at least one device to create a group");
      return false;
    }

    return true;
  };

  // Form submit
  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (!validateFormValues()) return;

      const requestConfigs = { headers: { Authorization: `Bearer ${token}` } };
      const requestData = {
        name: groupName,
        companyId: company._id,
        devices: rightList.map((item) => item.id),
      };

      if (!isEditing) {
        await api.post("/deviceGroups", requestData, requestConfigs);
      } else {
        await api.put(`/deviceGroups/${group?._id}`, requestData, requestConfigs);
      }

      // Update the groups and device cards values
      const [groups, deviceCards] = await Promise.all([
        getCompanyGroups(company._id, token),
        getDeviceCards(group?._id || "0", company._id, token),
      ]);

      setGroups(groups);
      setDeviceCards(deviceCards);

      toast({
        open: true,
        type: "success",
        message: `Device group successfully ${!isEditing ? "created" : "edited"}`,
      });
      handleModalClose();
    } catch (err) {
      handleRequestErrors.current(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DialogBasic
        open={open}
        onClose={handleModalClose}
        title={!isEditing ? "Add a new device group" : `Edit ${group?.name}`}
        widthSize="md"
      >
        <form onSubmit={handleFormSubmit}>
          <DialogContent>
            <TextField
              label="Group name"
              fullWidth
              required
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
              disabled={loading}
              error={!!errorGroupName}
              helperText={errorGroupName}
            />

            <div className="m-3">
              <TransferList
                leftLabel="Unselected devices"
                leftList={leftList}
                setLeftList={setLeftList}
                rightLabel="Selected devices"
                rightList={rightList}
                setRightList={setRightList}
                disabled={loading}
                loading={loading}
              />
              {!!errorDevices && (
                <Typography variant="subtitle2" className={classes.errorDevices}>
                  {errorDevices}
                </Typography>
              )}
            </div>
          </DialogContent>

          <DialogActions>
            <Button color="primary" onClick={handleModalClose}>
              Cancel
            </Button>

            <Button color="primary" type="submit" disabled={loading}>
              {!isEditing ? "Add group" : "Edit group"}
            </Button>

            {loading && <CircularProgress />}
          </DialogActions>
        </form>
      </DialogBasic>
    </>
  );
};

export default AddDeviceGroup;
