import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
// Material ui
import { Box, Button, Divider } from "@material-ui/core";
// Icons
import { Add } from "@material-ui/icons";
// Hooks
import { useQrCodes } from "hooks/useQrCodes";
import DataContext from "context/DataContext";
// Components
import { QrCodesTable } from "components/Tables/QrCodesTable";
import { check } from "components/Can";
import { QrCodeForm } from "components/Modals/QrCodeForm";
import Loading from "components/Loading";
// Hooks
import { useDialog } from "hooks/useDialog";

export const QrCodes: React.FC = () => {
  // Hooks
  const { qrCodes, isLoading } = useQrCodes();
  const { user } = useContext(DataContext);
  const { open, openDialog, closeDialog } = useDialog();

  if (!check(user.role, "qr-code:visit")) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Box p={2}>
      <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center">
        <h3>QR codes</h3>

        <Button variant="outlined" startIcon={<Add />} onClick={() => openDialog()}>
          Add QR codes
        </Button>
      </Box>

      <Divider className="mt-3 mb-4" />

      {isLoading ? <Loading message="Loading QR codes" /> : <QrCodesTable qrCodes={qrCodes} />}

      <QrCodeForm open={open} onClose={closeDialog} />
    </Box>
  );
};
