import useSWR from "swr";
import { fetcher } from "utils/fetcher";
import { QrCode } from "../@types/QrCode";
import useLocalStorage from "./useLocalStorage";
import useRequestErrorHandler from "./useRequestErrorHandler";

type UseQrCodes = () => {
  qrCodes: QrCode[];
  isLoading: boolean;
  error: string;
  mutate: (data?: any) => void;
};

export const useQrCodes: UseQrCodes = () => {
  const [token] = useLocalStorage("token");
  const { data, error, mutate } = useSWR("/qrcodes", (url) => fetcher(url, token));
  const handleRequestError = useRequestErrorHandler();

  if (error) {
    handleRequestError(error);
  }

  return {
    qrCodes: data?.qrCodes || [],
    isLoading: !error && !data,
    error,
    mutate,
  };
};
