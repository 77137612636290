import useSWR from "swr";
import { fetcher } from "utils/fetcher";
import useRequestErrorHandler from "./useRequestErrorHandler";

interface DeviceNameAndType {
  id: string;
  name: string;
  type: "string";
}

type UseDevicesNameAndTypes = (companyId: string) => {
  devices: DeviceNameAndType[];
  isLoading: boolean;
  error: any;
  mutate: (data?: any, shouldRevalidate?: boolean) => Promise<any>;
};

export const useDevicesNameAndType: UseDevicesNameAndTypes = (companyId) => {
  const { data, error, mutate } = useSWR<{
    devices: DeviceNameAndType[];
  }>(`/devices/nameAndType/${companyId}`, fetcher);
  const handleRequestError = useRequestErrorHandler();

  if (error) {
    handleRequestError(error);
  }

  return {
    devices: data?.devices ?? [],
    isLoading: !data && !error,
    error,
    mutate,
  };
};
