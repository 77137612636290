import React from "react";
import { useHistory } from "react-router";
// Material ui
import {
  Typography,
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
// Util
import { WinBackupData } from "../../@types/computerCommands";

interface Props {
  backupData: WinBackupData[] | string;
}

const useStyles = makeStyles(() => ({
  secondary: {
    color: red[400],
  },
}));

const getBackupValue = (key: string, backupObj: WinBackupData) =>
  Object.entries(backupObj).reduce((prev, curr) => (curr[0].includes(key) ? curr[1] : prev), "");

const BackupTable: React.FC<Props> = ({ backupData }) => {
  // Hooks
  const classes = useStyles();
  const history = useHistory();

  // Display message if the Windows Backup command line is not installed
  if (typeof backupData !== "object") {
    if (backupData.includes("token")) {
      window.localStorage.removeItem("token");
      history.push(`/?return_to=${window.location.href}`);
      return null;
    } else {
      return (
        <div className="text-center mt-5 mb-5">
          <Typography variant="h6" color="error">
            Windows backup is not configured or commands are not available
          </Typography>
          <Typography variant="body2" className={classes.secondary}>
            Make sure that Windows Server Backup and command-line tools are installed and properly
            configured to use this function.
          </Typography>
        </div>
      );
    }
  }

  // Display message if it couldn't find any backup
  if (backupData.length <= 0) {
    return (
      <Typography variant="h6" className="text-center m-5">
        No previous backup data found
      </Typography>
    );
  }

  // Change Status text color
  const getColorStatus = (status: string) => {
    return status?.toLowerCase().includes("completed") ? "success" : "danger";
  };

  return (
    <TableContainer className="MuiPaper-outlined MuiPaper-rounded">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Version ID</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Start time</TableCell>
            <TableCell>End time</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {backupData.map((row, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {index + 1}
              </TableCell>

              <TableCell>{getBackupValue("VersionId", row)}</TableCell>

              <TableCell className={`text-${getColorStatus(getBackupValue("JobState", row))}`}>
                {getBackupValue("JobState", row)}
              </TableCell>

              <TableCell>{getBackupValue("StartTime", row)}</TableCell>

              <TableCell>{getBackupValue("EndTime", row)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BackupTable;
