import React, { useState } from "react";
// Material ui
import { Typography, IconButton } from "@material-ui/core";
// Material icons
import { Visibility, VisibilityOff } from "@material-ui/icons";

interface Props {
  title: String;
  message: String;
  secret?: boolean;
  icon?: React.ReactNode;
  className?: string;
}

const DisplayInfo: React.FC<Props> = ({ title, message, secret = false, icon, className }) => {
  const [showValue, setShowValue] = useState(secret);

  return (
    <div className={`d-flex align-items-center ${className}`}>
      {icon && <span className="mr-2">{icon}</span>}

      <Typography variant="body2">
        <strong>{title}:</strong> {secret && showValue ? "********" : message}
      </Typography>

      {secret && (
        <IconButton onClick={() => setShowValue(!showValue)}>
          {showValue ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      )}
    </div>
  );
};

export default DisplayInfo;
