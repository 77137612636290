import React, { useMemo } from "react";
import {
  Column,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
// Material ui
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
// Components
import { QrCodesTableToolbar } from "./QrCodesTableToolbar";
import { CollapsibleRow } from "./CollapsibleRow";
// Cells
import { IdCell } from "./IdCell";
import { ActionCell } from "./ActionCell";
import { StatusCell } from "./StatusCell";
import { TableFooter } from "../TableFooter";
import { IndeterminateCheckbox } from "../IndeterminateCheckbox";
// Utils
import { QrCode } from "../../../@types/QrCode";
import useToast from "hooks/useToast";
import { exportDataToCsv } from "utils/functions";

interface ReactTableProps {
  qrCodes: QrCode[];
}

export const QrCodesTable: React.FC<ReactTableProps> = ({ qrCodes }) => {
  // Hooks
  const toast = useToast();
  const columns = useMemo(
    () =>
      [
        { Header: "ID", accessor: "_id", Cell: IdCell },
        { Header: "Action", accessor: "action", Cell: ActionCell },
        { Header: "Status", accessor: "status", Cell: StatusCell },
        {
          Header: "Created at",
          accessor: "createdAt",
          Cell: ({ cell }) => new Date(cell.value).toLocaleString(),
        },
      ] as Column<QrCode>[],
    []
  );
  const data: QrCode[] = useMemo(() => qrCodes, [qrCodes]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    page,
    gotoPage,
    setPageSize,
    setGlobalFilter,
  } = useTable<QrCode>(
    { columns, data },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.allColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({ row }) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
        },
        ...columns,
      ]);
    }
  );

  const handleExport = () => {
    const selectedIds = Object.keys(state.selectedRowIds);

    if (!selectedIds.length)
      return toast({
        open: true,
        type: "error",
        message: "Please, select at least 1 row before exporting",
      });

    const headers = ["Link", "Action", "Status", "Created At"];
    const csvData: string[][] = [];

    data.forEach((qrcode, index) => {
      if (!selectedIds.includes(index.toString())) return;

      csvData.push([
        `${window.origin}/dashboard/qrcodes/${qrcode._id}`,
        qrcode.action?.event ?? "no-action",
        qrcode.status,
        qrcode.createdAt,
      ]);
    });

    exportDataToCsv(headers, csvData, "qr-codes");
  };

  return (
    <TableContainer component={Paper}>
      <QrCodesTableToolbar
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
        onExport={handleExport}
      />

      <Table {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              <TableCell />

              {headerGroup.headers.map((column) => (
                <TableCell
                  padding={column.id === "selection" ? "checkbox" : undefined}
                  {...(column.id === "selection"
                    ? column.getHeaderProps()
                    : column.getHeaderProps(column.getSortByToggleProps()))}
                >
                  {column.render("Header")}

                  {column.id !== "selection" && (
                    <TableSortLabel
                      active={column.isSorted}
                      direction={column.isSortedDesc ? "desc" : "asc"}
                    />
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>

        <TableBody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return <CollapsibleRow {...row.getRowProps()} row={row} />;
          })}
        </TableBody>

        <TableFooter
          count={rows.length}
          rowsPerPage={state.pageSize}
          page={state.pageIndex}
          gotoPage={gotoPage}
          setPageSize={setPageSize}
        />
      </Table>
    </TableContainer>
  );
};
