import React, { useState, useEffect, useContext } from "react";
// Material ui
import { List, ListItem, ListItemText, makeStyles, Button, Typography } from "@material-ui/core";
// Icons
import { Add } from "@material-ui/icons";
// Components
import WindowsServicesMenu from "./WindowsServicesMenu";
import Can from "../Can";
// Utils
import { Computer, WindowsService } from "../../@types/computer";
import DataContext from "../../context/DataContext";
import { requestUpdateComputerData } from "../../utils/computer";
import useIsComputerAvailable from "../../hooks/useIsComputerAvailable";
import useToast from "../../hooks/useToast";
import SearchServices from "./SearchServices";

interface Props {
  computer: Computer;
}

const useStyles = makeStyles((theme) => ({
  rootList: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
    padding: 0,
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const ServiceStatusText: React.FC<{ status: string }> = ({ status }) => (
  <span style={{ color: status && status.includes("Running") ? "green" : "red" }}>{status}</span>
);

const WindowsServices: React.FC<Props> = ({ computer }) => {
  // Hooks
  const classes = useStyles();
  const { user } = useContext(DataContext);
  const isComputerAvailable = useIsComputerAvailable(computer);
  const toast = useToast();

  // States
  const [services, setServices] = useState<WindowsService[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [selectedService, setSelectedService] = useState<WindowsService>(undefined!);
  const [addServiceModal, setAddServiceModal] = useState(false);
  const [isControlling, setIsControlling] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setServices(computer.services || []);
  }, [computer.services]);

  useEffect(() => {
    setIsDisabled(
      !isComputerAvailable || (computer.commands && computer.commands.controlService) ? true : false
    );
  }, [computer.commands, isComputerAvailable]);

  useEffect(() => {
    const commands = computer.commands;

    if (
      commands &&
      commands.controlService &&
      !commands.controlService.requested &&
      !commands.update
    ) {
      const { success, output } = commands.controlService;
      const resultMessage = success && output?.includes("\n") ? output.split("\n")[1] : output;

      if (isControlling) {
        setIsControlling(false);

        toast({
          open: true,
          type: success ? "success" : "error",
          message: resultMessage || "Error when sending the command",
        });
      }

      requestUpdateComputerData(computer.id);
    }
  }, [computer.commands, computer.id, isControlling, toast]);

  // Open the popover and selected the clicked service
  const handleOpenPopover = (e: any, service: WindowsService) => {
    setAnchorEl(e.currentTarget);
    setSelectedService(service);
    setIsControlling(true);
  };

  return (
    <div className="p-3">
      {services.length <= 0 ? (
        <Typography className="text-center mb-2">No services added yet</Typography>
      ) : (
        <List component="nav" className={classes.rootList}>
          {services
            .sort((a, b) => (a.displayName > b.displayName ? 1 : -1))
            .map((service, index) => (
              <ListItem
                key={index}
                button
                divider={index !== services.length - 1}
                className={classes.listItem}
                onClick={(e) => handleOpenPopover(e, service)}
                disabled={isDisabled}
              >
                <ListItemText
                  primary={service.displayName}
                  secondary={<ServiceStatusText status={service.status} />}
                />
              </ListItem>
            ))}
        </List>
      )}

      <Can role={user.role} perform="services:add">
        <div className="text-center mb-2">
          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={() => setAddServiceModal(true)}
            disabled={isDisabled}
          >
            Add service
          </Button>
        </div>
      </Can>

      <WindowsServicesMenu
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        service={selectedService}
        computer={computer}
      />

      <Can role={user.role} perform="services:add">
        <SearchServices
          open={addServiceModal}
          onClose={() => setAddServiceModal(false)}
          computer={computer}
        />
      </Can>
    </div>
  );
};

export default WindowsServices;
