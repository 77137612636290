import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
// Material ui
import {
  AppBar,
  Toolbar,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
// Icons
import { AccountCircle, ExitToApp, Menu as MenuIcon } from "@material-ui/icons";
// Components
import { ZendeskIconButton } from "./ZendeskIconButton";
// Utils
import DataContext from "../context/DataContext";
import { app } from "../constants/configs";

interface Props {
  toggleDrawer: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const Navbar: React.FC<Props> = ({ toggleDrawer }) => {
  // Hooks
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { companies, user, setUser } = useContext(DataContext);
  const isCurrent = useRef(true);

  // States
  const [navbarTitle, setNavbarTitle] = useState(app.longName);
  const [userMenuAnchor, setUserMenuAnchor] = useState<HTMLButtonElement | null>(null);

  useEffect(
    () => () => {
      isCurrent.current = false;
    },
    []
  );

  useEffect(() => {
    if (!isCurrent.current) return;

    if (location.pathname.includes("company")) {
      setNavbarTitle("Company");
      document.title = `Company - ${app.shortName}`;
    } else if (location.pathname.includes("/users/groups")) {
      setNavbarTitle("User groups");
      document.title = `User groups - ${app.shortName}`;
    } else if (location.pathname.includes("activities")) {
      setNavbarTitle("User management");
      document.title = `Users activities - ${app.shortName}`;
    } else if (location.pathname.includes("users")) {
      setNavbarTitle("User management");
      document.title = `Users - ${app.shortName}`;
    } else if (location.pathname.includes("qrcodes")) {
      setNavbarTitle("QR codes");
      document.title = `QR codes - ${app.shortName}`;
    } else {
      setNavbarTitle("Dashboard");
      document.title = `Dashboard - ${app.shortName}`;
    }
  }, [location, companies]);

  const handleSignOut = () => {
    window.localStorage.removeItem("token");
    setUser(null!);

    history.push("/");
  };

  return (
    <AppBar position="fixed" className={classes.root}>
      <Toolbar>
        <Hidden smUp implementation="css">
          <IconButton onClick={toggleDrawer}>
            <MenuIcon className="text-white" />
          </IconButton>
        </Hidden>

        {/* Name of the page */}
        <h4 className="w-100 m-auto ml-2">{navbarTitle}</h4>

        {/* Zendesk integration */}
        <ZendeskIconButton showBadge showTicketStatusAmount />

        {/* Profile icon */}
        <Tooltip title="Profile">
          <IconButton onClick={(e) => setUserMenuAnchor(e.currentTarget)}>
            <AccountCircle className="text-white" />
          </IconButton>
        </Tooltip>

        <Menu
          id="simple-menu"
          anchorEl={userMenuAnchor}
          keepMounted
          open={Boolean(userMenuAnchor)}
          onClose={() => setUserMenuAnchor(null)}
        >
          <MenuItem disabled={true}>{user.name}</MenuItem>
          <MenuItem onClick={handleSignOut}>
            <ExitToApp className="mr-3" /> Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
