import { useMemo, useState } from "react";

interface UseDialogProps<T> {
  open?: boolean;
  data?: T;
}

export function useDialog<T>(initialState?: UseDialogProps<T>) {
  const [open, setOpen] = useState(initialState?.open || false);
  const [data, setData] = useState<T | undefined>(initialState?.data ?? undefined);

  const openDialog = useMemo(
    () => (newData?: T) => {
      setData(newData);
      setOpen(true);
    },
    []
  );

  const closeDialog = useMemo(
    () => (newData?: T) => {
      setData(newData);
      setOpen(false);
    },
    []
  );

  return { open, data, openDialog, closeDialog };
}
