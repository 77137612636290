import React, { useContext, useState } from "react";
// Material ui
import { Avatar, Typography, IconButton, MenuItem, Popover } from "@material-ui/core";
import { Settings, Edit, Delete } from "@material-ui/icons";
// Components
import AddDevice from "./AddDevice";
import ConfirmationModal from "./Confirmation";
import { ZendeskIconButton } from "../ZendeskIconButton";
// Utils
import { DeviceItem } from "../DisplayDevices";
import { typeIcons, getDeviceTypeNames } from "../../utils/deviceUtils";
import Can, { check } from "../Can";
import DataContext from "../../context/DataContext";
import { BasicDeviceInfo } from "../../@types/computer";

export type onDelete = (
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  onClose: () => void
) => void;
interface Props {
  device?: BasicDeviceInfo;
  deviceItem: DeviceItem;
  onDelete: onDelete;
}

const DeviceModalHeader: React.FC<Props> = ({ device, deviceItem, onDelete }) => {
  // Hooks
  const { user } = useContext(DataContext);

  // States
  const [loading, setLoading] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);
  const [deviceFormModal, setDeviceFormModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    open: false,
    title: "",
    message: <></>,
  });

  const getTypeIcon = () =>
    typeIcons.reduce((acc, typeIcon) => (typeIcon.value === deviceItem.type ? typeIcon : acc)).icon;

  const closeConfirmationModal = () => setConfirmationModal({ ...confirmationModal, open: false });

  const handleDeleteConfirmation = () => {
    setMenuAnchor(null);
    setConfirmationModal({
      open: true,
      title: "Delete device",
      message: (
        <span>
          Would you like to delete{" "}
          {deviceItem?.name ? <strong>{deviceItem.name}</strong> : "this device"}
        </span>
      ),
    });
  };

  const handleEditOption = () => {
    setMenuAnchor(null);
    setDeviceFormModal(true);
  };

  return (
    <>
      <div className="d-flex align-items-center">
        <Avatar className="mr-3">{getTypeIcon()}</Avatar>

        <div className="mr-auto">
          <Typography>{deviceItem.name}</Typography>

          <Typography color="textSecondary" variant="subtitle2">
            {getDeviceTypeNames(deviceItem.type).formalName}
          </Typography>
        </div>

        <ZendeskIconButton targetDevice={deviceItem} />

        {(check(user.role, "devices:edit") || check(user.role, "devices:delete")) && (
          <IconButton onClick={(e) => setMenuAnchor(e.currentTarget)}>
            <Settings />
          </IconButton>
        )}
      </div>

      <Popover
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Can role={user.role} perform="devices:delete">
          <MenuItem onClick={handleEditOption}>
            <Edit className="mr-3" />
            Edit
          </MenuItem>
        </Can>

        <Can role={user.role} perform="devices:delete">
          <MenuItem onClick={handleDeleteConfirmation}>
            <Delete className="mr-3" />
            Delete
          </MenuItem>
        </Can>
      </Popover>

      <Can role={user.role} perform="devices:edit">
        <AddDevice
          open={deviceFormModal}
          onClose={() => setDeviceFormModal(false)}
          device={device}
        />
      </Can>

      <ConfirmationModal
        open={confirmationModal.open}
        onClose={closeConfirmationModal}
        title={confirmationModal.title}
        message={confirmationModal.message}
        onYes={() => onDelete(setLoading, closeConfirmationModal)}
        yesLoading={loading}
      />
    </>
  );
};

export default DeviceModalHeader;
