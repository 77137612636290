import { useEffect, useContext, useState } from "react";
import CompanyContext from "../context/CompanyContext";
import { Computer } from "../@types/computer";
import useCompanyPage from "./useCompanyPage";

const useComputers = (id?: string) => {
  // Hooks
  const { computers } = useContext(CompanyContext);
  const company = useCompanyPage();

  // States
  const [filteredComputers, setFilteredComputers] = useState<Computer[]>([]);

  if (!id) {
    id = company._id;
  }

  useEffect(() => {
    const newComputers = computers.filter((computer: Computer) => computer.company === id);

    if (JSON.stringify(newComputers) !== JSON.stringify(filteredComputers)) {
      setFilteredComputers(newComputers);
    }
  }, [computers, id, filteredComputers]);

  return filteredComputers;
};

export default useComputers;
