import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MUIDataTable, { MUIDataTableColumnDef } from "mui-datatables";
// Material ui
import { IconButton, Tooltip } from "@material-ui/core";
// Icons
import { CheckCircle, CheckCircleOutline } from "@material-ui/icons";
// Components
import ProgressBar from "../ProgressBar";
// Utils
import DataContext from "../../context/DataContext";
import useFetch from "../../hooks/useFetch";
import { getCompanyById, getSize, getSpacePercentage } from "../../utils/functions";
import { Computer, DiskType } from "../../@types/computer";
import { Company } from "../../@types/company";

interface FetchResponse {
  computers: Computer[];
}

interface TableComputer {
  id: string;
  name: string;
  company: Company;
  disk: DiskType;
}

const getFormattedComputers = (computers: Computer[], companies: Company[]) =>
  computers
    .filter((computer) => computer.disks)
    .map((computer) =>
      computer.disks.map((disk) => ({
        id: computer.id,
        name: computer.name || computer.computerName,
        company: getCompanyById(computer.company, companies),
        disk,
      }))
    )
    .flat();

const usbThreshold = 32 * Math.pow(1024, 3);

const DiskSpaceTable: React.FC = () => {
  // Hooks
  const { data, loading, refetch } = useFetch<FetchResponse>("/devices/computers");
  const { companies } = useContext(DataContext);

  // States
  const [formattedComputers, setFormattedComputers] = useState<TableComputer[]>([]);
  const [showUsb, setShowUsb] = useState(false);

  useEffect(() => {
    const fetchInterval = setInterval(refetch, 60 * 1000);

    return () => {
      clearInterval(fetchInterval);
    };
  }, [refetch]);

  useEffect(() => {
    if (!data) return;

    const computers = getFormattedComputers(data.computers, companies);
    setFormattedComputers(
      showUsb ? computers : computers.filter(({ disk }) => disk.size > usbThreshold)
    );
  }, [companies, data, showUsb]);

  const columns: MUIDataTableColumnDef[] = [
    {
      name: "company",
      label: "Company",
      options: {
        filterType: "multiselect",
        filterOptions: {
          names: companies.map((comp) => comp.name).sort(),
          // @ts-ignore
          logic: (v: TableComputer["company"], filters: string[]) =>
            filters.length ? !filters.includes(v.name) : false,
        },
        customBodyRender: (v?: TableComputer["company"] | string) =>
          typeof v === "string" ? (
            v
          ) : (
            <Link to={`/dashboard/company/${v?._id || ""}`}>{v?.name || "Company name"}</Link>
          ),
      },
    },
    { name: "name", label: "Computer name", options: { filter: false } },
    { name: "diskName", label: "Disk name", options: { filter: false, sort: false } },
    {
      name: "freeSpace",
      label: "Free space",
      options: { filter: false, customBodyRender: (v: number) => getSize(v) },
    },
    {
      name: "totalSpace",
      label: "Total space",
      options: { filter: false, customBodyRender: (v: number) => getSize(v) },
    },
    {
      name: "percentUsed",
      label: "% used",
      options: {
        filterList: ["80"],
        filterType: "textField",
        filterOptions: {
          // @ts-ignore
          logic: (v: number, [percent]: string[]) => (percent ? v < parseFloat(percent) : false),
        },
        customFilterListOptions: {
          render: (v) => `Min % used: ${v}`,
        },
        customBodyRender: (v: number) => <ProgressBar percentage={v || 100} />,
      },
    },
  ];

  return (
    <MUIDataTable
      title="Computers with low disk space"
      columns={columns}
      data={
        !data && loading
          ? [["Loading..."]]
          : formattedComputers.map(({ name, company, disk }) => ({
              name,
              company,
              diskName: disk.fs,
              freeSpace: disk.size - disk.used,
              totalSpace: disk.size,
              percentUsed: getSpacePercentage(disk.used, disk.size),
            }))
      }
      options={{
        selectableRows: "none",
        sortOrder: { name: "percentUsed", direction: "desc" },
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10, 15],
        customToolbar: () => (
          <Tooltip title={showUsb ? "Don't show USB drivers" : "Show USB drivers"}>
            <IconButton onClick={() => setShowUsb((prev) => !prev)}>
              {showUsb ? <CheckCircle /> : <CheckCircleOutline />}
            </IconButton>
          </Tooltip>
        ),
      }}
    />
  );
};

export default DiskSpaceTable;
