import { useContext } from "react";
import { useParams } from "react-router-dom";
import DataContext from "../context/DataContext";
// Interfaces
import { Company } from "../@types/company";

const useCompanyPage = (): Company => {
  const { companies } = useContext(DataContext);
  const { id } = useParams<{ id: string }>();

  return companies.filter((comp) => comp._id === id)[0];
};

export default useCompanyPage;
