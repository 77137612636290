import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
// Material ui
import {
  Card,
  CardHeader,
  CardContent,
  makeStyles,
  CardActions,
  Button,
  Avatar,
  Tooltip,
  IconButton,
} from "@material-ui/core";
// Icons
import { AiOutlineQrcode } from "react-icons/ai";
// Components
import { DeviceItem } from "./DisplayDevices";
import DisplayOnOff from "./DisplayOnOff";
import OtherDeviceModal from "./Modals/OtherDevicesModal";
import ComputerInfoModal from "./Modals/ComputerInfoModal";
import DisplayInfo from "./DisplayInfo";
// Utils
import { typeIcons, getDeviceTypeNames } from "../utils/deviceUtils";
import QRCodeDeviceModal from "./Modals/QRCodeDeviceModal";

interface Props {
  device: DeviceItem;
  warning?: string;
}

interface RouteParams {
  id: string;
  deviceId: string;
}

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    minWidth: "275px",
    flex: "1 1 auto",
    maxWidth: "320px",
    margin: theme.spacing(1),
  },
  cardHeaderRoot: {
    padding: "10px 16px 10px 16px",
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.divider,
  },
  successContainer: { background: theme.palette.success.main, height: "10px" },
  warningContainer: { background: theme.palette.warning.main, height: "10px" },
}));

const DeviceCard: React.FC<Props> = ({ device, warning = "" }) => {
  // Hooks
  const classes = useStyles();
  const history = useHistory();
  const params = useParams<RouteParams>();

  // State
  const [deviceInfoModal, setDeviceInfoModal] = useState(false);
  const [qrcodeDeviceModal, setQrcodeDeviceModal] = useState(false);

  useEffect(() => {
    setDeviceInfoModal(Boolean(params.deviceId && params.deviceId === device.id ? true : false));
  }, [device.id, params.deviceId]);

  const handleCloseModal = () => history.push(`/dashboard/company/${params.id}`);

  return (
    <>
      <Card className={classes.cardContainer}>
        <CardHeader
          title={device.name}
          subheader={getDeviceTypeNames(device.type).formalName}
          avatar={
            <Avatar>
              {typeIcons.reduce((acc, type) => (type.value === device.type ? type : acc)).icon}
            </Avatar>
          }
          action={
            <div className="d-flex align-items-center">
              <IconButton onClick={() => setQrcodeDeviceModal(true)}>
                <AiOutlineQrcode />
              </IconButton>

              {device.type === "computer" && device.connected && (
                <DisplayOnOff connected={device.connected} />
              )}
            </div>
          }
          classes={{ root: classes.cardHeaderRoot, action: "m-auto" }}
        />

        <CardContent classes={{ root: "pb-2" }}>
          {device.information.map((info, index) => (
            <DisplayInfo key={index} title={info.name} message={info.value} />
          ))}
        </CardContent>

        <CardActions>
          <Button
            color="primary"
            fullWidth
            onClick={() => history.push(`/dashboard/company/${params.id}/devices/${device.id}`)}
          >
            View more
          </Button>
        </CardActions>

        <Tooltip
          title={<span style={{ fontSize: "12px" }}>{warning || "No warnings"}</span>}
          arrow
          placement="top"
        >
          <div className={!warning ? classes.successContainer : classes.warningContainer} />
        </Tooltip>
      </Card>

      {device.type === "computer" ? (
        <ComputerInfoModal open={deviceInfoModal} onClose={handleCloseModal} deviceItem={device} />
      ) : (
        <OtherDeviceModal
          open={deviceInfoModal}
          onClose={handleCloseModal}
          deviceItem={device}
          widthSize="sm"
        />
      )}

      <QRCodeDeviceModal
        open={qrcodeDeviceModal}
        onClose={() => setQrcodeDeviceModal(false)}
        device={{ id: device.id, name: device.name }}
      />
    </>
  );
};

export default DeviceCard;
