import React from "react";
import PropTypes from "prop-types";
// Material ui
import { List, ListItem, ListItemText, Typography, makeStyles } from "@material-ui/core";
// Components
import Loading from "./Loading";

const useStyles = makeStyles((theme) => ({
  listContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: 220,
  },
  list: {
    width: "100%",
    position: "relative",
    overflow: "auto",
    height: 220,
  },
}));

const ListItems = ({ items, notFound, loading, onClick, disabled }) => {
  const classes = useStyles();

  return (
    <div className={classes.listContainer}>
      {loading ? (
        <Loading />
      ) : items.length > 0 ? (
        <List className={classes.list}>
          {items.map((val) => (
            <ListItem key={val} dense button disabled={disabled} onClick={() => onClick(val)}>
              <ListItemText id={`label-${val}`} primary={val} />
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography variant="h6">{notFound}</Typography>
      )}
    </div>
  );
};

ListItems.propTypes = {
  items: PropTypes.array.isRequired,
  notFound: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ListItems.defaultProps = {
  items: [],
  notFound: "Items not found",
  loading: false,
  disable: false,
  onClick: () => {},
};

export default ListItems;
