import React from "react";
import { Row } from "react-table";
// Material UI
import { TableRow, TableCell, IconButton, Collapse } from "@material-ui/core";
// Icons
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
// Components
import { CollapsibleData } from "./CollapsibleData";
// Utils
import { useBoolean } from "hooks/useBoolean";
import { QrCode } from "../../../@types/QrCode";

interface CollapsibleRowProps {
  row: Row<QrCode>;
}

export const CollapsibleRow: React.FC<CollapsibleRowProps> = ({ row }) => {
  const [isOpen, setIsOpen] = useBoolean();

  return (
    <>
      <TableRow {...row.getRowProps()}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={setIsOpen.toggle}>
            {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>

        {row.cells.map((cell) => (
          <TableCell {...cell.getCellProps()}>{cell.render("Cell")}</TableCell>
        ))}
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <CollapsibleData qrCodeId={row.original._id} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
