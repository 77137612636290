import React, { useState, useContext, useEffect } from "react";
import marked from "marked";
import { useHistory } from "react-router-dom";
// Material ui
import { Typography, Button, makeStyles } from "@material-ui/core";
// Components
import CommentInput from "./CommentInput";
// Utils
import { Computer } from "../../../@types/computer";
import { check } from "../../Can";
import DataContext from "../../../context/DataContext";
import api from "../../../utils/api";
import useLocalStorage from "../../../hooks/useLocalStorage";

interface Props {
  computer: Computer;
}

interface FakeCommentInputProps {
  className: string;
  onClick: () => void;
  canUpdate: boolean;
}

const FakeCommentInput: React.FC<FakeCommentInputProps> = ({ className, onClick, canUpdate }) => (
  <Button className={className} onClick={onClick}>
    {canUpdate
      ? "Click here to add a comment for this computer"
      : "No comments added for this computer"}
  </Button>
);

const useStyles = makeStyles((theme) => ({
  commentContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "4px",
    marginBottom: theme.spacing(1),
  },
  markdownContainer: {
    minHeight: "40px",
    maxHeight: "300px",
    overflow: "auto",
    width: "100%",
    padding: "8px 12px",
    "& *:last-child": {
      marginBottom: 0,
    },
  },
  enableCursor: { cursor: "pointer" },
  addComment: {
    minHeight: "40px",
    width: "100%",
    padding: "8px 12px",
    textTransform: "none",
    justifyContent: "flex-start",
    cursor: "pointer",
    fontStyle: "italic",
  },
}));

const DisplayComputerComments: React.FC<Props> = ({ computer }) => {
  // Hooks
  const classes = useStyles();
  const { user } = useContext(DataContext);
  const [token] = useLocalStorage("token");
  const history = useHistory();

  // State
  const [comment, setComment] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchComment = async () => {
      try {
        const { data } = await api.get(`/computerComment/${computer.id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setComment(data.comment.text);
      } catch (err) {
        if (err.response && err.response.data) {
          const { data } = err.response;

          // Invalid token
          if (data.message && data.message.includes("token")) return history.push("/");
        }
      }
    };

    fetchComment();
  }, [computer.id, history, token]);

  const handleEdit = () => {
    if (check(user.role, "devices:update-comment")) {
      setIsEditing(true);
    }
  };

  const displayCommentState = () => {
    if (!isEditing && comment) {
      return (
        <div
          className={`${classes.markdownContainer} ${
            check(user.role, "devices:update-comment") ? classes.enableCursor : ""
          }`}
          onClick={handleEdit}
          dangerouslySetInnerHTML={{ __html: marked(comment, { breaks: true }) }}
        />
      );
    } else if (!isEditing) {
      return (
        <FakeCommentInput
          className={classes.addComment}
          onClick={handleEdit}
          canUpdate={check(user.role, "devices:update-comment")}
        />
      );
    } else {
      return (
        <CommentInput
          computerId={computer.id}
          currentComment={comment}
          updateComment={(newComment) => setComment(newComment)}
          onBlur={() => setIsEditing(false)}
        />
      );
    }
  };

  return (
    <div className="mt-2">
      <Typography variant="body2">
        <strong>Comments:</strong>
      </Typography>

      <div className={!isEditing ? classes.commentContainer : ""}>{displayCommentState()}</div>
    </div>
  );
};

export default DisplayComputerComments;
