import React, { createContext, useMemo, useState } from "react";
import { User, UserTickets, ZendeskIntegration } from "../@types/zendesk";

interface ZendeskContextProps {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  integration: ZendeskIntegration | null;
  setIntegration: React.Dispatch<React.SetStateAction<ZendeskIntegration | null>>;
  tickets: UserTickets | null;
  setTickets: React.Dispatch<React.SetStateAction<UserTickets | null>>;
}

const ZendeskContext = createContext<ZendeskContextProps>({
  user: null,
  setUser: () => {},
  integration: null,
  setIntegration: () => {},
  tickets: null,
  setTickets: () => {},
});

export const ZendeskProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [integration, setIntegration] = useState<ZendeskIntegration | null>(null);
  const [tickets, setTickets] = useState<UserTickets | null>(null);

  const values = useMemo(
    () => ({ user, setUser, integration, setIntegration, tickets, setTickets }),
    [integration, tickets, user]
  );

  return <ZendeskContext.Provider value={values}>{children}</ZendeskContext.Provider>;
};

export default ZendeskContext;
