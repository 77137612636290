import React, { forwardRef, useEffect, useRef } from "react";
import { TableToggleAllRowsSelectedProps } from "react-table";
// Material ui
import { Checkbox } from "@material-ui/core";

type IndeterminateCheckboxProps = TableToggleAllRowsSelectedProps;

type CheckboxRef = any;

export const IndeterminateCheckbox = forwardRef<CheckboxRef, IndeterminateCheckboxProps>(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef<CheckboxRef>();
    const resolvedRef: CheckboxRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <div style={{ textAlign: "center" }}>
        <Checkbox ref={defaultRef} color="primary" {...rest} />
      </div>
    );
  }
);
