import React, { createContext, useReducer } from "react";
import { UserGroup, User } from "../@types/users";
import { Company } from "../@types/company";

interface ProviderProps {
  children: React.ReactNode;
}

interface SelectedGroup {
  groupId: string;
  loading: boolean;
  users: User[];
  companies: Company[];
  fetchedAt: Date;
}
interface State {
  groups: UserGroup[];
  selectedGroup: SelectedGroup;
}

type Action =
  | { type: "SET_GROUPS"; payload: UserGroup[] }
  | { type: "ADD_GROUP"; payload: UserGroup }
  | {
      type: "UPDATE_GROUP";
      payload: { id: string; group: UserGroup; newComps: Company[]; newUsers: User[] };
    }
  | { type: "UPDATE_USERS_LIST"; payload: User[] }
  | { type: "SET_LOADING"; payload: boolean }
  | { type: "SET_SELECTED_GROUP"; payload: SelectedGroup };

const initialState: State = {
  groups: [],
  selectedGroup: {
    groupId: "",
    loading: false,
    users: [],
    companies: [],
    fetchedAt: new Date(),
  },
};

const UserGroupContext = createContext<{ state: State; dispatch: React.Dispatch<Action> }>({
  state: initialState,
  dispatch: () => null,
});

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_GROUPS":
      return { ...state, groups: action.payload };

    case "ADD_GROUP":
      return { ...state, groups: [...state.groups, action.payload] };

    case "UPDATE_GROUP":
      const { id, group, newComps, newUsers } = action.payload;
      const newGroups = state.groups.map((currGroup) => (currGroup._id === id ? group : currGroup));

      return {
        ...state,
        groups: newGroups,
        selectedGroup: { ...state.selectedGroup, companies: newComps, users: newUsers },
      };

    case "UPDATE_USERS_LIST":
      return { ...state, selectedGroup: { ...state.selectedGroup, users: action.payload } };

    case "SET_LOADING":
      return { ...state, selectedGroup: { ...state.selectedGroup, loading: action.payload } };

    case "SET_SELECTED_GROUP":
      return { ...state, selectedGroup: action.payload };

    default:
      return state;
  }
};

export const UserGroupProvider = ({ children }: ProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <UserGroupContext.Provider value={{ state, dispatch }}>{children}</UserGroupContext.Provider>
  );
};

export default UserGroupContext;
