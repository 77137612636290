import React, { useContext, useState } from "react";
// Material ui
import { Typography, Button, makeStyles, CircularProgress } from "@material-ui/core";
// Utils
import ZendeskContext from "../context/ZendeskContext";
import useLocalStorage from "../hooks/useLocalStorage";
import useRequestErrorHandler from "../hooks/useRequestErrorHandler";
import useToast from "../hooks/useToast";
import api from "../utils/api";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    maxWidth: 220,
    padding: theme.spacing(1.5),
  },
  btnIntegrate: {
    marginTop: theme.spacing(0.5),
  },
}));

const AccountNotIntegratedToZendeskMenu: React.FC = () => {
  // Hooks
  const classes = useStyles();
  const [token] = useLocalStorage("token");
  const handleRequestError = useRequestErrorHandler();
  const { setUser } = useContext(ZendeskContext);
  const toast = useToast();

  // States
  const [loading, setLoading] = useState(false);

  const handleIntegration = async () => {
    setLoading(true);

    try {
      const { data } = await api.post("/zendesk/integration", null, {
        headers: { Authorization: `Bearer ${token}` },
      });

      toast({ open: true, type: "success", message: "Account successfully integrated" });
      setUser(data.user);
    } catch (err) {
      handleRequestError(err);
      setLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      <Typography variant="body2">
        Would you like to integrate your account with Zendesk?
      </Typography>

      {loading ? (
        <CircularProgress className={classes.btnIntegrate} />
      ) : (
        <Button variant="outlined" onClick={handleIntegration} className={classes.btnIntegrate}>
          Integrate
        </Button>
      )}
    </div>
  );
};

export default AccountNotIntegratedToZendeskMenu;
