import React, { createContext, useMemo, useState } from "react";
import { Computer, DeviceGroup } from "../@types/computer";
import { DeviceItem } from "../components/DisplayDevices";

interface ContextProps {
  computers: Computer[];
  groups: DeviceGroup[];
  selectedGroup?: DeviceGroup;
  totalDevices: number;
  totalPendingCodes: number;
  loadingTotalDevices: boolean;
  deviceCards: DeviceItem[];
  setComputers: (newComputers: Computer[]) => void;
  setGroups: (newGroups: DeviceGroup[]) => void;
  setSelectedGroup: (newGroup: DeviceGroup) => void;
  setTotalDevices: (newTotalDevices: number) => void;
  setTotalPendingCodes: (newTotalPendingCodes: number) => void;
  setLoadingTotalDevices: (newTotalDevices: boolean) => void;
  setDeviceCards: (newDeviceCards: DeviceItem[]) => void;
}

const CompanyContext = createContext<ContextProps>(undefined!);

export const CompanyProvider: React.FC = ({ children }) => {
  const [computers, setComputers] = useState<Computer[]>([]);
  const [groups, setGroups] = useState<DeviceGroup[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<DeviceGroup>();
  const [totalDevices, setTotalDevices] = useState(0);
  const [totalPendingCodes, setTotalPendingCodes] = useState(0);
  const [loadingTotalDevices, setLoadingTotalDevices] = useState(false);
  const [deviceCards, setDeviceCards] = useState<DeviceItem[]>([]);

  const value = useMemo(
    () => ({
      computers,
      setComputers,
      groups,
      setGroups,
      selectedGroup,
      setSelectedGroup,
      totalDevices,
      setTotalDevices,
      totalPendingCodes,
      setTotalPendingCodes,
      loadingTotalDevices,
      setLoadingTotalDevices,
      deviceCards,
      setDeviceCards,
    }),
    [
      computers,
      deviceCards,
      groups,
      loadingTotalDevices,
      selectedGroup,
      totalDevices,
      totalPendingCodes,
    ]
  );

  return <CompanyContext.Provider value={value}>{children}</CompanyContext.Provider>;
};

export default CompanyContext;
