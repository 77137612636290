import React, { useCallback, useContext, useState } from "react";
import moment from "moment";
// Material ui
import {
  Avatar,
  IconButton,
  ListItemIcon,
  MenuItem,
  MenuList,
  Popover,
  Typography,
} from "@material-ui/core";
// Icons
import {
  Lock,
  PowerSettingsNew,
  Replay,
  SettingsPower,
  SubdirectoryArrowRight,
} from "@material-ui/icons";
// Components
import DisplayOnOff from "../DisplayOnOff";
import Can from "../Can";
// Utils
import { Computer, ShutdownCommands } from "../../@types/computer";
import { typeIcons } from "../../utils/deviceUtils";
import DataContext from "../../context/DataContext";
import ShutdownConfirmation from "./ShutdownConfirmation";
import { isVersionCompatible } from "../../utils/functions";
import { ZendeskIconButton } from "../ZendeskIconButton";
import { DeviceItem } from "../DisplayDevices";

interface Props {
  computer: Computer;
  deviceItem: DeviceItem;
}

const convertTime = (time: string) => {
  const date = moment(new Date(time));

  return `${date.format("LLL")} (${date.fromNow()})`;
};

const shutdownMenuItems: { label: string; icon: JSX.Element; command: ShutdownCommands }[] = [
  { label: "Turn off", icon: <PowerSettingsNew />, command: "shutdown" },
  { label: "Restart", icon: <Replay />, command: "restart" },
  { label: "Log off", icon: <SubdirectoryArrowRight />, command: "logOff" },
  { label: "Lock", icon: <Lock />, command: "lock" },
];

const ComputerModalHeader: React.FC<Props> = ({ computer, deviceItem }) => {
  const { user } = useContext(DataContext);

  // States
  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);
  const [shutdownModal, setShutdownModal] = useState<{ open: boolean; command: ShutdownCommands }>({
    open: false,
    command: "lock",
  });

  const showCommands = useCallback(() => {
    const { commands, lastUpdate } = computer;

    if (commands && commands.update) {
      return `Last update: Requested in ${convertTime(commands.update.timestamp)}`;
    } else if (commands && commands.updateConnector) {
      const { status, timestamp } = commands.updateConnector;

      return !status
        ? `Command: Update connector command sent at ${convertTime(timestamp)}`
        : `Command: ${status} (started at: ${convertTime(timestamp)})`;
    } else {
      return `Last update: ${convertTime(lastUpdate)}`;
    }
  }, [computer]);

  const handleShutdownItemClick = (command: ShutdownCommands) => {
    setMenuAnchor(null);
    setShutdownModal({ open: true, command: command });
  };

  return (
    <div className="d-flex align-items-center">
      <Avatar className="mr-3">
        {typeIcons.reduce((acc, typeIcon) => (typeIcon.value === "computer" ? typeIcon : acc)).icon}
      </Avatar>
      <div className="flex-grow-1">
        <Typography>{computer.name || computer.computerName}</Typography>

        <Typography color="textSecondary" variant="subtitle2">
          {showCommands()}
        </Typography>
      </div>

      <ZendeskIconButton targetDevice={deviceItem} />

      <DisplayOnOff connected={computer.connected} />

      {isVersionCompatible("1.0.10", computer.connectorVersion) && (
        <>
          <Can role={user.role} perform="devices:shutdown">
            <IconButton
              onClick={(e) => setMenuAnchor(e.currentTarget)}
              disabled={computer.connected && !computer.connected.connected}
            >
              <SettingsPower />
            </IconButton>
          </Can>

          <Can role={user.role} perform="devices:shutdown">
            <Popover
              anchorEl={menuAnchor}
              open={Boolean(menuAnchor)}
              onClose={() => setMenuAnchor(null)}
              anchorOrigin={{ vertical: "center", horizontal: "right" }}
              transformOrigin={{ vertical: "center", horizontal: "left" }}
            >
              <MenuList>
                {shutdownMenuItems.map((item) => (
                  <MenuItem key={item.label} onClick={() => handleShutdownItemClick(item.command)}>
                    <ListItemIcon>{item.icon}</ListItemIcon> {item.label}
                  </MenuItem>
                ))}
              </MenuList>
            </Popover>
          </Can>

          <ShutdownConfirmation
            open={shutdownModal.open}
            onClose={() => setShutdownModal({ open: false, command: "lock" })}
            command={shutdownModal.command}
            computerId={computer.id}
          />
        </>
      )}
    </div>
  );
};

export default ComputerModalHeader;
