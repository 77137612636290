import React from "react";
// Material UI
import { Box, Grid, GridProps, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
// Utils
import { useQrCode } from "hooks/useQrCode";
import { actions } from "constants/qrCodes";

interface CollapsibleDataProps {
  qrCodeId: string;
}

const gridItemProps: GridProps = {
  item: true,
  sm: true,
  xs: 12,
  container: true,
  direction: "column",
  justify: "center",
};

const InfoLabel: React.FC<{ title: string; gutterBottom?: boolean }> = (props) => (
  <Typography variant="subtitle2" gutterBottom={props.gutterBottom}>
    <strong>{props.title}:</strong> {props.children}
  </Typography>
);

export const CollapsibleData: React.FC<CollapsibleDataProps> = ({ qrCodeId }) => {
  // Hooks
  const { qrCode, isLoading, error } = useQrCode(qrCodeId);

  if (error)
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {typeof error === "string" ? error : error?.message ?? "An unexpected error happened"}
      </Alert>
    );

  if (isLoading && !qrCode)
    return (
      <Box p={2} display="flex" justifyContent="center" alignItems="center">
        <Typography align="center">Loading the QR code information</Typography>
      </Box>
    );

  return (
    <Box p={1}>
      <Grid container spacing={3}>
        <Grid {...gridItemProps}>
          {qrCode.action ? (
            <>
              <InfoLabel title="Action">{actions[qrCode.action.event].displayName}</InfoLabel>

              <InfoLabel title="Target Name">{qrCode.action.prepared?.name}</InfoLabel>
            </>
          ) : (
            <InfoLabel title="Action">No action</InfoLabel>
          )}
        </Grid>
        <Grid {...gridItemProps}>
          <InfoLabel title="Updated at">{new Date(qrCode.updatedAt).toLocaleString()}</InfoLabel>

          <InfoLabel title="Updated by">
            {qrCode.updatedBy.name} ({qrCode.updatedBy.email})
          </InfoLabel>
        </Grid>

        <Grid {...gridItemProps}>
          <InfoLabel title="Created at">{new Date(qrCode.createdAt).toLocaleString()}</InfoLabel>

          <InfoLabel title="Created by">
            {qrCode.createdBy.name} ({qrCode.createdBy.email})
          </InfoLabel>
        </Grid>
      </Grid>
    </Box>
  );
};
