import api from "./api";
import { BasicDeviceInfo, Computer, DeviceGroup } from "../@types/computer";

export const getCompanyGroups = async (
  companyId: string,
  token: string
): Promise<DeviceGroup[]> => {
  const { data } = await api.get(`/deviceGroups/company/${companyId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return data.groups as DeviceGroup[];
};

export const getGroupDetails = async (groupId: string, companyId: string, token: string) => {
  const { data } = await api.get(`/deviceGroups/${groupId}/company/${companyId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return data.group as {
    name: string;
    companyId: string;
    devices: BasicDeviceInfo[];
    computers: Computer[];
    pendingCodes?: Array<{ id: string; company: string; timestamp: number }>;
  };
};

export const getDetailsFromUserGroup = async (
  id: string,
  token: string,
  type: "users" | "companies"
) => {
  const { data } = await api.get(`/users/groups/${id}/${type}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return data;
};
