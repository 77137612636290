import React, { useContext, useState } from "react";
// Material ui
import { Tooltip, IconButton, Badge, Popover } from "@material-ui/core";
// Icons
import { SiZendesk } from "react-icons/si";
// Components
import AccountIntegratedToZendeskMenu from "./AccountIntegratedToZendeskMenu";
import AccountNotIntegratedToZendeskMenu from "./AccountNotIntegratedToZendeskMenu";
// Utils
import ZendeskContext from "../context/ZendeskContext";
import { DeviceItem } from "./DisplayDevices";

export interface ZendeskIconButtonProps {
  showBadge?: boolean;
  showTicketStatusAmount?: boolean;
  targetDevice?: DeviceItem;
}

export const ZendeskIconButton: React.FC<ZendeskIconButtonProps> = ({
  showBadge,
  showTicketStatusAmount,
  targetDevice,
}) => {
  // Hooks
  const { user, tickets } = useContext(ZendeskContext);

  // States
  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <Tooltip title="Zendesk">
        <IconButton onClick={(e) => setMenuAnchor(e.currentTarget)}>
          {tickets && tickets.open > 0 && showBadge ? (
            <Badge badgeContent={tickets.open} color="secondary">
              <SiZendesk />
            </Badge>
          ) : (
            <SiZendesk />
          )}
        </IconButton>
      </Tooltip>

      <Popover
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {user ? (
          <AccountIntegratedToZendeskMenu
            showTicketStatusAmount={showTicketStatusAmount}
            targetDevice={targetDevice}
          />
        ) : (
          <AccountNotIntegratedToZendeskMenu />
        )}
      </Popover>
    </>
  );
};
