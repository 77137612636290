import { useContext } from "react";
// Material ui
import { SnackbarProps } from "@material-ui/core";
// Utils
import { AlertProps } from "@material-ui/lab";
import { ThemeContext } from "../context/ThemeProvider";

export type UseToastProps = SnackbarProps & {
  alertProps?: AlertProps;
  type?: "success" | "error" | "warning" | "info";
};

const useToast = () => {
  const { setSnackbar } = useContext(ThemeContext);

  const toast = (toast: UseToastProps) => {
    setSnackbar({
      ...toast,
      type: toast.type || "info",
      autoHideDuration: toast.autoHideDuration || 5000,
      anchorOrigin: toast.anchorOrigin || { vertical: "top", horizontal: "right" },
    });
  };

  return toast;
};

export default useToast;
