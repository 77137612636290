import React, { useEffect, useRef, useState } from "react";
import QRCode from "qrcode";
import { useParams } from "react-router";
// Material ui
import { Box, IconButton, makeStyles, Typography } from "@material-ui/core";
import { SaveAlt } from "@material-ui/icons";
// Components
import DialogBasic from "./DialogBasic";

interface Props {
  open: boolean;
  onClose: () => void;
  device: { id: string; name: string };
}

interface DialogHeaderProps {
  title: string;
  onDownload: () => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    padding: theme.spacing(2),
    "& > svg": {
      width: "100%",
      maxHeight: "60vh",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
}));

const DialogHeader: React.FC<DialogHeaderProps> = ({ title, onDownload }) => (
  <Box
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    paddingX={2}
    paddingTop={1}
  >
    <Typography variant="h6">{title}</Typography>

    <IconButton onClick={onDownload}>
      <SaveAlt />
    </IconButton>
  </Box>
);

const downloadImage = async (code: string, name: string) => {
  const image = await QRCode.toDataURL(code, { margin: 2, scale: 10 });

  const link = document.createElement("a");
  link.download = name;
  link.style.opacity = "0";
  document.getElementById("root")?.append(link);
  link.href = image;
  link.click();
  link.remove();
};

const QRCodeDeviceModal: React.FC<Props> = ({ open, onClose, device }) => {
  // Hooks
  const classes = useStyles();
  const params = useParams<{ id: string }>();
  const qrcodeTargetUrl = useRef(
    `${window.location.origin}/dashboard/company/${params.id}/devices/${device.id}`
  );

  // States
  const [qrcodeSvg, setQrcodeSvg] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!open) return;

    (async () => {
      const svg = await QRCode.toString(qrcodeTargetUrl.current, {
        type: "svg",
        margin: 2,
        scale: 2,
      });

      setQrcodeSvg(svg);
      setLoading(false);
    })();
  }, [device.id, open, params.id]);

  if (!open) return null;

  return (
    <DialogBasic open={open} onClose={onClose} fullWidth={false}>
      <DialogHeader
        title={device.name}
        onDownload={() => downloadImage(qrcodeTargetUrl.current, device.name)}
      />

      {loading ? (
        <p>Loading...</p>
      ) : (
        <span className={classes.container} dangerouslySetInnerHTML={{ __html: qrcodeSvg }} />
      )}
    </DialogBasic>
  );
};

export default QRCodeDeviceModal;
