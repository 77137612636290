import React, { useContext, useState } from "react";
// Material ui
import { Popover, Paper, MenuList, MenuItem } from "@material-ui/core";
// Components
import Can from "../Can";
import ConfirmationModal from "./Confirmation";
// Utils
import DataContext from "../../context/DataContext";
import { WindowsService, Computer } from "../../@types/computer";
import { newApi } from "../../utils/api";
import useToast from "../../hooks/useToast";
import useRequestErrorHandler from "../../hooks/useRequestErrorHandler";

interface Props {
  anchorEl: HTMLDivElement | null;
  onClose: () => void;
  service: WindowsService;
  computer: Computer;
}

const WindowsServicesMenu: React.FC<Props> = ({ anchorEl, onClose, service, computer }) => {
  // Hooks
  const { user } = useContext(DataContext);
  const toast = useToast();
  const handleRequestErrors = useRequestErrorHandler();

  // States
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    open: false,
    title: "",
    message: "",
  });

  const sendActionService = async (action: string) => {
    onClose();

    try {
      const { data } = await newApi().post(`/commands/control-computer-service/${computer.id}`, {
        serviceName: service.displayName,
        action,
      });

      toast({ open: true, type: "success", message: data.message || "Command successfully sent" });
    } catch (err) {
      handleRequestErrors(err);
    }
  };

  // Open modal to ask for confirmation to remove the service
  const openConfirmationModal = () => {
    setConfirmationModal({
      open: true,
      title: `Remove ${service.displayName}?`,
      message: `Would you like to remove this service? You can add it again later (This will not delete the service on the host device)`,
    });

    onClose();
  };

  // Close the confirmation modal
  const closeConfirmationModal = () => {
    setConfirmationModal({ ...confirmationModal, open: false });
  };

  // Remove a service
  const handleRemoveService = async () => {
    try {
      setLoadingRemove(true);

      await newApi().delete(
        `/devices/computers/${computer.id}/services?name=${service.displayName}`
      );

      closeConfirmationModal();
    } catch (err) {
      handleRequestErrors(err);
    } finally {
      setLoadingRemove(false);
    }
  };

  return (
    <>
      <Can role={user.role} perform="services:control">
        <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onClose}>
          <Paper>
            <MenuList>
              <MenuItem onClick={() => sendActionService("start")}>Start</MenuItem>
              <MenuItem onClick={() => sendActionService("stop")}>Stop</MenuItem>
              <MenuItem onClick={() => sendActionService("restart")}>Restart</MenuItem>
              <Can role={user.role} perform="services:delete">
                <MenuItem onClick={openConfirmationModal}>Delete</MenuItem>
              </Can>
            </MenuList>
          </Paper>
        </Popover>
      </Can>

      <ConfirmationModal
        open={confirmationModal.open}
        onClose={closeConfirmationModal}
        title={confirmationModal.title}
        message={confirmationModal.message}
        onYes={handleRemoveService}
        onNo={closeConfirmationModal}
        yesDisabled={loadingRemove}
      />
    </>
  );
};

export default WindowsServicesMenu;
