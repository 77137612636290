import React, { useState } from "react";
// Material ui
import { Box, Divider, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
// Components
import { QrCodeActionForm } from "components/forms/QrCodeActionForm";
import { QrCodeAction } from "../@types/QrCode";

const selectActions: Array<{ value: QrCodeAction["event"]; label: string }> = [
  { value: "open-company-page", label: "Open company page" },
  { value: "open-device-page", label: "Open device page" },
];

export const EditQrCode: React.FC = () => {
  // States
  const [selectedAction, setSelectedAction] = useState<QrCodeAction["event"] | null>(null);

  return (
    <Box p={2}>
      <h3>Add an action</h3>

      <Divider className="mt-3 mb-4" />

      <FormControl variant="outlined" fullWidth size="small">
        <InputLabel id="qr-code-action-select">Actions</InputLabel>
        <Select
          labelId="qr-code-action-select"
          label="Actions"
          value={selectedAction ?? ""}
          onChange={(e) => setSelectedAction(e.target.value as QrCodeAction["event"])}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>

          {selectActions.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <QrCodeActionForm selectedEvent={selectedAction} />
    </Box>
  );
};
