import React, { useState, useEffect, useRef } from "react";
// Material ui
import {
  DialogContent,
  useMediaQuery,
  useTheme,
  DialogActions,
  Button,
  Tabs,
  makeStyles,
  Tab,
} from "@material-ui/core";
// Components
import DialogBasic from "./DialogBasic";
import { DeviceItem } from "../DisplayDevices";
import SystemInfo from "./ComputerInfoComponents/SystemInfo";
import ComputerModalHeader from "./ComputerModalHeader";
import WindowsBackup from "./WindowsBackup";
import WindowsLogs from "./WindowsLogs";
import WindowsServices from "./WindowsServices";
import ComputerSettings from "./ComputerSettings/ComputerSettings";
// Utils
import { Computer } from "../../@types/computer";
import { isVersionCompatible, isComputerOn } from "../../utils/functions";
import { requestUpdateComputerData } from "../../utils/computer";
import useComputers from "../../hooks/useComputers";

interface TabPanelProps {
  index: number;
  value: number;
  children?: React.ReactNode;
}

const TabPanel: React.FC<TabPanelProps> = ({ index, value, children, ...others }) => (
  <div
    id={`computer-tabpanel-${index}`}
    role="tabpanel"
    hidden={value !== index}
    className="p-2 w-100"
    {...others}
  >
    {value === index && children}
  </div>
);

interface Props {
  open: boolean;
  onClose: () => void;
  deviceItem: DeviceItem;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    padding: 0,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: "90px",
  },
  tabsButtons: { minWidth: "100%" },
}));

const ComputerInfoModal: React.FC<Props> = ({ open, onClose, deviceItem }) => {
  // Hooks
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const computers = useComputers();
  const isCurrent = useRef(true);

  // States
  const [tabValue, setTabValue] = useState(0);
  const [selectedComputer, setSelectedComputer] = useState<Computer>(undefined!);

  useEffect(
    () => () => {
      isCurrent.current = false;
    },
    []
  );

  useEffect(() => {
    if (!isCurrent.current) return;

    if (computers.length)
      setSelectedComputer(
        computers.reduce((acc, computer) => (computer.id === deviceItem.id ? computer : acc))
      );
  }, [computers, deviceItem.id]);

  if (!selectedComputer) return <React.Fragment />;

  const tabs = [
    {
      label: "System info",
      available: true,
      disabled: false,
      component: <SystemInfo computer={selectedComputer} />,
    },
    {
      label: "Services",
      available: isVersionCompatible("1.0.2", selectedComputer.connectorVersion),
      disabled: !isComputerOn(selectedComputer),
      component: <WindowsServices computer={selectedComputer} />,
    },
    {
      label: "Backups",
      available: isVersionCompatible("1.0.5", selectedComputer.connectorVersion),
      disabled: !isComputerOn(selectedComputer),
      component: <WindowsBackup computer={selectedComputer} />,
    },
    {
      label: "Windows Logs",
      available: isVersionCompatible("1.0.8", selectedComputer.connectorVersion),
      disabled: !isComputerOn(selectedComputer),
      component: <WindowsLogs computer={selectedComputer} />,
    },
    {
      label: "Settings",
      available: true,
      disabled: false,
      component: <ComputerSettings computer={selectedComputer} />,
    },
  ];

  // Check if any command is running and if the device is on
  const isRefreshBtnDisabled = (): boolean => {
    const { commands } = selectedComputer;
    return (selectedComputer.connected && !isComputerOn(selectedComputer)) ||
      (commands && commands.updateConnector) ||
      (commands && commands.update)
      ? true
      : false;
  };

  // Send command to update the computer info
  const handleRefresh = () => {
    if (!isRefreshBtnDisabled()) {
      requestUpdateComputerData(selectedComputer.id);
    }
  };

  return (
    <DialogBasic
      open={open}
      onClose={onClose}
      title={<ComputerModalHeader computer={selectedComputer} deviceItem={deviceItem} />}
      widthSize="lg"
      fullScreen={fullScreen}
    >
      <DialogContent className={classes.root}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={tabValue}
          onChange={(e, newValue) => setTabValue(newValue)}
          className={classes.tabs}
        >
          {tabs
            .filter((tab) => tab.available)
            .map((tab, index) => (
              <Tab
                key={index}
                label={tab.label}
                id={`computer-tab-${index}`}
                disabled={tab.disabled}
                wrapped
                className={classes.tabsButtons}
                style={tab.disabled ? { opacity: 0.3 } : {}}
              />
            ))}
        </Tabs>

        {tabs
          .filter((tab) => tab.available)
          .map((tab, index) => (
            <TabPanel key={index} index={index} value={tabValue}>
              {tabValue === index && tab.component}
            </TabPanel>
          ))}
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>

        <Button color="primary" disabled={isRefreshBtnDisabled()} onClick={handleRefresh}>
          Refresh
        </Button>
      </DialogActions>
    </DialogBasic>
  );
};

export default ComputerInfoModal;
