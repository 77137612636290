import React, { useState, useEffect, useRef } from "react";
import * as yup from "yup";
import moment from "moment";
// Material ui
import { Grid, MenuItem, Button } from "@material-ui/core";
// Icons
import { Search } from "@material-ui/icons";
// Formik
import { Form, Formik } from "formik";
import CustomSelect from "../Formik/CustomSelect";
import CustomTextField from "../Formik/CustomTextField";
import DatePickerRange from "../DatePickerRange";
// Component
import Loading from "../Loading";
import WinLogsTable, { Event } from "./WinLogsTable";
// Utils
import api from "../../utils/api";
import useLocalStorage from "../../hooks/useLocalStorage";
import { isComputerOn } from "../../utils/functions";
import { Computer } from "../../@types/computer";
import useRequestErrorHandler from "../../hooks/useRequestErrorHandler";

interface Props {
  computer: Computer;
}

const fetchLogsName = async (computerId: string, token: string) => {
  const { data } = await api.get(`/commands/getWinLogsName/${computerId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return data.logNames as { success: boolean; data: string[] | { message: string } };
};

const WindowsLogs: React.FC<Props> = ({ computer }) => {
  // Hooks
  const [token] = useLocalStorage("token");
  const handleRequestErrors = useRef(useRequestErrorHandler());

  // States
  const [logsName, setLogsName] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [logs, setLogs] = useState<{ success: boolean; data: Event[] }>({
    success: true,
    data: [],
  });

  const requiredMessage = "This field is required";
  const initialValues = {
    logName: "",
    logLevel: "all",
    countLogs: 10,
    source: "",
    message: "",
    startDate: new Date(new Date().setHours(0, 0, 0, 0)),
    endDate: new Date(),
  };

  const validationSchema = yup.object({
    logName: yup.string().required(requiredMessage),
    logLevel: yup.string().required(requiredMessage),
    startDate: yup.date().required(requiredMessage).max(yup.ref("endDate")),
    endDate: yup.date().required(requiredMessage).min(yup.ref("startDate")),
    source: yup.string(),
    message: yup.string(),
    countLogs: yup
      .number()
      .required(requiredMessage)
      .integer()
      .min(1, "The number of logs must be greater than or equal to 1")
      .max(50, "The number of logs must be less than or equal to 50"),
  });

  const logLevels = [
    { label: "All", value: "all" },
    { label: "Information", value: "information" },
    { label: "Error", value: "error" },
    { label: "Warning", value: "Warning" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const { success, data } = await fetchLogsName(computer.id, token);

        if (!success) return handleRequestErrors.current({ response: data });
        // @ts-ignore
        setLogsName(data);
      } catch (err) {
        handleRequestErrors.current(err);
      } finally {
        setLoading(false);
      }
    };

    if (isComputerOn(computer)) {
      fetchData();
    }
  }, [computer, token]);

  const handleSubmit = async (data: typeof initialValues) => {
    const { logName, logLevel, countLogs, startDate, endDate, source, message } = data;
    setLoading(true);

    try {
      const level = logLevel !== "all" ? logLevel : "";
      const winLogData = {
        logName,
        count: countLogs,
        level,
        startDate: moment(startDate).format("MM/DD/YYYY HH:mm"),
        endDate: moment(endDate).format("MM/DD/YYYY HH:mm"),
        source,
        message,
      };

      const { data } = await api.post(`/commands/getWinLogs/${computer.id}`, winLogData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setLogs(data.logs);
    } catch (err) {
      handleRequestErrors.current(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-2">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        initialErrors={{ logName: "This field is required" }}
      >
        {({ values, setFieldValue, isValid }) => (
          <Form className="mb-3">
            <Grid container spacing={2}>
              {/* Log Name */}
              <Grid item xs={12} sm={4}>
                <CustomSelect
                  name="logName"
                  id="log-name"
                  label="Log name"
                  required
                  variant="outlined"
                  disabled={loading}
                >
                  {logsName.map((log) => (
                    <MenuItem key={log} value={log}>
                      {log}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </Grid>

              {/* Log level */}
              <Grid item xs={12} sm={4}>
                <CustomSelect
                  name="logLevel"
                  id="log-level"
                  label="Log level"
                  required
                  variant="outlined"
                  disabled={loading}
                >
                  {logLevels.map((log) => (
                    <MenuItem key={log.value} value={log.value}>
                      {log.label}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </Grid>

              {/* Number of logs */}
              <Grid item xs={12} sm={4}>
                <CustomTextField
                  name="countLogs"
                  label="Number of logs"
                  type="number"
                  fullWidth
                  required
                  variant="outlined"
                  inputProps={{ min: 1, max: 50 }}
                  disabled={loading}
                />
              </Grid>
            </Grid>

            <DatePickerRange
              startValue={values.startDate}
              startName="startDate"
              endValue={values.endDate}
              endName="endDate"
              onChange={(e, label) => setFieldValue(`${label}Date`, e?.toDate())}
              setError={setDateError}
              disabled={loading}
            />

            <Grid container spacing={2}>
              {/* Source */}
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  name="source"
                  label="Source"
                  fullWidth
                  variant="outlined"
                  disabled={loading}
                />
              </Grid>

              {/* Message */}
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  name="message"
                  label="Message"
                  fullWidth
                  variant="outlined"
                  disabled={loading}
                />
              </Grid>
            </Grid>

            <Button
              type="submit"
              variant="outlined"
              fullWidth
              startIcon={<Search />}
              className="mt-2"
              disabled={loading || dateError || !isValid || !isComputerOn(computer)}
            >
              Search
            </Button>
          </Form>
        )}
      </Formik>

      {loading ? <Loading message="Loading logs" /> : <WinLogsTable events={logs} />}
    </div>
  );
};

export default WindowsLogs;
