import React, { useContext, useEffect, useRef, useState } from "react";
import * as yup from "yup";
// Formik
import { Formik, Form, FormikHelpers } from "formik";
import CustomAutocomplete from "../Formik/CustomAutocomplete";
// Material ui
import { DialogContent, DialogActions, Button, Grid, Divider } from "@material-ui/core";
// Components
import DialogBasic from "./DialogBasic";
import CustomTextField from "../Formik/CustomTextField";
import CustomSwitch from "../Formik/CustomSwitch";
// Utils
import DataContext from "../../context/DataContext";
import { User, UserGroup } from "../../@types/users";
import { Company } from "../../@types/company";
import api from "../../utils/api";
import useLocalStorage from "../../hooks/useLocalStorage";
import UserGroupContext from "../../context/UserGroupsContext";
import { getDetailsFromUserGroup } from "../../utils/apiRequests";
import useToast from "../../hooks/useToast";
import useRequestErrorHandler from "../../hooks/useRequestErrorHandler";

interface Props {
  open: boolean;
  onClose: () => void;
  group?: UserGroup;
}

interface FormData {
  name: string;
  maxDevices: number;
  companies: Company[];
  users: User[];
  status: boolean;
}

// Fetch all the users that are not in a group
const fetchUsers = async (token: string) => {
  const { data } = await api.get("/users?group=false", {
    headers: { Authorization: `Bearer ${token}` },
  });

  return data.users.sort((a, b) => (a.email > b.email ? 1 : -1)) as User[];
};

// FORMIK - validation schema
const validationSchema = yup.object({
  name: yup.string().required("Group name is required"),
  maxDevices: yup
    .number()
    .integer()
    .min(1, "The value must be greater than or equals to 1")
    .required("Max devices is required"),
  companies: yup
    .array()
    .of(yup.string())
    .min(1, "You must choose at least 1 company")
    .required("This field is required"),
  users: yup
    .array()
    .of(yup.string())
    .min(1, "You must choose at least 1 user")
    .required("This field is required"),
});

const UserGroupsForm: React.FC<Props> = ({ open, onClose, group }) => {
  // Hooks
  const [token] = useLocalStorage("token");
  const { companies } = useContext(DataContext);
  const {
    state: { selectedGroup },
    dispatch,
  } = useContext(UserGroupContext);
  const usersRef = useRef<User[]>([]);
  const toast = useToast();
  const handleRequestErrors = useRef(useRequestErrorHandler());

  // States
  const [users, setUsers] = useState<User[]>(group ? selectedGroup.users : []);

  useEffect(() => {
    if (open) {
      usersRef.current = selectedGroup.users;
    }
  }, [open, selectedGroup.users]);

  // Fetch the users
  useEffect(() => {
    let active = true;

    const getUsers = async () => {
      try {
        const users = await fetchUsers(token);

        if (active) {
          setUsers(group?.users ? [...users, ...usersRef.current] : users);
        }
      } catch (err) {
        handleRequestErrors.current(err);
      }
    };

    if (open) {
      getUsers();
    }

    return () => {
      active = false;
    };
  }, [group, open, token]);

  const getGroupCompanies = (ids: string[]): Company[] =>
    companies.filter((comp: Company) => ids.includes(comp._id));

  const initialValues: FormData = {
    name: group?.name || "",
    maxDevices: group?.maxDevices || 50,
    companies: group?.companies ? getGroupCompanies(group.companies) : [],
    users: group?.users ? selectedGroup.users : [],
    status: group?.status ? group.status === "active" : true,
  };

  const getGroupStatus = (status: boolean) => (status ? "active" : "disabled");

  const handleSubmit = async (formData: FormData, { setSubmitting }: FormikHelpers<FormData>) => {
    setSubmitting(true);

    const reqHeaders = { headers: { Authorization: `Bearer ${token}` } };
    const reqData = {
      name: formData.name,
      maxDevices: formData.maxDevices,
      companiesId: formData.companies.map((comp) => comp._id),
      usersId: formData.users.map((user) => user._id),
    };

    try {
      if (!group) {
        const { data } = await api.post("/users/groups", reqData, reqHeaders);
        dispatch({ type: "ADD_GROUP", payload: data.group });
      } else {
        const { data } = await api.put(
          `/users/groups/${group._id}`,
          { ...reqData, status: getGroupStatus(formData.status) },
          reqHeaders
        );
        const { companies } = await getDetailsFromUserGroup(group._id, token, "companies");

        dispatch({
          type: "UPDATE_GROUP",
          payload: {
            id: group._id,
            group: data.group,
            newComps: companies,
            newUsers: formData.users,
          },
        });
      }

      toast({ open: true, type: "success", message: "User group successfully created" });
      onClose();
    } catch (err) {
      handleRequestErrors.current(err);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <DialogBasic open={open} onClose={onClose} title={`${!group ? "Add" : "Edit"} user group`}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, errors, touched, setFieldTouched, isSubmitting }) => (
            <Form>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <CustomTextField
                      name="name"
                      label="Group name"
                      disabled={isSubmitting}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm>
                    <CustomTextField
                      name="maxDevices"
                      label="Max devices"
                      type="number"
                      inputProps={{ min: 0 }}
                      disabled={isSubmitting}
                      fullWidth
                      required
                    />
                  </Grid>

                  {group && (
                    <Grid item xs={12} sm>
                      <CustomSwitch
                        name="status"
                        label="Group status:"
                        switchLabel={values.status ? "Activated" : "Disabled"}
                        switchprops={{ color: "primary" }}
                      />
                    </Grid>
                  )}
                </Grid>

                <Divider className="my-4" />

                <Grid container spacing={2}>
                  <Grid item xs={12} sm>
                    <CustomAutocomplete
                      name="companies"
                      label="Companies"
                      disableCloseOnSelect
                      multiple
                      limitTags={2}
                      options={companies}
                      getOptionLabel={(opt: Company) => opt.name}
                      disabled={isSubmitting}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} sm>
                    <CustomAutocomplete
                      name="users"
                      label="Users"
                      disableCloseOnSelect
                      multiple
                      limitTags={1}
                      options={users}
                      getOptionLabel={(opt: Company) => opt.name}
                      loading={users.length === 0}
                      disabled={isSubmitting}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button onClick={onClose}>Cancel</Button>

                <Button type="submit" disabled={isSubmitting}>
                  {!group ? "Add" : "Edit"}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogBasic>
    </>
  );
};

export default UserGroupsForm;
