// Utils
import { Computer } from "../@types/computer";
import { Company } from "../@types/company";

export const getSize = (bytes: number): string => {
  const factor = 1024;

  for (const unit of ["", "K", "M", "G", "T", "P"]) {
    if (bytes < factor) {
      return `${bytes.toFixed(2)}${unit}B`;
    }

    bytes /= factor;
  }

  return "";
};

export const isVersionCompatible = (minVersion: string, currentVersion: string): boolean => {
  // Check if it has a current version
  if (!currentVersion) {
    return false;
  }

  // Check if version are equals
  if (minVersion === currentVersion) {
    return true;
  }

  const minParts = minVersion.split(".");
  const currentParts = currentVersion.split(".");

  for (let i = 0; i < currentParts.length; i++) {
    const a = parseInt(currentParts[i]) || 0;
    const b = parseInt(minParts[i]) || 0;

    if (a > b) return true;
    if (a < b) return false;
  }

  return false;
};

export const getCompanyById = (id: string, companies: Company[]): Company => {
  return companies.filter((company) => company._id === id)[0];
};

export const getSpacePercentage = (used: number, total: number) => (used * 100) / total;

export const exportDataToCsv = (header: string[], data: Array<string[]>, fileName: string) => {
  const csvArray = [header, ...data];
  const csvString = csvArray.map((arr) => arr.join(";")).join("\n");

  const link = document.createElement("a");
  link.href = "data:attachment/csv," + csvString;
  link.target = "_Blank";
  link.download = `${fileName}.csv`;
  document.body.appendChild(link);
  link.click();
};

// Check if the computer is on
export const isComputerOn = (computer: Computer) => {
  return computer.connected && computer.connected.connected;
};
