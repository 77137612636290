import React, { useState, useEffect } from "react";
// Formik
import { Formik, Form } from "formik";
import * as yup from "yup";
// Material ui
import { Typography, Button, makeStyles } from "@material-ui/core";
// Components
import CustomTextField from "../../Formik/CustomTextField";
// Utils
import { Computer } from "../../../@types/computer";
import { isVersionCompatible } from "../../../utils/functions";
import { newApi } from "../../../utils/api";
import useToast from "../../../hooks/useToast";
import useRequestErrorHandler from "../../../hooks/useRequestErrorHandler";

interface Props {
  computer: Computer;
}

const useStyles = makeStyles((theme) => ({
  btnEdit: {
    maxHeight: "40px",
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginLeft: theme.spacing(0),
    },
  },
}));

const EditComputerName: React.FC<Props> = ({ computer }) => {
  // Hooks
  const classes = useStyles();
  const toast = useToast();
  const handleRequestErrors = useRequestErrorHandler();

  // States
  const [isOptionAvailable, setIsOptionAvailable] = useState(true);
  const [obsMessage, setObsMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const initialValues = {
    computerName: computer.name || computer.computerName,
  };
  const validationSchema = yup.object({
    computerName: yup.string().required("The computer name is required"),
  });

  useEffect(() => {
    const isCompatible = isVersionCompatible("1.0.0", computer.connectorVersion);

    setIsOptionAvailable(isCompatible);
    setObsMessage(
      isCompatible
        ? "OBS: This name will only be used inside the site"
        : "Please, update the connector to unlock this feature"
    );
  }, [computer.connectorVersion]);

  const handleSubmit = async ({ computerName }: typeof initialValues) => {
    setLoading(true);

    try {
      const { data } = await newApi().put(`/devices/computers/${computer.id}/name`, {
        name: computerName,
      });

      toast({ open: true, type: "success", message: data.message });
    } catch (err) {
      handleRequestErrors(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Typography variant="h6">Edit computer name</Typography>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form className="d-flex flex-wrap align-items-center mt-2">
            <CustomTextField
              name="computerName"
              label="Computer name"
              required
              disabled={!isOptionAvailable || loading}
              className="flex-grow-1"
              helperText={obsMessage}
            />

            <Button
              type="submit"
              variant="outlined"
              disabled={!isOptionAvailable || loading}
              className={classes.btnEdit}
            >
              Edit
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EditComputerName;
