import React, { useState } from "react";
// Material ui
import {
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Typography,
  makeStyles,
} from "@material-ui/core";
// Colors
import { red, orange, blue } from "@material-ui/core/colors";
// Icons
import { KeyboardArrowUp, KeyboardArrowDown } from "@material-ui/icons";
// Interface
import { Event } from "./WinLogsTable";

interface RowsProps {
  event: Event;
}

const useStyles = makeStyles((theme) => ({
  Error: { color: red[500] },
  Warning: { color: orange[500] },
  Information: { color: blue[200] },
}));

const WinLogsTableRows: React.FC<RowsProps> = ({ event }) => {
  // Hooks
  const classes = useStyles();

  // State
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell onClick={() => setOpen(!open)}>
          <IconButton size="small">{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</IconButton>
        </TableCell>
        <TableCell component="th" scope="row" className={classes[event.EntryType]}>
          <strong>{event.EntryType}</strong>
        </TableCell>
        <TableCell>{event.Source}</TableCell>
        <TableCell>{event.EventID}</TableCell>
        <TableCell>{event.TimeWritten}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit className="p-2">
            {/* Task category */}
            <Typography>
              <strong>Task category:</strong> {event.Category !== "(0)" ? event.Category : "None"}
            </Typography>

            {/* Machine */}
            <Typography>
              <strong>Machine:</strong> {event.MachineName}
            </Typography>

            {/* Data */}
            {event.Data !== "{}" && (
              <Typography>
                <strong>Data:</strong> {event.Data}
              </Typography>
            )}

            {/* Message */}
            <Typography>
              <strong>Message:</strong> {event.Message}
            </Typography>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default WinLogsTableRows;
