import React, { useContext, useState } from "react";
import * as yup from "yup";
// Formik
import { Form, Formik, FormikHelpers } from "formik";
// Material ui
import { Button, DialogActions, DialogContent, Divider, Grid } from "@material-ui/core";
// Components
import CustomAutocomplete from "../Formik/CustomAutocomplete";
import CustomTextField from "../Formik/CustomTextField";
import DialogBasic from "./DialogBasic";
// Hooks
import useCompanyPage from "../../hooks/useCompanyPage";
import useFetch from "../../hooks/useFetch";
import useRequestErrorHandler from "../../hooks/useRequestErrorHandler";
import useLocalStorage from "../../hooks/useLocalStorage";
import useToast from "../../hooks/useToast";
// Utils
import DataContext from "../../context/DataContext";
import { errorMessages } from "../../constants/form";
import api from "../../utils/api";
import { Company } from "../../@types/company";
import { getDeviceTypeNames } from "../../utils/deviceUtils";
import { DeviceItem } from "../DisplayDevices";

interface Props {
  open: boolean;
  onClose: () => void;
  device?: DeviceItem;
}

interface FormData {
  company: Company;
  device: { id: string; name: string; type: string } | null;
  subject: string;
  description: string;
}

const validationSchema = yup.object({
  company: yup
    .object({ _id: yup.string().required(), name: yup.string().required() })
    .nullable()
    .required(errorMessages.requiredField),
  device: yup.object({ id: yup.string().required(), name: yup.string().required() }).nullable(),
  subject: yup.string().required(errorMessages.requiredField),
  description: yup.string().required(errorMessages.requiredField),
});

const TicketLink: React.FC<{ url: string; number: string }> = ({ url, number }) => (
  <a href={url} target="_blank" rel="noopener noreferrer" className="text-white">
    <strong>
      <u>#{number}</u>
    </strong>
  </a>
);

const ZendeskTicketForm: React.FC<Props> = ({ open, onClose, device }) => {
  // Hooks
  const { companies } = useContext(DataContext);
  const company = useCompanyPage();
  const handleRequestErrors = useRequestErrorHandler();
  const [token] = useLocalStorage("token");
  const toast = useToast();

  // States
  const [selectedCompany, setSelectedCompany] = useState(company || companies[0]);
  const { data, loading } = useFetch<{ devices: { id: string; name: string; type: string }[] }>(
    `/devices/nameAndType/${selectedCompany._id}`,
    { skip: !open }
  );

  if (!open) return null;

  const selectedDevice = device ? { id: device.id, name: device.name, type: device.type } : null;

  const initialValues: FormData = {
    company: company || companies[0],
    device: selectedDevice,
    subject: "",
    description: "",
  };

  const handleSubmit = async (formData: FormData, helpers: FormikHelpers<FormData>) => {
    helpers.setSubmitting(true);

    try {
      const requestData = {
        subject: formData.subject,
        description: formData.description,
        companyId: formData.company._id,
        deviceId: formData.device?.id || undefined,
      };

      const { data } = await api.post("/zendesk/ticket", requestData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      toast({
        open: true,
        type: "success",
        message: (
          <>
            Ticket successfully created (
            <TicketLink url={data.ticket.url} number={data.ticket.id} />)
          </>
        ),
      });
      onClose();
    } catch (err) {
      handleRequestErrors(err);
      helpers.setSubmitting(false);
    }
  };

  return (
    <DialogBasic open={open} onClose={onClose} title="Create a new ticket">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, isSubmitting }) => {
          return (
            <Form>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <CustomAutocomplete
                      name="company"
                      label="Company"
                      options={companies}
                      getOptionLabel={(opt) => opt.name}
                      disabled={isSubmitting}
                      // @ts-ignore
                      onChange={(e, newValue: Company) => {
                        setFieldValue("device", null);
                        if (newValue) {
                          setSelectedCompany(newValue);
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <CustomAutocomplete
                      name="device"
                      label="Device"
                      options={
                        data?.devices.sort((a, b) => (a.name > b.name ? 1 : -1)) ?? [selectedDevice]
                      }
                      getOptionSelected={(opt, value) => opt && value && opt.id === value.id}
                      getOptionLabel={(opt) =>
                        opt.name || opt.type
                          ? `${opt.name} (${getDeviceTypeNames(opt.type).formalName})`
                          : "None"
                      }
                      disabled={loading || isSubmitting}
                    />
                  </Grid>
                </Grid>

                <Divider className="mt-4" />

                <CustomTextField
                  name="subject"
                  label="Subject"
                  fullWidth
                  required
                  margin="normal"
                  disabled={isSubmitting}
                />

                <CustomTextField
                  name="description"
                  label="Description"
                  fullWidth
                  required
                  multiline
                  rowsMax={10}
                  margin="normal"
                  disabled={isSubmitting}
                />
              </DialogContent>

              <DialogActions>
                <Button onClick={onClose}>Cancel</Button>

                <Button type="submit" disabled={isSubmitting}>
                  Submit ticket
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </DialogBasic>
  );
};

export default ZendeskTicketForm;
