import React from "react";
import PropTypes from "prop-types";
// Material ui
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  DialogContentText,
  CircularProgress,
} from "@material-ui/core";
// Components
import Transition from "./ModalTransition";

const ConfirmationModal = ({
  open,
  onClose,
  title,
  message,
  yesLabel,
  noLabel,
  onYes,
  onNo,
  yesDisabled,
  yesLoading,
  noDisabled,
}) => (
  <>
    <Dialog open={open} onClose={onClose} TransitionComponent={Transition}>
      <DialogTitle>{title}</DialogTitle>

      <Divider />

      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>

      <DialogActions>
        {noLabel.trim() !== "" && (
          <Button color="primary" onClick={onNo || onClose} disabled={noDisabled}>
            {noLabel}
          </Button>
        )}

        <Button color="primary" onClick={onYes} autoFocus disabled={yesDisabled || yesLoading}>
          {yesLabel}
        </Button>

        {yesLoading && <CircularProgress />}
      </DialogActions>
    </Dialog>
  </>
);

ConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  yesLabel: PropTypes.string,
  noLabel: PropTypes.string,
  onYes: PropTypes.func.isRequired,
  onNo: PropTypes.func,
  yesDisabled: PropTypes.bool,
  yesLoading: PropTypes.bool,
  noDisabled: PropTypes.bool,
};

ConfirmationModal.defaultProps = {
  yesLabel: "Yes",
  noLabel: "No",
  yesDisabled: false,
  yesLoading: false,
  noDisabled: false,
};

export default ConfirmationModal;
