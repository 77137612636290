import React from "react";
// Material ui
import { Dialog, DialogProps, DialogTitle, Divider } from "@material-ui/core";
// Components
import Transition from "./ModalTransition";

type Props = Pick<DialogProps, Exclude<keyof DialogProps, "title">> & {
  title?: string | React.ReactNode;
  widthSize?: false | "md" | "xs" | "sm" | "lg" | "xl";
};

const DialogBasic: React.FC<Props> = ({
  open,
  onClose,
  title,
  widthSize = "md",
  children,
  ...props
}) => (
  <>
    <Dialog
      fullWidth
      maxWidth={widthSize}
      {...props}
      open={open}
      onClose={onClose}
      // @ts-ignore
      TransitionComponent={Transition}
    >
      {title && (
        <>
          <DialogTitle>{title}</DialogTitle>

          <Divider />
        </>
      )}

      {children}
    </Dialog>
  </>
);
export default DialogBasic;
