import axios, { AxiosInstance } from "axios";
import { backend } from "./configs";

type NewApi = (options?: { skipAuthorization?: boolean }) => AxiosInstance;

export const newApi: NewApi = ({ skipAuthorization = false } = {}) => {
  const token = !skipAuthorization ? JSON.parse(window.localStorage.getItem("token") || "") : "";

  return axios.create({
    baseURL: backend.api,
    headers: !skipAuthorization ? { Authorization: `Bearer ${token}` } : undefined,
  });
};

export const getUsers = async (token) => {
  return await api.get("/users", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const api = axios.create({ baseURL: backend.api });

export default api;
