import React from "react";
// Material ui
import { CircularProgress, Typography, makeStyles } from "@material-ui/core";

interface Props {
  message?: string;
  style?: React.CSSProperties;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "30px 0",
  },
  progress: {
    margin: theme.spacing(2),
  },
}));

const Loading: React.FC<Props> = ({ message, style }) => {
  const classes = useStyles();
  return (
    <div className={classes.root} style={style}>
      <CircularProgress className={classes.progress} />
      {message !== "" && <Typography variant="h5">{message}</Typography>}
    </div>
  );
};

export default Loading;
