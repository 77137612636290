import React from "react";
// Material ui
import MUIDataTable from "mui-datatables";
import { User } from "../../@types/users";
import { green, orange, red } from "@material-ui/core/colors";
import { IconButton } from "@material-ui/core";
import { Edit } from "@material-ui/icons";

interface Props {
  title?: string;
  data: User[];
  loading?: boolean;
  onEdit?: (user: User) => void;
}

const columns = [
  { name: "id", label: "ID", options: { filter: false } },
  { name: "name", label: "Name", options: { filter: false } },
  { name: "email", label: "Email", options: { filter: false } },
  { name: "role", label: "Role" },
  {
    name: "status",
    label: "Status",
    options: {
      filter: true,
      filterOptions: {
        names: ["Completed", "Pending", "Disabled"],
        logic: (status, filters) => !filters.includes(status.props.children),
      },
    },
  },
  { name: "action", label: "Action", options: { filter: false } },
];

const NewUsersTable: React.FC<Props> = ({ title = "", data, loading = false, onEdit }) => {
  // Format user's role
  const displayRole = (role: User["role"]) => {
    if (role === "superAdmin") return "Super Admin";

    return role[0].toUpperCase() + role.slice(1);
  };

  // Get the proper status color
  const getUserStatusColor = (status: User["status"]) => {
    switch (status) {
      case "completed":
        return green[600];
      case "pending":
        return orange[800];
      case "disabled":
        return red[600];

      default:
        break;
    }
  };

  const getRowData = (user: User, index: number) => {
    const row = {
      id: ++index,
      name: user.name,
      email: user.email,
      role: displayRole(user.role),
      status: (
        <span style={{ color: getUserStatusColor(user.status) }}>
          {user.status.replace(/^\w/, (c) => c.toUpperCase())}
        </span>
      ),
    };

    if (onEdit) {
      // @ts-ignore
      row.action = (
        <IconButton component="span" title="Edit" onClick={() => onEdit(user)}>
          <Edit />
        </IconButton>
      );
    }

    return row;
  };

  return (
    <MUIDataTable
      title={title}
      columns={columns.filter((column) => column.name !== "action" || onEdit)}
      data={loading ? [["Loading users..."]] : data.map(getRowData)}
      options={{ download: false, print: false, selectableRows: "none" }}
    />
  );
};

export default NewUsersTable;
