import React, { useState } from "react";
import { Redirect, useHistory, useParams } from "react-router";
// Material ui
import { Box, Typography } from "@material-ui/core";
// Components
import Loading from "components/Loading";
import { OpenCompanyPageAction } from "./OpenCompanyPageAction";
import { OpenDevicePageAction } from "./OpenDevicePageAction";
// Hooks
import { useQrCode } from "hooks/useQrCode";
import useToast from "hooks/useToast";
import useRequestErrorHandler from "hooks/useRequestErrorHandler";
// Utils
import { QrCodeAction } from "../../../@types/QrCode";
import { newApi } from "utils/api";

interface QrCodeActionFormProps {
  selectedEvent: QrCodeAction["event"] | null;
}

export const QrCodeActionForm: React.FC<QrCodeActionFormProps> = ({ selectedEvent }) => {
  // Hooks
  const { qrCodeId } = useParams<{ qrCodeId: string }>();
  const { qrCode, isLoading } = useQrCode(qrCodeId);
  const toast = useToast();
  const history = useHistory();
  const handleRequestError = useRequestErrorHandler();

  // States
  const [isLoadingSubmitRequest, setIsLoadingSubmitRequest] = useState(false);

  if (isLoading && !qrCode) return <Loading message="Loading QR code" />;

  if (!isLoading && !qrCode) return <Redirect to="/dashboard/qrcodes" />;

  const handleSubmit = async (payload: Record<string, string>) => {
    try {
      setIsLoadingSubmitRequest(true);

      const { data } = await newApi().post<{ message: string }>(`/qrcodes/${qrCodeId}/action`, {
        event: selectedEvent,
        payload,
      });

      toast({ open: true, type: "success", message: data.message });
      history.push("/dashboard/qrcodes");
    } catch (err) {
      handleRequestError(err);
      setIsLoadingSubmitRequest(false);
    }
  };

  switch (selectedEvent) {
    case "open-company-page":
      return <OpenCompanyPageAction isLoading={isLoadingSubmitRequest} onSubmit={handleSubmit} />;

    case "open-device-page":
      return <OpenDevicePageAction isLoading={isLoadingSubmitRequest} onSubmit={handleSubmit} />;

    default:
      return (
        <Box pt={2}>
          <Typography variant="h6" align="center">
            Please, select an action
          </Typography>
        </Box>
      );
  }
};
