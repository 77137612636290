import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// Page
import Main from "pages/Main";
import Company from "pages/Company";
import Users from "pages/Users";
import UserGroups from "pages/UserGroups";
import UserActivities from "pages/UserActivities";
import { QrCodes } from "pages/QrCodes";
import { QrCodeAction } from "pages/QrCodeAction";
import { EditQrCode } from "pages/EditQrCode";
// Components
import Can from "components/Can";
// Context
import { CompanyProvider } from "context/CompanyContext";
import { UserGroupProvider } from "context/UserGroupsContext";
import DataContext from "context/DataContext";

const UserGroupComponent = () => {
  const { user } = useContext(DataContext);

  return (
    <Can role={user.role} perform="user-groups:visit" no={<Redirect to="/dashboard" />}>
      <UserGroupProvider>
        <UserGroups />
      </UserGroupProvider>
    </Can>
  );
};

const CompanyPage = () => (
  <CompanyProvider>
    <Company />
  </CompanyProvider>
);

const Routes = () => (
  <Switch>
    <Route path="/dashboard/" exact component={Main} />

    <Route path="/dashboard/company/:id" exact component={CompanyPage} />
    <Route path="/dashboard/company/:id/devices/:deviceId" exact component={CompanyPage} />

    <Route path="/dashboard/users" exact component={Users} />
    <Route path="/dashboard/users/groups" exact component={UserGroupComponent} />
    <Route path="/dashboard/users/groups/:id" exact component={UserGroupComponent} />
    <Route path="/dashboard/users/groups/:id/:type" exact component={UserGroupComponent} />

    <Route path="/dashboard/users/activities" component={UserActivities} />

    <Route path="/dashboard/qrcodes" exact component={QrCodes} />
    <Route path="/dashboard/qrcodes/:qrCodeId" exact component={QrCodeAction} />
    <Route path="/dashboard/qrcodes/:qrCodeId/edit" exact component={EditQrCode} />

    <Route render={() => <Redirect to="/dashboard" />} />
  </Switch>
);
export default Routes;
