import React from "react";
import moment from "moment";
// Types
import { DeviceTypes, BasicDeviceInfo, Computer } from "../@types/computer";
// Icons
import { Computer as ComputerIcon, Print, TabletMac } from "@material-ui/icons";
import { ReactComponent as AccessPoint } from "../assets/access-point.svg";
import { ReactComponent as NetworkSwitch } from "../assets/network-switch.svg";
import { SvgIcon } from "@material-ui/core";
// Utils
import { getSpacePercentage } from "./functions";
import { DeviceItem } from "../components/DisplayDevices";
import { getGroupDetails } from "./apiRequests";

export const deviceTypes = [
  { name: "ap", pluralName: "aps", formalName: "AP", formalPluralName: "APs" },
  {
    name: "computer",
    pluralName: "computers",
    formalName: "Computer",
    formalPluralName: "Computers",
  },
  { name: "printer", pluralName: "printers", formalName: "Printer", formalPluralName: "Printers" },
  { name: "switch", pluralName: "switches", formalName: "Switch", formalPluralName: "Switches" },
  { name: "tablet", pluralName: "tablets", formalName: "Tablet", formalPluralName: "Tablets" },
];

interface TypeIcon {
  value: DeviceTypes;
  icon: JSX.Element;
}

export const typeIcons: TypeIcon[] = [
  {
    value: "ap",
    icon: (
      <SvgIcon style={{ fontWeight: 700 }}>
        <AccessPoint />
      </SvgIcon>
    ),
  },
  { value: "computer", icon: <ComputerIcon /> },
  { value: "printer", icon: <Print /> },
  { value: "tablet", icon: <TabletMac /> },
  {
    value: "switch",
    icon: (
      <SvgIcon>
        <NetworkSwitch />
      </SvgIcon>
    ),
  },
];

export const getDeviceTypeNames = (type: DeviceTypes) => {
  return deviceTypes.reduce((acc, val) => (Object.values(val).includes(type) ? val : acc));
};

export const checkComputerWarnings = (computer: Computer) => {
  // Check space in disk
  if (computer.disks) {
    const lowSpaceInDisk = computer.disks.reduce(
      (acc, disk) => (getSpacePercentage(disk.used, disk.size) >= 80 ? true : acc),
      false
    );

    if (lowSpaceInDisk) {
      return "One of the disks is running low in space";
    }
  }

  // Check for available memory
  if (computer.memory) {
    const { used, total } = computer.memory;

    if ((used * 100) / total > 80 && computer.connected && computer.connected.connected) {
      return "Low memory available";
    }
  }

  return "";
};

export const checkOtherDevicesWarnings = (device: BasicDeviceInfo) => {
  // Check warranty
  if (device.warrantyDate) {
    const warrantyMoment = moment(new Date(device.warrantyDate));
    const warrantyDays = warrantyMoment.diff(new Date(), "days");

    if (moment().isAfter(warrantyMoment)) {
      return "Your warranty has been expired";
    }

    if (warrantyDays <= 60) {
      return `Your warranty will expire ${warrantyMoment.fromNow()}`;
    }
  }

  return "";
};

// Convert computer values to a device card obj
export const computersToCard = (computers: Computer[]): DeviceItem[] =>
  computers.map((computer) => ({
    id: computer.id,
    name: computer.name || computer.computerName,
    type: "computer",
    connected: computer.connected,
    information: [
      { name: "Domain", value: computer.domain },
      { name: "Connector version", value: computer.connectorVersion || "1.0.0" },
    ],
    warning: checkComputerWarnings(computer),
  }));

// Convert device values to a device card obj
export const devicesToCard = (devices: BasicDeviceInfo[]): DeviceItem[] =>
  devices.map((device) => ({
    id: device._id,
    name: device.name,
    type: device.type,
    information: [
      { name: "Brand", value: device.brand },
      { name: "Model", value: device.model },
    ],
    warning: checkOtherDevicesWarnings(device),
  }));

export const getDeviceCards = async (groupId: string, companyId: string, token: string) => {
  const groupDetails = await getGroupDetails(groupId, companyId, token);

  return [
    ...devicesToCard(groupDetails.devices as BasicDeviceInfo[]),
    ...computersToCard(groupDetails.computers),
  ];
};
