import React, { useState, useEffect, useContext, useRef } from "react";
import copy from "copy-to-clipboard";
// Material ui
import { Button, CircularProgress, DialogActions } from "@material-ui/core";
// Hooks
import useLocalStorage from "../../hooks/useLocalStorage";
import useRequestErrorHandler from "../../hooks/useRequestErrorHandler";
// Utils
import { Company } from "../../@types/company";
import api from "../../utils/api";
import CompanyContext from "../../context/CompanyContext";
import DataContext from "../../context/DataContext";
import { backend } from "../../utils/configs";

type AddNewPendingDeviceProps = (token: string, company: Company) => Promise<string[]>;

const addNewPendingDevice: AddNewPendingDeviceProps = async (token, company) => {
  const requestData = { companyId: company._id };

  const { data } = await api.post("/devices/computers", requestData, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return data.computerKeys as string[];
};

interface Props {
  open: boolean;
  company: Company;
  onClose: () => void;
}

const AddComputer: React.FC<Props> = ({ open, company, onClose }) => {
  // Hooks
  const [token] = useLocalStorage("token");
  const { user } = useContext(DataContext);
  const { totalDevices } = useContext(CompanyContext);
  const companyCtx = useRef(useContext(CompanyContext));
  const handleRequestErrors = useRef(useRequestErrorHandler());

  // States
  const [deviceId, setDeviceId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    const fetchComputerKey = async () => {
      setIsLoading(true);
      setIsCopied(false);

      try {
        const computerKeys = await addNewPendingDevice(token, company);
        setDeviceId(computerKeys[0]);

        // Update the total device values
        const { setTotalDevices, setTotalPendingCodes } = companyCtx.current;
        // @ts-ignore
        setTotalDevices((oldVal) => oldVal + 1);
        // @ts-ignore
        setTotalPendingCodes((oldVal) => oldVal + 1);
      } catch (err) {
        handleRequestErrors.current(err);
        setDeviceId("Something went wrong");
      } finally {
        setIsLoading(false);
      }
    };

    if (deviceId === "" && open) {
      fetchComputerKey();
    }
  }, [open, deviceId, token, company]);

  // Clean device code before closes
  const handleModalClose = () => {
    setDeviceId("");
    onClose();
  };

  const handleCopy = () => {
    copy(deviceId);
    setIsCopied(true);
  };

  return (
    <>
      {!isLoading ? (
        <>
          <p className="mt-3 mb-0">
            <strong>Computer code: </strong>
            {deviceId}
            <Button className="ml-sm-2" variant="outlined" onClick={handleCopy}>
              {isCopied ? "Copied!" : "Copy code"}
            </Button>
          </p>

          <p className="mt-2">
            Use this code to install the connector. After the installation, the device will appear
            on in the company list
          </p>
        </>
      ) : (
        <div className="text-center">
          <CircularProgress />
        </div>
      )}

      <DialogActions>
        <Button onClick={handleModalClose}>Cancel</Button>

        <Button
          onClick={() => setDeviceId("")}
          disabled={isLoading}
          hidden={
            (company.maxDevices !== undefined && totalDevices + 1 > company.maxDevices) ||
            (user.userGroup && totalDevices + 1 > user.userGroup.maxDevices)
          }
        >
          Add a new one
        </Button>

        <Button component="a" href={backend.latestClientUrl} style={{ color: "white" }}>
          Download
        </Button>
      </DialogActions>
    </>
  );
};

export default AddComputer;
