import React, { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
// Material ui
import { Link as MuiLink } from "@material-ui/core";
import { CellProps } from "react-table";
import { QrCode } from "../../../@types/QrCode";

export const IdCell = ({ cell }: PropsWithChildren<CellProps<QrCode, QrCode["_id"]>>) => (
  <MuiLink component={Link} to={`/dashboard/qrcodes/${cell.value}`}>
    {cell.value}
  </MuiLink>
);
