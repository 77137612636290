import React from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
// Material ui
import { Button, DialogActions, DialogContent, DialogProps } from "@material-ui/core";
// Components
import DialogBasic from "./DialogBasic";
import CustomTextField from "components/Formik/CustomTextField";
import useRequestErrorHandler from "hooks/useRequestErrorHandler";
import { newApi } from "utils/api";
import { useQrCodes } from "hooks/useQrCodes";
import useToast from "hooks/useToast";

interface QrCodeFormProps extends Omit<DialogProps, "onSubmit" | "onClose"> {
  onClose: () => void;
}

const initialValues = {
  amount: 5,
};

const validationSchema = yup.object({
  amount: yup.number().integer().positive().required(),
});

export const QrCodeForm: React.FC<QrCodeFormProps> = (props) => {
  // Hooks
  const { mutate } = useQrCodes();
  const toast = useToast();
  const handleRequestErrors = useRequestErrorHandler();

  if (!props.open) return null;

  const handleSubmit = async (formData: typeof initialValues) => {
    try {
      await newApi().post("/qrcodes/many", formData);

      toast({
        open: true,
        type: "success",
        message: `${formData.amount} QR code(s) successfully created`,
      });
      mutate();
      props.onClose();
    } catch (err) {
      handleRequestErrors(err);
    }
  };

  return (
    <DialogBasic {...props} title="QR codes form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <DialogContent>
              <CustomTextField
                name="amount"
                label="Amount"
                required
                type="number"
                fullWidth
                disabled={isSubmitting}
              />
            </DialogContent>

            <DialogActions>
              <Button onClick={props.onClose}>Cancel</Button>

              <Button type="submit" disabled={isSubmitting}>
                Submit
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </DialogBasic>
  );
};
