import React, { useContext } from "react";
import * as yup from "yup";
// Formik
import { Form, Formik, FormikHelpers } from "formik";
// Material ui
import {
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Radio,
} from "@material-ui/core";
// Components
import DialogBasic from "./DialogBasic";
import CustomAutocomplete from "../Formik/CustomAutocomplete";
import CustomRadio from "../Formik/CustomRadio";
import CustomTextField from "../Formik/CustomTextField";
import CustomSwitch from "../Formik/CustomSwitch";
// Utils
import DataContext from "../../context/DataContext";
import { User } from "../../@types/users";
import { Company } from "../../@types/company";
import useLocalStorage from "../../hooks/useLocalStorage";
import api from "../../utils/api";
import useToast from "../../hooks/useToast";
import useRequestErrorHandler from "../../hooks/useRequestErrorHandler";

interface Props {
  open: boolean;
  onClose: () => void;
  user?: User;
  onSubmitSuccess?: (newUsers: User[]) => void;
}

interface FormData {
  name: string;
  email: string;
  role: string;
  companiesAccess: Company[];
  status: boolean;
}

const roles = [
  { value: "superAdmin", label: "Super admin" },
  { value: "admin", label: "Admin" },
  { value: "tech", label: "Tech" },
  { value: "viewer", label: "Viewer" },
];

const UserForm: React.FC<Props> = ({ open, onClose, user, onSubmitSuccess }) => {
  // Hooks
  const { companies } = useContext(DataContext);
  const [token] = useLocalStorage("token");
  const toast = useToast();
  const handleRequestErrors = useRequestErrorHandler();

  const getUserCompanies = (ids: string[]): Company[] =>
    companies.filter((comp) => ids.includes(comp._id));

  const initialValues: FormData = {
    name: user?.name || "",
    email: user?.email || "",
    role: user?.role || "viewer",
    companiesAccess: user && user.companies ? getUserCompanies(user.companies) : [],
    status: user?.status ? user.status !== "disabled" : true,
  };

  const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    role: yup.string().required("Role is required"),
    companiesAccess: yup.array(),
  });

  const getUserStatus = (status: boolean) =>
    !status ? "disabled" : user!.status !== "disabled" ? user!.status : "completed";

  // Create or update a new user
  const handleSubmit = async (formData: FormData, { setSubmitting }: FormikHelpers<FormData>) => {
    setSubmitting(true);

    const reqHeaders = { headers: { Authorization: `Bearer ${token}` } };
    const reqData = {
      name: formData.name,
      email: formData.email,
      role: formData.role,
      company_access: formData.companiesAccess.map((comp) => comp._id),
    };

    try {
      let newUsers = [];
      if (!user) {
        const { data } = await api.post("/users", reqData, reqHeaders);
        newUsers = data.users;
      } else {
        const { data } = await api.put(
          `/users/${user._id}`,
          { ...reqData, status: getUserStatus(formData.status), email: undefined },
          reqHeaders
        );
        newUsers = data.users;
      }

      toast({
        open: true,
        type: "success",
        message: `User successfully ${!user ? "created" : "edited"}`,
      });
      if (onSubmitSuccess) onSubmitSuccess(newUsers);
      onClose();
    } catch (err) {
      handleRequestErrors(err);
    } finally {
      setSubmitting(false);
    }
  };

  const handlePasswordReset = async () => {
    try {
      await api.get(`/users/reset_password/${user?._id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      toast({
        open: true,
        type: "success",
        message: `${
          user?.status === "completed" ? "Reset password email" : "Activation link"
        } successfully sent to the user`,
      });
    } catch (err) {
      handleRequestErrors(err);
    }
  };

  return (
    <>
      <DialogBasic open={open} onClose={onClose} title={`${!user ? "Add" : "Edit"} user`}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values }) => (
            <Form>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm>
                    <CustomTextField
                      name="name"
                      label="Name"
                      fullWidth
                      required
                      disabled={isSubmitting}
                    />
                  </Grid>

                  <Grid item xs={12} sm>
                    <CustomTextField
                      name="email"
                      type="email"
                      label="Email"
                      fullWidth
                      required
                      disabled={isSubmitting || !!user}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} className="mt-2">
                  <Grid item xs={12} sm>
                    <CustomRadio label="Choose a user role" row name="role">
                      {roles.map((role) => (
                        <FormControlLabel
                          key={role.value}
                          value={role.value}
                          control={<Radio color="primary" />}
                          label={role.label}
                        />
                      ))}
                    </CustomRadio>
                  </Grid>

                  {user && (
                    <Grid item xs={12} sm>
                      <CustomSwitch
                        name="status"
                        label="User status:"
                        switchLabel={values.status ? "Activated" : "Disabled"}
                        switchprops={{ color: "primary" }}
                      />
                    </Grid>
                  )}
                </Grid>

                <CustomAutocomplete
                  name="companiesAccess"
                  label="Companies access"
                  disableCloseOnSelect
                  multiple
                  limitTags={5}
                  options={companies}
                  getOptionLabel={(opt: Company) => opt.name}
                  disabled={isSubmitting}
                />
              </DialogContent>

              <DialogActions>
                <Button onClick={onClose}>Cancel</Button>

                {user && user.status !== "disabled" && (
                  <Button disabled={isSubmitting} onClick={handlePasswordReset}>
                    {user.status === "pending" ? "Resend activation link" : "Reset password"}
                  </Button>
                )}

                <Button type="submit" disabled={isSubmitting}>
                  {!user ? "Add" : "Edit"}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogBasic>
    </>
  );
};

export default UserForm;
