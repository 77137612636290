import { useEffect, useRef, useState } from "react";
import api from "../utils/api";
import useLocalStorage from "./useLocalStorage";
import useRequestErrorHandler from "./useRequestErrorHandler";

interface UseFetchOptions {
  handleError?: boolean;
  skip?: boolean;
}

interface UseFetchReturn<T = any> {
  data: T | null;
  loading: boolean;
  error: any;
  refetch: () => void;
}

type UseFetch = <T>(url: string, options?: UseFetchOptions) => UseFetchReturn<T>;

const useFetch: UseFetch = (url, { handleError = true, skip = false } = {}) => {
  // Hooks
  const handleErrorRequest = useRef(useRequestErrorHandler());
  const [token] = useLocalStorage("token");

  // States
  const [data, setData] = useState<UseFetchReturn["data"]>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(undefined);
  const [refetchIndex, setRefetchIndex] = useState(0);

  const refetch = () => setRefetchIndex((prev) => ++prev);

  useEffect(() => {
    const fetchData = async () => {
      if (skip) return;

      setLoading(true);

      try {
        const { data } = await api.get(url, { headers: { Authorization: `Bearer ${token}` } });
        setData(data);
      } catch (err) {
        if (handleError) {
          handleErrorRequest.current(err);
        }
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [handleError, token, url, refetchIndex, skip]);

  return { data, loading, error, refetch };
};

export default useFetch;
