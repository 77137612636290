import React, { useState } from "react";
import PropTypes from "prop-types";
// Material ui
import { Tooltip } from "@material-ui/core";
// Icons
import { Power, PowerOff } from "@material-ui/icons";

const DisplayOnOff = ({ className, connected }) => {
  // States
  const [open, setOpen] = useState(false);

  const convertTime = (time) => {
    const date = new Date(time);
    return date.toLocaleTimeString("pt-BR") + " - " + date.toLocaleDateString("pt-BR");
  };

  return connected ? (
    <div className={className} onClick={() => setOpen(true)}>
      <Tooltip
        title={
          <span style={{ fontSize: "12px" }}>
            {connected.connected ? "Connected" : "Disconnected"} since:{" "}
            {convertTime(connected.timestamp)}
          </span>
        }
        arrow
        placement="top"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
      >
        {connected.connected ? <Power className="text-success" /> : <PowerOff color="error" />}
      </Tooltip>
    </div>
  ) : null;
};

DisplayOnOff.propTypes = {
  className: PropTypes.string,
  connected: PropTypes.object,
};

export default DisplayOnOff;
