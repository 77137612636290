import useSWR from "swr";
import { fetcher } from "utils/fetcher";
import { QrCode } from "../@types/QrCode";
import { User } from "../@types/users";

type UseQrCode = (qrCodeId: string) => {
  qrCode: QrCode & { createdBy: User; updatedBy: User };
  isLoading: boolean;
  error: any;
  mutate: (data?: any) => void;
};

export const useQrCode: UseQrCode = (qrCodeId: string) => {
  const { data, error, mutate } = useSWR(`/qrcodes/${qrCodeId}`, fetcher);

  return { qrCode: data?.qrCode, isLoading: !data && !error, error, mutate };
};
