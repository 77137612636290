import React, { useState, useEffect } from "react";
//Components
import { ListItem, ListItemText, Collapse, ListItemIcon } from "@material-ui/core";
//Icons
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

interface Props {
  open?: boolean;
  onChange?: () => void;
  title?: string;
  defaultCollapse?: boolean | (() => boolean | undefined) | undefined;
  className?: string | undefined;
  icon?: React.ReactNode;
  children?: React.ReactNode;
}

const CustomCollapse: React.FC<Props> = ({
  open,
  onChange,
  title,
  defaultCollapse,
  className,
  icon,
  children,
}) => {
  const [openCollapse, setOpenCollapse] = useState<boolean | undefined>(defaultCollapse);

  useEffect(() => {
    if (open !== undefined) {
      setOpenCollapse(open);
    }
  }, [open]);

  const handleClick = () => {
    if (onChange !== undefined) {
      onChange();
    }

    setOpenCollapse(!openCollapse);
  };

  return (
    <>
      <ListItem button onClick={handleClick}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText primary={title} />
        {openCollapse ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={openCollapse} timeout="auto" unmountOnExit className={className}>
        {children}
      </Collapse>
    </>
  );
};

export default CustomCollapse;
