import React from "react";
import * as yup from "yup";
import { Form, Formik, FormikHelpers } from "formik";
// Material ui
import { Button, Dialog, InputAdornment, makeStyles, Typography } from "@material-ui/core";
// Icons
import { ReportProblemOutlined } from "@material-ui/icons";
// Components
import CustomTextField from "../Formik/CustomTextField";
// Utils
import { ShutdownCommands } from "../../@types/computer";
import { messages } from "../../utils/validationConstants";
import api from "../../utils/api";
import useLocalStorage from "../../hooks/useLocalStorage";
import useRequestErrorHandler from "../../hooks/useRequestErrorHandler";
import useToast from "../../hooks/useToast";

interface Props {
  open: boolean;
  onClose: () => void;
  command: ShutdownCommands;
  computerId: string;
}

interface FormData {
  reason: string;
  time: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: "rgb(27 27 27 / 0.9)",
    transition: "background 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  formRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  timeField: {
    maxWidth: 120,
    marginLeft: theme.spacing(2),
  },
}));

const MAX_TIME = 315360000;
const initialValues: FormData = {
  reason: "",
  time: 15,
};

const commandFormatted = {
  shutdown: "shut down",
  restart: "restart",
  logOff: "log off",
  lock: "lock",
};

const ShutdownConfirmation: React.FC<Props> = ({ open, onClose, command, computerId }) => {
  // Hooks
  const classes = useStyles();
  const [token] = useLocalStorage("token");
  const toast = useToast();
  const handleRequestErrors = useRequestErrorHandler();

  const validationSchema = yup.object({
    reason: yup.string().required(messages.requiredField),
    time: yup
      .number()
      .min(10, messages.min("Time", 10))
      .max(MAX_TIME, messages.max("Time", MAX_TIME))
      .required(messages.requiredField),
  });

  const handleSubmit = async (formData: FormData, { setSubmitting }: FormikHelpers<FormData>) => {
    try {
      setSubmitting(true);
      const { data } = await api.post(
        `/commands/windowsShutdown/${computerId}`,
        {
          method: command,
          message: formData.reason,
          time: formData.time,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      toast({
        open: true,
        type: "success",
        message: data.message || "Command successfully sent",
      });
      onClose();
    } catch (err) {
      handleRequestErrors(err);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        disableBackdropClick
        PaperProps={{ style: { background: "transparent", boxShadow: "none" } }}
        className={classes.root}
      >
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className={classes.formRoot}>
                <div className="d-flex w-100 mb-3">
                  <CustomTextField
                    name="reason"
                    label="Reason"
                    disabled={isSubmitting}
                    className="flex-grow-1"
                  />

                  {["shutdown", "restart"].includes(command) && (
                    <CustomTextField
                      name="time"
                      label="Time"
                      disabled={isSubmitting}
                      type="number"
                      inputProps={{ min: 15, max: MAX_TIME }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">seconds</InputAdornment>,
                      }}
                      className={classes.timeField}
                    />
                  )}
                </div>

                <ReportProblemOutlined style={{ fontSize: 70 }} />

                <Typography variant="h5" className="my-3" align="center">
                  Are you sure you want to <strong>{commandFormatted[command]}</strong> the
                  computer?
                </Typography>

                <div className="d-flex">
                  <Button type="submit" disabled={isSubmitting}>
                    Yes
                  </Button>

                  <Button onClick={onClose}>No</Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Dialog>
    </>
  );
};

export default ShutdownConfirmation;
