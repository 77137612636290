import { useState, useEffect } from "react";
import { Computer } from "../@types/computer";

const useIsComputerAvailable = (computer: Computer) => {
  const [isAvailable, setIsAvailable] = useState(true);

  useEffect(() => {
    let isAvailable = true;

    // Check if the device is connected
    if (computer.connected && !computer.connected.connected) isAvailable = false;

    // Check if the device is being updated
    if (computer.commands && computer.commands.updateConnector) isAvailable = false;

    setIsAvailable(isAvailable);
  }, [computer.commands, computer.connected]);

  return isAvailable;
};

export default useIsComputerAvailable;
