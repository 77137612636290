import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// Material ui
import { TextField, Button, IconButton, makeStyles } from "@material-ui/core";
// Icons
import { Close } from "@material-ui/icons";
// Utils
import api from "../../../utils/api";
import useLocalStorage from "../../../hooks/useLocalStorage";

interface Props {
  computerId: string;
  currentComment: string;
  updateComment: (newComment: string) => void;
  onBlur: () => void;
}

const useStyles = makeStyles((theme) => ({
  textContainer: { margin: "10px" },
  textInput: { fontSize: "14px" },
}));

const CommentInput: React.FC<Props> = ({ computerId, currentComment, updateComment, onBlur }) => {
  // Hooks
  const [token] = useLocalStorage("token");
  const classes = useStyles();
  const history = useHistory();

  // States
  const [comment, setComment] = useState(currentComment);

  const handleBlur = async () => {
    try {
      const newComment = { computerId, text: comment };
      const { data } = await api.post("/computerComment", newComment, {
        headers: { Authorization: `Bearer ${token}` },
      });

      updateComment(data.comment.text);
    } catch (err) {
      if (err.response && err.response.data) {
        const { data } = err.response;

        // Invalid token
        if (data.message && data.message.includes("token")) return history.push("/");
      }
    } finally {
      onBlur();
    }
  };

  return (
    <>
      <TextField
        placeholder="Click here to add comments"
        variant="outlined"
        fullWidth
        multiline
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        onBlur={handleBlur}
        rowsMax={10}
        InputProps={{ classes: { input: classes.textInput } }}
        helperText="OBS: We support markdown text"
      />

      <div className="d-flex my-2">
        <Button variant="outlined" onClick={handleBlur} size="small">
          Save
        </Button>

        <IconButton onClick={onBlur} size="small" className="ml-2">
          <Close />
        </IconButton>
      </div>
    </>
  );
};

export default CommentInput;
