import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

// Pages
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import FirstAccess from "./pages/FirstAccess";
import NotFound from "./pages/NotFound";
// Context
import { DataProvider } from "./context/DataContext";
import { ZendeskProvider } from "./context/ZendeskContext";

const Routes = () => (
  <BrowserRouter>
    <DataProvider>
      <Switch>
        <Route path="/" exact component={Login} />

        <Route path="/first_access/:id" component={FirstAccess} />

        <Route path="/dashboard">
          <ZendeskProvider>
            <Dashboard />
          </ZendeskProvider>
        </Route>

        <Route path="*" component={NotFound} />
      </Switch>
    </DataProvider>
  </BrowserRouter>
);

export default Routes;
