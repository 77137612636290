import React, { useMemo } from "react";
import { Form, Formik, FormikConfig } from "formik";
import * as yup from "yup";
// Material ui
import { Box, Button } from "@material-ui/core";
// Components
import CustomAutocomplete from "components/Formik/CustomAutocomplete";
// Hooks
import { useCompanies } from "hooks/useCompanies";
// Utils
import { IQrCodeActionFormsProps } from "./QrCodeActionFormsProps";
import { Company } from "../../../@types/company";
import { useDevicesNameAndType } from "hooks/useDevicesNameAndType";

type OpenDevicePageProps = IQrCodeActionFormsProps;

interface DeviceAutocompleteProps {
  companyId: string;
  isLoading: boolean;
}

const validationSchema = yup.object({
  company: yup.object({
    _id: yup.string().required(),
    name: yup.string().required(),
  }),
});

const initialCompanyValue = { _id: "0", name: "None" };
const initialDeviceValue = { id: "0", name: "None" };

const DeviceAutocomplete = React.memo<DeviceAutocompleteProps>(({ companyId, isLoading }) => {
  const { devices, isLoading: isLoadingCompanies } = useDevicesNameAndType(companyId);

  return (
    <Box mt={2}>
      <CustomAutocomplete
        name="device"
        label="Device"
        variant="outlined"
        size="small"
        options={isLoadingCompanies ? [] : [initialDeviceValue, ...devices]}
        getOptionLabel={(opt) => opt.name}
        getOptionSelected={(option, value) => option.id === value.id}
        loading={isLoadingCompanies}
        disabled={isLoading}
      />
    </Box>
  );
});

export const OpenDevicePageAction: React.FC<OpenDevicePageProps> = ({ isLoading, onSubmit }) => {
  // Hooks
  const { companies, isLoading: isLoadingCompanies } = useCompanies();

  const initialValues = useMemo(
    () => ({
      company: initialCompanyValue,
      device: initialDeviceValue,
    }),
    []
  );

  const handleSubmit: FormikConfig<typeof initialValues>["onSubmit"] = (
    formData,
    { setFieldError }
  ) => {
    if (formData.company._id === "0") return setFieldError("company", "Invalid company");
    if (formData.device.id === "0") return setFieldError("device", "Invalid device");

    onSubmit({ id: formData.device.id });
  };

  return (
    <Box mt={2}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <CustomAutocomplete
              name="company"
              label="Company"
              variant="outlined"
              size="small"
              options={isLoadingCompanies ? [] : [initialCompanyValue, ...companies]}
              getOptionLabel={(opt: Company) => opt.name}
              getOptionSelected={(option, value) => option._id === value._id}
              loading={isLoadingCompanies}
              disabled={isLoading}
              onChange={() => {
                setFieldValue("device", initialDeviceValue);
              }}
            />

            {values.company._id !== "0" && (
              <DeviceAutocomplete companyId={values.company._id} isLoading={isLoading} />
            )}

            <Box mt={2}>
              <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
                Submit
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
