import React, { useState, useEffect, useContext } from "react";
// Material ui
import {
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
} from "@material-ui/core";
// Components
import DialogBasic from "./DialogBasic";
import AddComputer from "../Devices/AddComputer";
import GenericDeviceForm from "../Devices/GenericDeviceForm";
import TabletFormType from "../Devices/TabletForm";
// Utils
import { DeviceTypes, BasicDeviceInfo } from "../../@types/computer";
import CompanyContext from "../../context/CompanyContext";
import api from "../../utils/api";
import useLocalStorage from "../../hooks/useLocalStorage";
import useCompanyPage from "../../hooks/useCompanyPage";
import { getDeviceCards } from "../../utils/deviceUtils";
import useToast from "../../hooks/useToast";
import useRequestErrorHandler from "../../hooks/useRequestErrorHandler";

interface Props {
  open: boolean;
  onClose: () => void;
  defaultDevice?: DeviceTypes;
  device?: BasicDeviceInfo;
}

export type HandleDeviceSubmit = (
  requestData: any,
  setSubmitting: React.Dispatch<React.SetStateAction<boolean>>
) => void;

const AddDevice: React.FC<Props> = ({ open, onClose, defaultDevice = "ap", device }) => {
  // Hooks
  const { selectedGroup, setDeviceCards } = useContext(CompanyContext);
  const [token] = useLocalStorage("token");
  const company = useCompanyPage();
  const toast = useToast();
  const handleRequestErrors = useRequestErrorHandler();

  // States
  const [isEditing, setIsEditing] = useState(false);
  const [selDeviceType, setSelDeviceType] = useState<DeviceTypes>(defaultDevice);
  const deviceTypes: Array<{ label: string; value: DeviceTypes }> = [
    { label: "AP", value: "ap" },
    { label: "Computer", value: "computer" },
    { label: "Switch", value: "switch" },
    { label: "Printer", value: "printer" },
    { label: "Tablet", value: "tablet" },
  ];

  useEffect(() => {
    if (open) {
      setIsEditing(device ? true : false);
      setSelDeviceType(device ? device.type : defaultDevice);
    }
  }, [open, defaultDevice, device]);

  // Handle device type change
  const handleSelectChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setSelDeviceType(e.target.value as DeviceTypes);
  };

  const handleDeviceSubmit: HandleDeviceSubmit = async (requestData, setSubmitting) => {
    try {
      setSubmitting(true);

      const requestConfigs = { headers: { Authorization: `Bearer ${token}` } };

      if (!isEditing) {
        // Save the device
        await api.post("/devices", requestData, requestConfigs);
      } else {
        // Edit the device
        await api.put(`/devices/${device?._id}`, requestData, requestConfigs);
      }

      // Update the devices data
      setDeviceCards(await getDeviceCards(selectedGroup?._id || "0", company._id, token));

      toast({
        open: true,
        type: "success",
        message: `Device successfully ${!isEditing ? "created" : "edited"}`,
      });
    } catch (err) {
      handleRequestErrors(err);
    } finally {
      setSubmitting(false);
      onClose();
    }
  };

  const displayDeviceForm = () => {
    switch (selDeviceType) {
      case "computer":
        return <AddComputer open={open} company={company} onClose={onClose} />;
      case "tablet":
        return <TabletFormType onSubmit={handleDeviceSubmit} onClose={onClose} device={device} />;
      default:
        return (
          <GenericDeviceForm
            deviceType={selDeviceType}
            onSubmit={handleDeviceSubmit}
            onClose={onClose}
            device={device}
          />
        );
    }
  };

  return (
    <>
      <DialogBasic
        open={open}
        onClose={onClose}
        title={!isEditing ? "Add a new device" : "Edit a device"}
        widthSize="md"
      >
        <DialogContent>
          {!isEditing && (
            <>
              <FormControl fullWidth>
                <InputLabel id="select-device-type-label">Select a device type</InputLabel>

                <Select
                  labelId="select-device-type-label"
                  id="select-device-type"
                  value={selDeviceType}
                  onChange={handleSelectChange}
                >
                  {deviceTypes
                    .sort((a, b) => (a.label > b.label ? 1 : -1))
                    .map((type) => (
                      <MenuItem key={type.value} value={type.value}>
                        {type.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <Divider className="mt-4 mb-3" />
            </>
          )}

          {displayDeviceForm()}
        </DialogContent>
      </DialogBasic>
    </>
  );
};

export default AddDevice;
