import React, { useState, useEffect, useRef } from "react";
import * as yup from "yup";
// Material ui
import { MenuItem, Button } from "@material-ui/core";
// Formik
import { Formik, Form } from "formik";
import CustomSelect from "../Formik/CustomSelect";
// Components
import BackupTable from "../Tables/BackupTable";
import Loading from "../Loading";
//Utils
import { Computer } from "../../@types/computer";
import { WinBackupData } from "../../@types/computerCommands";
import { newApi } from "../../utils/api";

interface Props {
  computer: Computer;
}

const availableDays = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const initialValues = { selectedDay: 3 };
const validationSchema = yup.object({
  selectedDay: yup.number().required().integer().min(1).max(10),
});

const WindowsBackup: React.FC<Props> = ({ computer }) => {
  // States
  const [loading, setLoading] = useState(true);
  const [backupData, setBackupData] = useState<WinBackupData[] | string>([]);

  const getBackupsData = useRef(async (day: number) => {
    try {
      setLoading(true);

      const { data } = await newApi().get(
        `/commands/get-windows-backups/${computer.id}?qtd=${day}`
      );

      setBackupData(data.backups);
    } catch (err) {
      setBackupData(err.response?.data?.message || err.message);
    } finally {
      setLoading(false);
    }
  });

  // // Run when the component loads
  useEffect(() => {
    getBackupsData.current(3);
  }, []);

  return (
    <div className="p-2">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(data) => getBackupsData.current(data.selectedDay)}
      >
        {() => (
          <Form className="d-flex mb-3">
            <CustomSelect
              name="selectedDay"
              id="win-backup-days"
              label="How many previous days"
              fullWidth
              disabled={loading || typeof backupData === "string"}
            >
              {availableDays.map((day) => (
                <MenuItem key={day} value={day}>
                  {day}
                </MenuItem>
              ))}
            </CustomSelect>

            <Button
              className="ml-2"
              variant="outlined"
              type="submit"
              disabled={loading || typeof backupData === "string"}
            >
              Check
            </Button>
          </Form>
        )}
      </Formik>

      {loading ? <Loading /> : <BackupTable backupData={backupData} />}
    </div>
  );
};

export default WindowsBackup;
