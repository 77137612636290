import React from "react";
// Material ui
import {
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  Typography,
  TableContainer,
} from "@material-ui/core";
import { useState, useEffect } from "react";
// Components
import WinLogsTableRows from "./WinLogsTableRows";

export interface Event {
  EventID: string;
  MachineName: string;
  Data: string;
  Category: string;
  EntryType: string;
  Source: string;
  Message: string;
  TimeWritten: string;
}
interface Props {
  events: { success: boolean; data: Event[] };
}

const WinLogsTable: React.FC<Props> = ({ events }) => {
  // State
  const [page, setPage] = useState(0);
  const [listEvents, setListEvents] = useState(events.data);

  const rowsPerPage = 5;
  const tableHead = ["Level", "Source", "EventID", "Date and time"];

  useEffect(() => setListEvents(events.data), [events.data]);

  const handleChangePage = (e: any, newPage: React.SetStateAction<number>) => {
    setPage(newPage);
  };

  if (!events.success) {
    return (
      <div className="text-center mt-5 mb-5">
        <Typography variant="h5" color="error">
          {events.data}
        </Typography>
      </div>
    );
  }

  if (events.data.length <= 0) {
    return (
      <div className="m-4">
        <Typography variant="h5" className="text-center">
          Sorry, no logs founds
        </Typography>
        <Typography variant="subtitle1" className="text-center">
          Try a different query configuration
        </Typography>
      </div>
    );
  }

  return (
    <TableContainer className="MuiPaper-outlined MuiPaper-rounded">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            {tableHead.map((head) => (
              <TableCell key={head}>
                <strong>{head}</strong>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {listEvents
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((event, index) => (
              <WinLogsTableRows key={index} event={event} />
            ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5]}
              count={listEvents.length}
              page={page}
              onChangePage={handleChangePage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default WinLogsTable;
