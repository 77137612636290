import React, { useEffect, useContext, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
// Material ui
import { makeStyles, useMediaQuery } from "@material-ui/core";
// Components
import Drawer from "../components/UserGroups/Drawer";
import Content from "../components/UserGroups/Content";
// Utils
import api from "../utils/api";
import useLocalStorage from "../hooks/useLocalStorage";
import { UserGroup } from "../@types/users";
import UserGroupContext from "../context/UserGroupsContext";
import useRequestErrorHandler from "../hooks/useRequestErrorHandler";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
  },
}));

const getAllGroups = async (token: string) => {
  const { data } = await api.get("/users/groups?disabled=true", {
    headers: { Authorization: `Bearer ${token}` },
  });

  return data.groups as UserGroup[];
};

const UserGroups = () => {
  // Hooks
  const classes = useStyles();
  const mediaQueryMatches = useMediaQuery("(max-width: 500px)");
  const [token] = useLocalStorage("token");
  const ctx = useRef(useContext(UserGroupContext));
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const handleRequestErrors = useRef(useRequestErrorHandler());

  useEffect(() => {
    let isCurrent = true;

    (async () => {
      try {
        const groups = await getAllGroups(token);

        if (isCurrent) {
          ctx.current.dispatch({ type: "SET_GROUPS", payload: groups });
        }
      } catch (err) {
        handleRequestErrors.current(err);
      }
    })();

    return () => {
      isCurrent = false;
    };
  }, [history, token]);

  return (
    <div className={classes.root}>
      {!mediaQueryMatches ? <Drawer /> : !id ? <Drawer fullWidth /> : null}

      {!mediaQueryMatches ? <Content /> : id ? <Content /> : null}
    </div>
  );
};

export default UserGroups;
