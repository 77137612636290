import React, { useContext, useEffect, useRef, useState } from "react";
// Material ui
import { Divider } from "@material-ui/core";
// Icons
import { Business, SettingsRemote, Devices, GetApp } from "@material-ui/icons";
// Components
import DiskSpaceTable from "../components/Tables/DiskSpaceTable";
import InfoCard from "../components/InfoCard";
import UpdateDevices from "../components/Modals/UpdateDevices";
// Utils
import Can, { check } from "../components/Can";
import DataContext from "../context/DataContext";
import useLocalStorage from "../hooks/useLocalStorage";
import useRequestErrorHandler from "../hooks/useRequestErrorHandler";
import api from "../utils/api";
import { User } from "../@types/users";

interface DashboardTotals {
  totalCompanies: number;
  totalComputers: number;
  totalOutdatedConnectors: number;
  totalDevices: number;
  totalDevicesAndComputers: number;
}

const getDashboardTotals = async (userId: string, token: string) => {
  const { data } = await api.get("/dashboard/totals", {
    headers: { Authorization: `Bearer ${token}` },
  });

  return data as DashboardTotals;
};

const getUpdateSubtitle = (outdatedConnectors: number) =>
  outdatedConnectors > 0
    ? `${outdatedConnectors} connector(s) need to be updated`
    : "All connectors are up to date";

const getDevicesSubtitle = (user: User, totalDevices: number) =>
  user.userGroup
    ? `${totalDevices} added / ${user.userGroup.maxDevices} max`
    : `Total of ${totalDevices} devices`;

const canOpenUpdateModal = (role: string, outdatedConnectors: number) =>
  check(role, "devices:update") && outdatedConnectors > 0;

const Main: React.FC = () => {
  // Hooks
  const { user } = useContext(DataContext);
  const [token] = useLocalStorage("token");
  const handleRequestErrors = useRef(useRequestErrorHandler());
  const isCurrent = useRef(true);

  // States
  const [updateModal, setUpdateModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dashboardTotals, setDashboardTotals] = useState<DashboardTotals>({
    totalComputers: 0,
    totalCompanies: 0,
    totalOutdatedConnectors: 0,
    totalDevices: 0,
    totalDevicesAndComputers: 0,
  });

  useEffect(
    () => () => {
      isCurrent.current = false;
    },
    []
  );

  useEffect(() => {
    if (!isCurrent.current) return;
    setLoading(true);

    (async () => {
      try {
        const dashboardData = await getDashboardTotals(user._id, token);

        setDashboardTotals(dashboardData);
      } catch (err) {
        handleRequestErrors.current(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [token, user._id]);

  const openUpdateModal = () => setUpdateModal(true);

  return (
    <div className="container mt-4 mb-3">
      <div className="d-flex flex-row flex-wrap justify-content-center text-center">
        <InfoCard
          icon={Business}
          title="Companies"
          subtitle={`Total of ${dashboardTotals.totalCompanies} companies`}
          type="info"
        />

        <InfoCard
          icon={SettingsRemote}
          title="Connectors"
          subtitle={`Total of ${dashboardTotals.totalComputers} connectors`}
          type="info"
          loading={loading}
        />

        <InfoCard
          icon={Devices}
          title="Devices"
          subtitle={getDevicesSubtitle(user, dashboardTotals.totalDevicesAndComputers)}
          type="info"
          loading={loading}
        />

        <InfoCard
          icon={GetApp}
          subtitle={getUpdateSubtitle(dashboardTotals.totalOutdatedConnectors)}
          type={dashboardTotals.totalOutdatedConnectors > 0 ? "warning" : "info"}
          loading={loading}
          tooltip={
            canOpenUpdateModal(user.role, dashboardTotals.totalOutdatedConnectors)
              ? "Click here to update the connectors"
              : ""
          }
          onClick={
            canOpenUpdateModal(user.role, dashboardTotals.totalOutdatedConnectors)
              ? openUpdateModal
              : undefined
          }
        />
      </div>

      <Divider className="mt-3 mb-3" />

      <DiskSpaceTable />

      <Can role={user.role} perform="devices:update">
        <UpdateDevices open={updateModal} onClose={() => setUpdateModal(false)} />
      </Can>
    </div>
  );
};

export default Main;
