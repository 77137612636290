import React, { useState, useEffect } from "react";
import * as yup from "yup";
import moment from "moment";
// Material ui
import {
  Typography,
  Grid,
  DialogActions,
  Button,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
// Formik
import { Formik, Form } from "formik";
// Components
import CustomTextField from "../Formik/CustomTextField";
// Utils
import { DeviceTypes, BasicDeviceInfo } from "../../@types/computer";
import useCompanyPage from "../../hooks/useCompanyPage";
import { BasicDeviceForm } from "./FormTypes";
import { HandleDeviceSubmit } from "../Modals/AddDevice";

interface Props {
  deviceType: Exclude<DeviceTypes, "Computer/Server">;
  onSubmit: HandleDeviceSubmit;
  onClose?: () => void;
  device?: BasicDeviceInfo;
}

const useStyles = makeStyles((theme) => ({
  textField: { marginBottom: theme.spacing(1.5) },
}));

const GenericDeviceForm: React.FC<Props> = ({ deviceType, onSubmit, onClose, device }) => {
  // Hooks
  const classes = useStyles();
  const company = useCompanyPage();

  // States
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const initialState: BasicDeviceForm = {
    name: device?.name || "",
    model: device?.model || "",
    brand: device?.brand || "",
    serialNumber: device?.serialNumber || "",
    warrantyDate: device?.warrantyDate ? moment(device.warrantyDate) : null,
    comments: device?.comments || "",
  };

  const validationSchema = yup.object({
    name: yup.string().required("Device name is required"),
    model: yup.string().required("Model is required"),
    brand: yup.string().required("Brand is required"),
    warrantyDate: yup.date().nullable(),
    serialNumber: yup.string(),
    comment: yup.string(),
  });

  useEffect(() => setIsEditing(device ? true : false), [device]);

  const handleSubmit = (data: BasicDeviceForm) => {
    const requestData = {
      deviceType,
      device: {
        ...data,
        companyId: company._id,
        warrantyDate: data.warrantyDate?.toDate() || null,
      },
    };

    onSubmit(requestData, setIsSubmitting);
  };

  return (
    <>
      <Typography variant="h6" className="text-center">
        {!isEditing ? `Add a new ${deviceType}` : `Edit ${device?.name}`}
      </Typography>

      <Formik
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <CustomTextField
              label="Device name"
              name="name"
              fullWidth
              required
              className={classes.textField}
              disabled={isSubmitting}
            />

            <Grid container spacing={2} className={classes.textField}>
              <Grid item xs={12} sm>
                <CustomTextField
                  label="Model"
                  name="model"
                  fullWidth
                  required
                  disabled={isSubmitting}
                />
              </Grid>

              <Grid item xs={12} sm>
                <CustomTextField
                  label="Brand"
                  name="brand"
                  fullWidth
                  required
                  disabled={isSubmitting}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} className={classes.textField}>
              <Grid item xs={12} sm>
                <CustomTextField
                  label="Serial number (optional)"
                  name="serialNumber"
                  fullWidth
                  disabled={isSubmitting}
                />
              </Grid>

              <Grid item xs={12} sm>
                <KeyboardDatePicker
                  label="Warranty date (optional)"
                  name="warrantyDate"
                  variant="inline"
                  format="DD/MM/yyyy"
                  autoOk
                  value={values.warrantyDate}
                  onChange={(value) => setFieldValue("warrantyDate", value)}
                  fullWidth
                  disabled={isSubmitting}
                />
              </Grid>
            </Grid>

            <CustomTextField
              label="Comments (optional)"
              name="comments"
              multiline={true}
              fullWidth
              className={classes.textField}
              disabled={isSubmitting}
              helperText="OBS: we support markdown text"
            />

            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>

              <Button type="submit" disabled={isSubmitting}>
                {!isEditing ? "Add" : "Edit"}
              </Button>

              {isSubmitting && <CircularProgress />}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default GenericDeviceForm;
