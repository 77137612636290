import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  TableFooter,
  TablePagination,
  Tooltip,
} from "@material-ui/core";

const convertTime = (time) => {
  const date = new Date(time);
  return (
    <div className="text-center">
      <div>{date.toLocaleTimeString("pt-BR")}</div>
      <div>{date.toLocaleDateString("pt-BR")}</div>
    </div>
  );
};

const NoValues = () => (
  <Paper className="p-3">
    <Typography variant="h4" className="text-center">
      Sorry, we couldn't find any activities
    </Typography>
    <Typography variant="subtitle1" className="text-center">
      Try different query values and search again
    </Typography>
  </Paper>
);

const DisplayUser = ({ user }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const getFirstName = (name) => name.split(" ")[0];

  return (
    <Tooltip
      title={
        <div style={{ fontSize: "13px", textAlign: "center" }}>
          <div>{user.name}</div>
          <div>{user.email}</div>
        </div>
      }
      arrow
      placement="top"
      open={tooltipOpen}
      onOpen={() => setTooltipOpen(true)}
      onClose={() => setTooltipOpen(false)}
    >
      <span onClick={() => setTooltipOpen(true)}>{getFirstName(user.name)}</span>
    </Tooltip>
  );
};

const ActivitiesTable = ({ tableHead, data }) => {
  // States
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [listActs, setListActs] = useState(data);

  useEffect(() => setListActs(data), [data]);

  // Change page
  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  // Change rows per page
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  if (!data || data.length <= 0) {
    return <NoValues />;
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow style={{ textDecoration: "bold" }}>
            {tableHead.map((head, index) => (
              <TableCell key={index}>
                <strong>{head}</strong>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {listActs
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  <DisplayUser user={row.user} />
                </TableCell>
                <TableCell>{row.resource}</TableCell>
                <TableCell>{row.action}</TableCell>
                <TableCell>
                  {row.company ? (
                    <Link to={`/dashboard/company/${row.company._id}`}>{row.company.name}</Link>
                  ) : (
                    "System"
                  )}
                </TableCell>
                <TableCell>{convertTime(row.timestamp)}</TableCell>
              </TableRow>
            ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              count={listActs.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

ActivitiesTable.propTypes = {
  tableHead: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
};

ActivitiesTable.defaultProps = {
  tableHead: [],
  data: [],
};

export default ActivitiesTable;
