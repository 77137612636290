import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  Typography,
  makeStyles,
  Toolbar,
  IconButton,
  Tooltip,
  createStyles,
  FormControlLabel,
  Switch,
  TablePagination,
} from "@material-ui/core";
import { Refresh, Send } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DisplayOnOff from "../DisplayOnOff";
import Loading from "../Loading";
import { OutdatedComputer } from "../Modals/UpdateDevices";

interface UpdateDevicesTableProps {
  data: OutdatedComputer[];
  loading?: boolean;
  onRefetch: () => void;
  onSendCommand: (selectedIds: string[]) => void;
}

interface TableToolbarProps {
  numSelected: number;
  showBlocked: boolean;
  onClick: () => void;
  onChangeSelect: () => void;
  onRefetch: () => void;
}

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    maxHeight: 440,
  },
  selectableRow: {
    cursor: "pointer",
  },
  rowBlocked: {
    cursor: "not-allowed",
  },
}));

const columns = ["Device name", "Company", "Connector version", "Connected"];

const getComputerName = (computer: OutdatedComputer) => computer.name || computer.computerName;

const getConnectorVersion = (computer: OutdatedComputer) => computer.connectorVersion || "1.0.0";

const sortByVersion = (a: OutdatedComputer, b: OutdatedComputer) =>
  getConnectorVersion(a) < getConnectorVersion(b) ? -1 : 1;

type IsComputerBlocked = (computer: OutdatedComputer, blockCommand?: boolean) => boolean;
const isComputerBlocked: IsComputerBlocked = (
  { connectorVersion, commands, connected },
  blocksCommand = true
) =>
  Boolean(
    !connectorVersion ||
      connectorVersion === "1.0.0" ||
      (blocksCommand && commands) ||
      (connected && !connected.connected)
  );

const displayCommandOrVersion = (computer: OutdatedComputer) =>
  computer.commands
    ? computer.commands.updateConnector?.status || "Update command sent"
    : getConnectorVersion(computer);

// Get how many devices are upgradable
const getUpgradableComputersLength = (computers: OutdatedComputer[]) =>
  computers.filter((computer) => !isComputerBlocked(computer)).length;

const useToolbarStyles = makeStyles((theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight: {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.primary.dark,
    },
    title: {
      flex: "1 1 100%",
    },
  })
);

const TableToolbar: React.FC<TableToolbarProps> = ({
  numSelected,
  showBlocked,
  onClick,
  onChangeSelect,
  onRefetch,
}) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar className={`${classes.root} ${numSelected > 0 ? classes.highlight : ""}`}>
      {numSelected > 0 ? (
        <>
          <Typography variant="subtitle1" color="inherit" component="div" className={classes.title}>
            {numSelected} selected
          </Typography>
          <Tooltip title="Send command">
            <IconButton onClick={onClick}>
              <Send />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <div className="d-flex w-100 pt-2 justify-content-between align-content-center">
          <div>
            <Typography variant="subtitle1" color="inherit">
              Update connectors
            </Typography>

            <FormControlLabel
              control={<Switch checked={showBlocked} onChange={onChangeSelect} size="small" />}
              label={
                <small>Show offline computers or that don't support the "Update" command</small>
              }
            />
          </div>
          <div>
            <Tooltip title="Refresh">
              <IconButton onClick={onRefetch}>
                <Refresh />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}
    </Toolbar>
  );
};

const UpdateDevicesTable: React.FC<UpdateDevicesTableProps> = ({
  data,
  loading,
  onSendCommand,
  onRefetch,
}) => {
  // Hooks
  const classes = useStyles();

  // States
  const [computers, setComputers] = useState<OutdatedComputer[]>([]);
  const [selComputers, setSelComputers] = useState<string[]>([]);
  const [showBlocked, setShowBlocked] = useState(false);
  const [page, setPage] = useState(0);

  useEffect(() => {
    setComputers(
      showBlocked ? data : data.filter((computer) => !isComputerBlocked(computer, false))
    );
  }, [data, showBlocked]);

  if (loading) return <Loading />;

  // Add or remove one device to the selected array
  const selOneComputer = (computer: OutdatedComputer) => {
    if (isComputerBlocked(computer)) return;

    const selectedIndex = selComputers.indexOf(computer.id);
    let newSelDevices: string[] = [];

    if (selectedIndex === -1) {
      newSelDevices = newSelDevices.concat(selComputers, computer.id);
    } else {
      newSelDevices = newSelDevices.concat(
        selComputers.slice(0, selectedIndex),
        selComputers.slice(selectedIndex + 1)
      );
    }

    setSelComputers(newSelDevices);
  };

  // Select or unselect all devices
  const selAllDevices = (e: React.ChangeEvent<HTMLInputElement>) =>
    e.target.checked
      ? setSelComputers(
          computers.reduce((acc, curr) => (!isComputerBlocked(curr) ? [...acc, curr.id] : acc), [])
        )
      : setSelComputers([]);

  const handleSendCommand = () => {
    onSendCommand(selComputers);
    setSelComputers([]);
  };

  return (
    <Paper>
      <TableToolbar
        numSelected={selComputers.length}
        showBlocked={showBlocked}
        onRefetch={onRefetch}
        onClick={handleSendCommand}
        onChangeSelect={() => setShowBlocked((prev) => !prev)}
      />

      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={
                    selComputers.length > 0 &&
                    selComputers.length < getUpgradableComputersLength(computers)
                  }
                  checked={
                    selComputers.length > 0 &&
                    selComputers.length === getUpgradableComputersLength(computers)
                  }
                  onChange={(e) => selAllDevices(e)}
                  inputProps={{ "aria-label": "select all devices" }}
                />
              </TableCell>

              {columns.map((column, index) => (
                <TableCell key={index} align="left">
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {computers
              .sort(sortByVersion)
              .slice(page * 10, page * 10 + 10)
              .map((computer, index) => (
                <TableRow
                  key={index}
                  hover
                  className={`${
                    isComputerBlocked(computer) ? classes.rowBlocked : classes.selectableRow
                  }`}
                  role="checkbox"
                  tabIndex={-1}
                  onClick={() => selOneComputer(computer)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selComputers.includes(computer.id)}
                      disabled={isComputerBlocked(computer)}
                    />
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {getComputerName(computer)}
                  </TableCell>

                  <TableCell>
                    <Link to={`/dashboard/company/${computer.company._id}`}>
                      {computer.company.name}
                    </Link>
                  </TableCell>

                  <TableCell>{displayCommandOrVersion(computer)}</TableCell>

                  <TableCell>
                    {computer.connected ? (
                      <DisplayOnOff connected={computer.connected} />
                    ) : (
                      "Not supported"
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        rowsPerPageOptions={[10]}
        count={computers.length}
        rowsPerPage={10}
        page={page}
        onChangePage={(e, newPage) => setPage(newPage)}
      />
    </Paper>
  );
};

export default UpdateDevicesTable;
