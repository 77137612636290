import React, { PropsWithChildren } from "react";
import { CellProps } from "react-table";
// Material ui
import { Typography } from "@material-ui/core";
// Constants
import { qrCodeStatuses } from "constants/qrCode";
import { colors } from "constants/Colors";
import { QrCode } from "../../../@types/QrCode";

export const StatusCell = ({ cell }: PropsWithChildren<CellProps<QrCode, QrCode["status"]>>) => {
  const status = cell.value;
  const statusDisplayName = qrCodeStatuses(status).displayName;

  return (
    <Typography variant="inherit" style={{ color: colors.table[status] }}>
      {statusDisplayName}
    </Typography>
  );
};
