import React, { useState } from "react";
// Material ui
import { makeStyles, Slide, List, ListItem, ListItemText } from "@material-ui/core";

export interface Item {
  id: string;
  name: string;
  icon?: React.ReactNode;
  isSelectable?: boolean;
}

interface Props {
  open: boolean;
  data: Item[];
  onSelect: (index: number, item: Item) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 170,
    maxWidth: 400,
    background: theme.palette.background.paper,
  },
}));

const MakeShiftDrawer: React.FC<Props> = ({ open, data, onSelect }) => {
  // Hooks
  const classes = useStyles();

  // States
  const [selectedIndex, setSelectedIndex] = useState(0);

  // Handle selection on an item
  const handleListItemClick = (index: number) => {
    const selectedData = data[index];

    if (selectedData.isSelectable === undefined || selectedData.isSelectable === true)
      setSelectedIndex(index);

    onSelect(index, data[index]);
  };

  return (
    <Slide direction="right" in={open} mountOnEnter unmountOnExit>
      <div className={classes.root}>
        <List component="nav">
          {data.map(({ isSelectable = true, ...item }, index) => (
            <ListItem
              key={item.id}
              button
              selected={isSelectable && selectedIndex === index}
              onClick={(e) => handleListItemClick(index)}
            >
              <span className="mr-2">{item.icon}</span>

              <ListItemText primary={item.name} />
            </ListItem>
          ))}
        </List>
      </div>
    </Slide>
  );
};

export default MakeShiftDrawer;
