import { useState } from "react";

type UseBoolean = (
  initialValue?: boolean
) => [boolean, { on: () => void; off: () => void; toggle: () => void }];

export const useBoolean: UseBoolean = (initialValue) => {
  const [flag, setFlag] = useState<boolean>(!!initialValue);

  const updateFlag = {
    on: () => setFlag(true),
    off: () => setFlag(false),
    toggle: () => setFlag((value) => !value),
  };

  return [flag, updateFlag];
};
