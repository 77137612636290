import React from "react";
// Material ui
import { makeStyles, Typography, SvgIconProps } from "@material-ui/core";
import { TreeItem, TreeItemProps } from "@material-ui/lab";

type Props = TreeItemProps & {
  labelIcon: React.ElementType<SvgIconProps>;
  labelText: string;
  labelInfo?: string;
};

const useStyles = makeStyles((theme) => ({
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 2, 0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
  },
}));

const CustomTreeItem: React.FC<Props> = ({
  labelIcon: LabelIcon,
  labelText,
  labelInfo,
  ...others
}) => {
  // Hooks
  const classes = useStyles();

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon className={classes.labelIcon} />

          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>

          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      {...others}
    />
  );
};

export default CustomTreeItem;
