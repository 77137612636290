import React, { useState } from "react";
import {
  Grid,
  Typography,
  Paper,
  makeStyles,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@material-ui/core";
import Loading from "./Loading";

type SetList = React.Dispatch<React.SetStateAction<Item[]>>;

export interface Item {
  id: string;
  label: string;
  secondaryLabel?: string;
}

interface Props {
  leftLabel?: string;
  rightLabel?: string;
  leftList: Item[];
  setLeftList: SetList;
  rightList: Item[];
  setRightList: SetList;
  disabled?: boolean;
  loading?: boolean;
}

interface CustomListProps {
  items: Item[];
  label: string;
  onSelectItem: (item: Item) => void;
  disabled?: boolean;
  loading?: boolean;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    background: theme.palette.background.default,
    width: 300,
    height: 300,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

const CustomList: React.FC<CustomListProps> = ({
  items,
  label,
  onSelectItem,
  loading,
  disabled,
}) => {
  // Hooks
  const classes = useStyles();

  // States
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <Paper className={classes.paper}>
      <Typography variant="subtitle1" className="text-center">
        {label}
      </Typography>

      <TextField
        placeholder="Search"
        style={{ padding: "8px" }}
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        disabled={disabled}
      />

      {loading ? (
        <Loading />
      ) : items.length > 0 ? (
        <List dense component="div" role="list">
          {items
            .filter((item) => item.label.toLowerCase().includes(searchTerm.trim().toLowerCase()))
            .sort((a, b) => (a.label > b.label ? 1 : -1))
            .map((item) => (
              <ListItem
                key={item.id}
                role="listitem"
                button
                onClick={() => onSelectItem(item)}
                disabled={disabled}
              >
                <ListItemText primary={item.label} secondary={item.secondaryLabel} />
              </ListItem>
            ))}
        </List>
      ) : (
        <Typography variant="subtitle1" className="text-center m-5">
          No items
        </Typography>
      )}
    </Paper>
  );
};

const moveItemBetweenLists = (from: SetList, to: SetList, item: Item) => {
  from((prevItems) => [...prevItems, item]);
  to((prevItems) => prevItems.filter(({ id }) => id !== item.id));
};

const TransferList: React.FC<Props> = ({
  leftLabel = "Unselected",
  rightLabel = "Selected",
  leftList,
  setLeftList,
  rightList,
  setRightList,
  disabled = false,
  loading = false,
}) => (
  <Grid container spacing={2} justify="center" alignItems="center">
    <Grid item>
      <CustomList
        items={leftList}
        label={leftLabel}
        loading={loading}
        disabled={disabled}
        onSelectItem={(item) => moveItemBetweenLists(setRightList, setLeftList, item)}
      />
    </Grid>

    <Grid item>
      <CustomList
        items={rightList}
        label={rightLabel}
        loading={loading}
        disabled={disabled}
        onSelectItem={(item) => moveItemBetweenLists(setLeftList, setRightList, item)}
      />
    </Grid>
  </Grid>
);

export default TransferList;
