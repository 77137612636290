import React from "react";
// Material ui
import { CheckboxProps, FormControlLabel, Checkbox } from "@material-ui/core";
// Formik
import { FieldAttributes, useField } from "formik";

type Props = CheckboxProps & FieldAttributes<{}> & { label: string; labelClassName?: string };

const CustomCheckbox: React.FC<Props> = ({ label, disabled, labelClassName, ...props }) => {
  const [field] = useField<{}>(props);

  return (
    <FormControlLabel
      control={<Checkbox {...props} {...field} checked={field.value as boolean} />}
      label={label}
      disabled={disabled}
      className={labelClassName}
    />
  );
};

export default CustomCheckbox;
