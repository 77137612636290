import React, { useContext, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
// Material ui
import { Divider } from "@material-ui/core";
// Components
import CompanyHeader from "../components/CompanyHeader";
import DisplayGroupsAndDevice from "../components/DisplayGroupsAndDevice";
// Hooks
import useCompanyPage from "../hooks/useCompanyPage";
import useLocalStorage from "../hooks/useLocalStorage";
import useRequestErrorHandler from "../hooks/useRequestErrorHandler";
import useSocket from "../hooks/useSocket";
// Utils
import api from "../utils/api";
import CompanyContext from "../context/CompanyContext";
import { Computer } from "../@types/computer";

const getTotalDevices = async (companyId: string, token: string) => {
  const { data } = await api.get(`/devices/totalDevices/${companyId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return data as { totalDevices: number; totalPendingCodes: number };
};

const Company: React.FC = () => {
  // Hooks
  const company = useCompanyPage();
  const [token] = useLocalStorage("token");
  const companyCtx = useRef(useContext(CompanyContext));
  const isCurrent = useRef(true);
  const handleRequestErrors = useRef(useRequestErrorHandler());
  const socket = useSocket();

  useEffect(
    () => () => {
      isCurrent.current = false;
    },
    []
  );

  useEffect(() => {
    (async () => {
      if (!isCurrent.current) return;

      if (!company) return;

      const { setLoadingTotalDevices, setTotalDevices, setTotalPendingCodes } = companyCtx.current;
      setLoadingTotalDevices(true);

      try {
        const { totalDevices, totalPendingCodes } = await getTotalDevices(company._id, token);
        setTotalDevices(totalDevices);
        setTotalPendingCodes(totalPendingCodes);
      } catch (err) {
        handleRequestErrors.current(err);
      } finally {
        setLoadingTotalDevices(false);
      }
    })();
  }, [company, token]);

  useEffect(() => {
    if (!company) return;

    socket.current?.on("update-computers-data", (computers: Computer[]) => {
      if (!isCurrent.current) return;
      companyCtx.current.setComputers(computers);
    });
  }, [company, socket]);

  if (!company) return <Redirect to="/dashboard" />;

  return (
    <div className="m-3">
      <CompanyHeader />

      <Divider className="mt-3 mb-4" />

      <DisplayGroupsAndDevice />
    </div>
  );
};

export default Company;
