import React, { useState, useEffect, useCallback, useContext, useRef } from "react";
// Material ui
import { Tooltip, TextField, makeStyles, Typography, Button } from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton, Pagination } from "@material-ui/lab";
// Icons
import { SelectAll, Search, Add } from "@material-ui/icons";
// Components
import DeviceItem from "./DeviceCard";
import Loading from "./Loading";
import AddDevice from "./Modals/AddDevice";
// Utils
import { DeviceTypes, DeviceGroup } from "../@types/computer";
import {
  typeIcons,
  getDeviceTypeNames,
  getDeviceCards,
  computersToCard,
} from "../utils/deviceUtils";
import useComputers from "../hooks/useComputers";
import useCompanyPage from "../hooks/useCompanyPage";
import useLocalStorage from "../hooks/useLocalStorage";
import CompanyContext from "../context/CompanyContext";
import useRequestErrorHandler from "../hooks/useRequestErrorHandler";

interface Props {
  group: DeviceGroup;
}

export interface DeviceItem {
  id: string;
  name: string;
  type: DeviceTypes;
  information: Array<{ name: string; value: string }>;
  connected?: { connected: boolean; timestamp: string };
  warning?: string;
}

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    flex: "1 1 auto",
    display: "flex",
    minWidth: "330px",
    margin: "0 16px 0 8px",
  },
  searchInput: {
    flex: "1 1 auto",
  },
  sortGroup: {
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      margin: "8px auto 0 auto",
    },
  },
  deviceCards: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  paginationContainer: {
    justifyContent: "center",
  },
}));

// const DisplayDevices: React.FC<Props> = ({ devices }) => {
const DisplayDevices: React.FC<Props> = ({ group }) => {
  // Hooks
  const classes = useStyles();
  const computers = useComputers();
  const company = useCompanyPage();
  const [token] = useLocalStorage("token");
  const { deviceCards } = useContext(CompanyContext);
  const companyCtx = useRef(useContext(CompanyContext));
  const handleRequestErrors = useRef(useRequestErrorHandler());

  // States
  const [types, setTypes] = useState(["all"]);
  const [listDevices, setListDevices] = useState<DeviceItem[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [addDeviceModal, setAddDeviceModal] = useState(false);
  const [page, setPage] = useState(1);

  const devicesPerPage = 10;

  // Functions to filter all of the devices
  const filterDevices = useCallback(
    (device: DeviceItem) => {
      const searchTerm = searchValue.toLowerCase().trim();

      return (
        (types.includes(device.type) || types[0] === "all") &&
        device.name &&
        device.name.toLowerCase().includes(searchTerm)
      );
    },
    [searchValue, types]
  );

  useEffect(() => {
    const fetchDevices = async () => {
      setLoading(true);

      try {
        const groupDetails = await getDeviceCards(group._id, company._id, token);
        companyCtx.current.setDeviceCards(groupDetails);
      } catch (err) {
        handleRequestErrors.current(err);
      } finally {
        setLoading(false);
      }
    };

    fetchDevices();
  }, [company._id, group._id, token]);

  // Update the cards if a computer value is updated
  useEffect(() => {
    const newComputers = computersToCard(computers);
    const newCards = deviceCards.filter((device) => device.type !== "computer");
    companyCtx.current.setDeviceCards([...newCards, ...newComputers]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [computers]);

  // Filter devices
  useEffect(() => {
    const searchTerm = searchValue.toLowerCase().trim();
    setListDevices(deviceCards.filter((val) => val.name?.toLowerCase().includes(searchTerm)));
  }, [deviceCards, computers, searchValue]);

  // Change buttons to filter by device type
  const handleChangeType = (e: React.MouseEvent<HTMLElement>, newType: string[]) => {
    // Make sure that it has at least 1 value
    if (newType.length) {
      if (newType[newType.length - 1] === "all") {
        // Filter all device types
        return setTypes(["all"]);
      } else if (newType[0] === "all") {
        // Remove the "all" filter
        newType.shift();
      }

      setTypes(newType);
    }
  };

  return (
    <div>
      <div className="d-flex flex-row flex-wrap">
        <div className={classes.searchContainer}>
          <Search className="mt-auto mr-2" />

          <TextField
            label="Search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            className={classes.searchInput}
          />
        </div>

        <ToggleButtonGroup value={types} onChange={handleChangeType} className={classes.sortGroup}>
          {typeIcons.map((typeIcon) => (
            <ToggleButton key={typeIcon.value} value={typeIcon.value}>
              <Tooltip
                title={
                  <span style={{ fontSize: "12px" }}>
                    {getDeviceTypeNames(typeIcon.value).formalPluralName}
                  </span>
                }
                arrow
                placement="top"
              >
                {typeIcon.icon}
              </Tooltip>
            </ToggleButton>
          ))}

          <ToggleButton value="all">
            <Tooltip title={<span style={{ fontSize: "12px" }}>All</span>} arrow placement="top">
              <SelectAll />
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
      </div>

      {/* Display all of the devices */}
      {loading ? (
        <Loading message="Loading devices" />
      ) : listDevices.length > 0 ? (
        <div className={classes.deviceCards}>
          {listDevices
            .filter(filterDevices)
            .slice((page - 1) * devicesPerPage, (page - 1) * devicesPerPage + devicesPerPage)
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((device, index) => (
              <DeviceItem key={index} device={device} warning={device.warning} />
            ))}
        </div>
      ) : (
        <div className="mt-5 text-center">
          <Typography variant="h5">No devices have been added to this group yet</Typography>
          <Button
            color="primary"
            variant="contained"
            className="mt-2"
            startIcon={<Add />}
            onClick={() => setAddDeviceModal(true)}
          >
            Add a device here
          </Button>
        </div>
      )}

      {/* Pagination */}
      <div className="text-center">
        {listDevices.length > devicesPerPage && (
          <Pagination
            page={page}
            count={Math.ceil(listDevices.length / devicesPerPage)}
            showFirstButton
            showLastButton
            classes={{ ul: classes.paginationContainer }}
            onChange={(e, val) => setPage(val)}
          />
        )}
      </div>

      <AddDevice
        open={addDeviceModal}
        onClose={() => setAddDeviceModal(false)}
        defaultDevice={
          types.length > 1 || types[0] === "all" ? "computer" : (types[0] as DeviceTypes)
        }
      />
    </div>
  );
};

export default DisplayDevices;
