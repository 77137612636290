import api from "./api";
import { HttpRequestError } from "./errors/HttpRequestError";

export const fetcher = async (url: string, token?: string) => {
  try {
    if (!token) {
      const localStorageToken = window.localStorage.getItem("token");
      token = localStorageToken ? JSON.parse(localStorageToken) : "";
    }

    const { data } = await api.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (err) {
    throw new HttpRequestError(err.response?.data?.message || err.message, err.response?.status);
  }
};
