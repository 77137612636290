import React, { useContext, useState } from "react";
// Material ui
import { IconButton, Popover, MenuItem, MenuList } from "@material-ui/core";
import { Settings, Delete, Edit } from "@material-ui/icons";
// Components
import ConfirmationModal from "./Modals/Confirmation";
import AddDeviceGroup from "./Modals/AddDeviceGroup";
// Utils
import Can, { check } from "./Can";
import DataContext from "../context/DataContext";
import { DeviceGroup } from "../@types/computer";
import api from "../utils/api";
import useLocalStorage from "../hooks/useLocalStorage";
import { getCompanyGroups } from "../utils/apiRequests";
import useCompanyPage from "../hooks/useCompanyPage";
import CompanyContext from "../context/CompanyContext";
import useToast from "../hooks/useToast";
import useRequestErrorHandler from "../hooks/useRequestErrorHandler";

interface Props {
  selectedGroup?: DeviceGroup;
}

const DeviceGroupsSettings: React.FC<Props> = ({ selectedGroup }) => {
  // Hooks
  const { user } = useContext(DataContext);
  const { setGroups } = useContext(CompanyContext);
  const [token] = useLocalStorage("token");
  const company = useCompanyPage();
  const toast = useToast();
  const handleRequestErrors = useRequestErrorHandler();

  // States
  const [loading, setLoading] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);
  const [groupModal, setGroupModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    open: false,
    title: "",
    message: <></>,
  });

  const closeConfirmationModal = () => setConfirmationModal({ ...confirmationModal, open: false });

  // Open group dialog and close the menu
  const handleEditOption = () => {
    setMenuAnchor(null);
    setGroupModal(true);
  };

  // Open confirmation dialog and close the menu
  const handleDeleteConfirmation = () => {
    setMenuAnchor(null);
    setConfirmationModal({
      open: true,
      title: "Delete device group",
      message: (
        <span>
          Would you like to delete{" "}
          {selectedGroup?.name ? <strong>{selectedGroup.name}</strong> : "this group"}
        </span>
      ),
    });
  };

  // Send request to delete a device group
  const deleteDeviceGroup = async () => {
    if (!selectedGroup) return;
    setLoading(true);

    try {
      await api.delete(`/deviceGroups/${selectedGroup._id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Request groups again to update the data
      const groups = await getCompanyGroups(company._id, token);
      setGroups(groups.sort((a, b) => (a.name > b.name ? 1 : -1)));

      // Close the confirmation dialog and display a snackbar
      toast({ open: true, type: "success", message: "Device deleted successfully" });
      setConfirmationModal({ ...confirmationModal, open: false });
    } catch (err) {
      handleRequestErrors(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {check(user.role, "devices:delete-group") && (
        <IconButton onClick={(e) => setMenuAnchor(e.currentTarget)}>
          <Settings />
        </IconButton>
      )}

      <Popover
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <MenuList>
          <Can role={user.role} perform="devices:edit-group">
            <MenuItem onClick={handleEditOption}>
              <Edit className="mr-3" />
              Edit
            </MenuItem>
          </Can>

          <Can role={user.role} perform="devices:delete-group">
            <MenuItem onClick={handleDeleteConfirmation}>
              <Delete className="mr-3" />
              Delete
            </MenuItem>
          </Can>
        </MenuList>
      </Popover>

      {/* Confirmation Modal */}
      <ConfirmationModal
        open={confirmationModal.open}
        onClose={closeConfirmationModal}
        title={confirmationModal.title}
        message={confirmationModal.message}
        onYes={deleteDeviceGroup}
        yesLoading={loading}
      />

      <AddDeviceGroup
        open={groupModal}
        onClose={() => setGroupModal(false)}
        group={selectedGroup}
      />
    </>
  );
};

export default DeviceGroupsSettings;
