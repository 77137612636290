import React, { useState, useEffect, useContext, useReducer } from "react";
import { useHistory } from "react-router-dom";
// Material ui
import { Divider, makeStyles, Button } from "@material-ui/core";
// Icons
import AddIcon from "@material-ui/icons/Add";
// Components
import UserForm from "../components/Modals/UserForm";
import UsersTable from "../components/Tables/UsersTable";
// Utils
import api from "../utils/api";
import DataContext from "../context/DataContext";
import { check } from "../components/Can";
// Hooks
import useLocalStorage from "../hooks/useLocalStorage";
import useRequestErrorHandler from "../hooks/useRequestErrorHandler";

const useStyles = makeStyles((theme) => ({
  tabRoot: {
    minWidth: "50%",
    maxWidth: "50%",
  },
  btnAddUser: {
    minHeight: "41px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

const initialState = {
  users: [],
  loading: true,
  snackbar: { open: false, type: "", message: "" },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "closeUserModal":
      return { ...state, addUserModal: false };

    case "startLoading":
      return { ...state, loading: true };
    case "setUsers":
      return { ...state, loading: false, users: action.payload };

    default:
      return state;
  }
};

const Users = () => {
  // Hooks
  const classes = useStyles();
  const { user } = useContext(DataContext);
  const [token] = useLocalStorage("token", "");
  const history = useHistory();
  const [{ users, loading }, dispatch] = useReducer(reducer, initialState);
  const handleRequestErrors = useRequestErrorHandler();

  // States
  const [userFormModal, setUserFormModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(undefined);

  useEffect(() => {
    if (!check(user.role, "users:visit")) {
      history.push("/dashboard");
    }
  }, [history, user.role]);

  useEffect(() => {
    getUsers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const getUsers = async () => {
    dispatch({ type: "startLoading" });

    try {
      const { data } = await api.get("/users", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch({
        type: "setUsers",
        payload: data.users.sort((a, b) => (a.name > b.name ? 1 : -1)),
      });
    } catch (err) {
      handleRequestErrors(err);
    }
  };

  const openUserForm = (user = undefined) => {
    setSelectedUser(user);
    setUserFormModal(true);
  };

  return (
    <div className="m-3">
      <div className="d-flex flex-wrap align-items-center">
        <h3 className="mt-auto mb-auto mr-auto align-items-center">Users</h3>

        <Button
          variant="outlined"
          className={classes.btnAddUser}
          startIcon={<AddIcon />}
          onClick={() => openUserForm()}
        >
          Add user
        </Button>
      </div>

      <Divider className="mt-3 mb-4" />

      <UsersTable data={users} loading={loading} onEdit={(user) => openUserForm(user)} />

      <UserForm
        open={userFormModal}
        onClose={() => setUserFormModal(false)}
        user={selectedUser}
        onSubmitSuccess={(newUsers) =>
          dispatch({
            type: "setUsers",
            payload: newUsers.sort((a, b) => (a.name > b.name ? 1 : -1)),
          })
        }
      />
    </div>
  );
};

export default Users;
