import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
// Material ui
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogProps,
  Grid,
  makeStyles,
  Paper,
} from "@material-ui/core";
// Components
import DialogBasic from "./DialogBasic";
// Utils
import { Computer } from "../../@types/computer";
import CustomTextField from "../Formik/CustomTextField";
import { Search } from "@material-ui/icons";
import useRequestErrorHandler from "../../hooks/useRequestErrorHandler";
import { newApi } from "../../utils/api";
import ListItems from "../ListItems";

interface Props extends DialogProps {
  computer: Computer;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  searchList: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.background.default,
  },
}));

const initialValues = { service: "" };

const validationSchema = yup.object({
  service: yup.string().required().min(3),
});

const SearchServices: React.FC<Props> = ({ computer, ...props }) => {
  // Hooks
  const requestErrorHandler = useRequestErrorHandler();
  const classes = useStyles();

  // States
  const [searchedServices, setSearchedServices] = useState<string[]>([]);
  const [addedServices, setAddedServices] = useState<string[]>([]);

  if (!props.open) return null;

  const closeModal = () => {
    setSearchedServices([]);
    setAddedServices([]);
    props.onClose();
  };

  const handleSearch = async ({ service }: typeof initialValues) => {
    try {
      const { data } = await newApi().get(
        `/commands/get-computer-services/${computer.id}?searchTerm=${service}`
      );

      setSearchedServices(data.services.filter((service) => !addedServices.includes(service)));
    } catch (err) {
      requestErrorHandler(err);
    }
  };

  const moveService = (
    service: string,
    from: React.Dispatch<React.SetStateAction<string[]>>,
    to: React.Dispatch<React.SetStateAction<string[]>>
  ) => {
    from((prevValues) => prevValues.filter((value) => value !== service));
    to((prevValues) => [...prevValues, service]);
  };

  const handleAddServices = async (setSubmitting: (isSubmitting: boolean) => void) => {
    try {
      setSubmitting(true);

      await newApi().put(`/devices/computers/${computer.id}/services`, {
        services: addedServices,
      });

      closeModal();
    } catch (err) {
      requestErrorHandler(err);
      setSubmitting(true);
    }
  };

  return (
    <DialogBasic title="Add a service" {...props} onClose={closeModal}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSearch}
      >
        {({ isSubmitting, setSubmitting }) => (
          <Form>
            <DialogContent>
              <Grid container spacing={2} justify="center" alignItems="center">
                <Grid item xs={12} sm={10}>
                  <CustomTextField
                    name="service"
                    label="Search for a service"
                    fullWidth
                    required
                    disabled={isSubmitting}
                  />
                </Grid>

                <Grid item sm xs={12}>
                  <Button
                    variant="outlined"
                    type="submit"
                    fullWidth
                    startIcon={<Search />}
                    disabled={isSubmitting}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>

              <Box display="flex" flexDirection="row" flexWrap="wrap" mt={2}>
                {addedServices.map((service, index) => (
                  <Chip
                    key={index}
                    label={service}
                    onDelete={() => moveService(service, setAddedServices, setSearchedServices)}
                    className="m-1"
                  />
                ))}
              </Box>

              <Paper elevation={2} className={classes.searchList}>
                <ListItems
                  items={searchedServices}
                  loading={isSubmitting}
                  onClick={(service) => moveService(service, setSearchedServices, setAddedServices)}
                />
              </Paper>
            </DialogContent>

            <DialogActions>
              <Button color="primary" onClick={closeModal}>
                Cancel
              </Button>

              <Button
                color="primary"
                disabled={addedServices.length <= 0 || isSubmitting}
                onClick={() => handleAddServices(setSubmitting)}
              >
                Add
              </Button>

              {isSubmitting && <CircularProgress />}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </DialogBasic>
  );
};

export default SearchServices;
