import React from "react";
// Material ui
import {
  FormControl,
  FormHelperText,
  FormLabel,
  RadioGroup,
  RadioGroupProps,
} from "@material-ui/core";
// Formik
import { FieldAttributes, useField } from "formik";

type Props = RadioGroupProps & FieldAttributes<{}> & { label: string; helperText?: string };

const CustomRadio: React.FC<Props> = ({ label, helperText, children, ...props }) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";

  return (
    <FormControl component="fieldset" error={!!errorText}>
      <FormLabel component="legend">{label}</FormLabel>

      <RadioGroup {...props} {...field}>
        {children}
      </RadioGroup>

      <FormHelperText>{errorText || helperText}</FormHelperText>
    </FormControl>
  );
};

export default CustomRadio;
