import React from "react";
import MomentUtils from "@date-io/moment";
// Material ui
import { createMuiTheme, MuiThemeProvider, CssBaseline } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Routes from "./routes";

const theme = {
  palette: {
    primary: {
      main: "#2196f3",
    },
    type: "dark",
    tableContrast: "#353535",
  },
};

const App = () => {
  const themeConfig = createMuiTheme(theme);

  return (
    <MuiThemeProvider theme={themeConfig}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <>
          <CssBaseline />
          <Routes />
        </>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
};

export default App;
