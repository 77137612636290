import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
// Material ui
import {
  Button,
  IconButton,
  makeStyles,
  Popover,
  MenuItem,
  MenuList,
  Typography,
} from "@material-ui/core";
// Icons
import { MoreVert, Add, GetApp, Edit, Delete } from "@material-ui/icons";
// Components
import Can, { check } from "./Can";
import FormCompany from "./Modals/FormCompany";
import ConfirmationModal from "./Modals/Confirmation";
import AddDevice from "./Modals/AddDevice";
import DisplayInfo from "./DisplayInfo";
// Hooks
import useCompanyPage from "../hooks/useCompanyPage";
import useLocalStorage from "../hooks/useLocalStorage";
import useToast from "../hooks/useToast";
import useRequestErrorHandler from "../hooks/useRequestErrorHandler";
// Utils
import DataContext from "../context/DataContext";
import api from "../utils/api";
import CompanyContext from "../context/CompanyContext";
import { Company } from "../@types/company";
import { backend } from "../utils/configs";

const useStyles = makeStyles((theme) => ({
  buttons: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
  },
}));

const hasMaxDevices = (company: Company) =>
  company.maxDevices && typeof company.maxDevices === "number";

const CompanyHeader: React.FC = () => {
  // Hooks
  const classes = useStyles();
  const company = useCompanyPage();
  const { user } = useContext(DataContext);
  const { totalDevices, loadingTotalDevices } = useContext(CompanyContext);
  const [token] = useLocalStorage("token");
  const toast = useToast();
  const handleRequestError = useRef(useRequestErrorHandler());
  const isCurrent = useRef(true);
  const history = useHistory();

  // States
  const [loading, setLoading] = useState(false);
  const [hasAddPermission, setHasAddPermission] = useState(false);
  const [addDeviceModal, setAddDeviceModal] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);
  const [formCompanyModal, setFormCompanyModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    open: false,
    title: "",
    message: <></>,
  });

  // Check if has permission to add new devices
  useEffect(() => {
    if (!isCurrent.current) return;
    setHasAddPermission(check(user.role, "devices:add"));

    return () => {
      isCurrent.current = false;
    };
  }, [user.role]);

  // Open modal to edit the company
  const handleEditOption = () => {
    setMenuAnchor(null);
    setFormCompanyModal(true);
  };

  // Close the confirmation modal
  const closeConfirmationModal = () => setConfirmationModal({ ...confirmationModal, open: false });

  // Open confirmation modal to delete the company
  const handleDeleteConfirmation = () => {
    setMenuAnchor(null);

    if (loadingTotalDevices) {
      return toast({ open: true, type: "info", message: "Loading computer information" });
    }

    if (totalDevices > 0) {
      toast({
        open: true,
        type: "error",
        message: "You can't delete a company that has devices saved",
      });
    } else {
      setConfirmationModal({
        open: true,
        title: "Delete company",
        message: (
          <span>
            Would you like to delete <strong>{company.name}</strong>?
          </span>
        ),
      });
    }
  };

  // Delete the company
  const deleteCompany = async () => {
    setLoading(true);

    try {
      await api.delete(`/company/${company._id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      toast({ open: true, type: "success", message: "Company successfully deleted" });
      history.push("/dashboard");
    } catch (err) {
      handleRequestError.current(err);
    } finally {
      setLoading(false);
    }
  };

  // Check if it is possible to add a new device
  const isAddBtnBlocked = () => {
    if (hasAddPermission) {
      if (hasMaxDevices(company) && totalDevices >= company.maxDevices!) {
        return { available: true, message: "Company max devices limit reached" };
      }

      if (user.userGroup && totalDevices >= user.userGroup.maxDevices) {
        return { available: true, message: "User group max devices limit reached" };
      }
    }

    return { available: false, message: "" };
  };

  return (
    <>
      <div className="d-flex flex-wrap align-items-center justify-content-between">
        <div>
          <h3>
            {company.name}
            {(check(user.role, "companies:edit") || check(user.role, "companies:delete")) && (
              <IconButton className="ml-1" onClick={(e) => setMenuAnchor(e.currentTarget)}>
                <MoreVert />
              </IconButton>
            )}
          </h3>

          <DisplayInfo
            title="Total devices"
            message={
              loadingTotalDevices
                ? "Loading..."
                : `${totalDevices} ${hasMaxDevices(company) ? `of ${company.maxDevices}` : ""}`
            }
          />
        </div>

        <div className="text-center" hidden={loadingTotalDevices}>
          <div>
            {hasAddPermission ? (
              <Button
                startIcon={<Add />}
                variant="outlined"
                onClick={() => setAddDeviceModal(true)}
                disabled={isAddBtnBlocked().available}
                className={classes.buttons}
              >
                Add device
              </Button>
            ) : null}

            <Button
              component="a"
              href={backend.latestClientUrl}
              startIcon={<GetApp />}
              variant="outlined"
              className={classes.buttons}
              style={{ color: "white" }}
            >
              Download client
            </Button>
          </div>

          {isAddBtnBlocked().available && (
            <Typography variant="caption" className="mt-1 d-block">
              {isAddBtnBlocked().message}
            </Typography>
          )}
        </div>
      </div>

      <Popover
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <MenuList>
          <Can role={user.role} perform="companies:edit">
            <MenuItem onClick={handleEditOption}>
              <Edit className="mr-3" />
              Edit
            </MenuItem>
          </Can>

          <Can role={user.role} perform="companies:delete">
            <MenuItem onClick={handleDeleteConfirmation}>
              <Delete className="mr-3" />
              Delete
            </MenuItem>
          </Can>
        </MenuList>
      </Popover>

      {/* Add device modal */}
      <Can role={user.role} perform="devices:add">
        <AddDevice open={addDeviceModal} onClose={() => setAddDeviceModal(false)} />
      </Can>

      {/* Form modal to add a company */}
      <FormCompany
        open={formCompanyModal}
        onClose={() => setFormCompanyModal(false)}
        company={company}
      />

      {/* Confirmation Modal */}
      <ConfirmationModal
        open={confirmationModal.open}
        onClose={closeConfirmationModal}
        title={confirmationModal.title}
        message={confirmationModal.message}
        onYes={deleteCompany}
        yesDisabled={loadingTotalDevices}
        yesLoading={loading}
      />
    </>
  );
};

export default CompanyHeader;
