import React from "react";
// Material ui
import {
  FormControl,
  FormLabel,
  FormHelperText,
  SwitchProps,
  FormControlLabel,
  Switch,
  FormControlLabelProps,
} from "@material-ui/core";
// Formik
import { FieldAttributes, useField } from "formik";

type Props = Omit<FormControlLabelProps, "control"> &
  FieldAttributes<{}> & {
    label: string;
    switchLabel: string;
    helperText?: string;
    switchprops?: SwitchProps;
  };

const CustomSwitch: React.FC<Props> = ({ label, switchLabel, helperText, ...props }) => {
  const [field, meta, helpers] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";

  return (
    <FormControl component="fieldset" error={!!errorText}>
      <FormLabel component="legend">{label}</FormLabel>

      <FormControlLabel
        {...props}
        control={
          <Switch
            {...props.switchprops}
            checked={field.value as boolean}
            onChange={() => helpers.setValue(!field.value)}
          />
        }
        label={switchLabel}
      />

      <FormHelperText>{errorText || helperText}</FormHelperText>
    </FormControl>
  );
};

export default CustomSwitch;
