import React from "react";

interface Props {
  title?: string;
  percentage: number;
  dangerLimit?: number;
  warningLimit?: number;
  percentageFixed?: number;
  className?: string;
}

const getProgressBarColor = (percentage: number, dangerLimit: number, warningLimit: number) =>
  percentage >= dangerLimit ? "bg-danger" : percentage >= warningLimit ? "bg-warning" : "";

const ProgressBar: React.FC<Props> = ({
  title,
  percentage,
  dangerLimit = 90,
  warningLimit = 75,
  percentageFixed = 2,
  className,
}) => {
  return (
    <div className={className}>
      <p className="m-0">{title}</p>

      <div className="progress mb-2">
        <div
          className={`progress-bar ${getProgressBarColor(percentage, dangerLimit, warningLimit)}`}
          role="progressbar"
          style={{ width: `${percentage}%` }}
          aria-valuenow={percentage}
          aria-valuemin={0}
          aria-valuemax={100}
        >
          {`${percentage.toFixed(percentageFixed)}%`}
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
