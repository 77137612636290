import React, { useState, useRef } from "react";
import {
  ButtonGroup,
  Button,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Popover,
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";

interface Props {
  className?: string | undefined;
  onClick: (formatOption: "pdf" | "csv") => void;
}

const options = ["Export to CSV", "Export to PDF"];
const FORMAT: ("csv" | "pdf")[] = ["csv", "pdf"];

const ExportSplitButton: React.FC<Props> = ({ className, onClick }) => {
  // Hooks
  const anchorRef = useRef<HTMLDivElement>(null);

  // States
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(1);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onClick(FORMAT[selectedIndex]);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleMenuItemClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleClose = (e: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(e.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <div className={className}>
      <ButtonGroup variant="contained" color="primary" ref={anchorRef}>
        <Button onClick={handleClick}>{options[selectedIndex]}</Button>
        <Button color="primary" size="small" onClick={handleToggle}>
          <ArrowDropDown />
        </Button>
      </ButtonGroup>

      <Popover
        open={open}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList id="split-button-menu">
              {options.map((option, index) => (
                <MenuItem
                  key={option}
                  selected={index === selectedIndex}
                  onClick={(event) => handleMenuItemClick(event, index)}
                >
                  {option}
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popover>
    </div>
  );
};

export default ExportSplitButton;
