import React, { useContext, useEffect, useRef, useState } from "react";
// Components
import DialogBasic from "./DialogBasic";
// Utils
import DataContext from "../../context/DataContext";
import UpdateDevicesTable from "../Tables/UpdateDevicesTable";
import useFetch from "../../hooks/useFetch";
import api from "../../utils/api";
import useLocalStorage from "../../hooks/useLocalStorage";
import useRequestErrorHandler from "../../hooks/useRequestErrorHandler";
import useToast from "../../hooks/useToast";
import { Computer } from "../../@types/computer";
import { Company } from "../../@types/company";

interface UpdateDevicesProps {
  open: boolean;
  onClose: () => void;
}

export type OutdatedComputer = Omit<Computer, "company"> & { company: Company };

const getComputerCompany = (computer: Computer, companies: Company[]) =>
  companies.reduce((acc, curr) => (curr._id === computer.company ? curr : acc));

const UpdateDevices: React.FC<UpdateDevicesProps> = ({ open, onClose }) => {
  // Hooks
  const { companies } = useContext(DataContext);
  const isCurrent = useRef(true);
  const [token] = useLocalStorage("token");
  const handleRequestErrors = useRequestErrorHandler();
  const toast = useToast();
  const { data, loading, refetch } = useFetch<{ computers: Computer[] }>(
    "/devices/computers/outdated",
    { skip: !open }
  );

  // State
  const [outdatedComputers, setOutdatedComputers] = useState<OutdatedComputer[]>([]);

  useEffect(() => {
    if (!open || !isCurrent) return;

    const fetchInterval = setInterval(refetch, 60 * 1000);

    return () => {
      isCurrent.current = false;
      clearInterval(fetchInterval);
    };
  }, [open, refetch]);

  useEffect(() => {
    if (!open || !isCurrent || !data) return;
    setOutdatedComputers(
      data.computers.map((computer) => ({
        ...computer,
        company: getComputerCompany(computer, companies as Company[]),
      }))
    );
  }, [companies, data, open]);

  if (!open) return <React.Fragment />;

  const handleSendCommand = async (ids: string[]) => {
    try {
      await api.post(
        "/devices/computers/update-connector/many",
        { computers: ids },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      toast({ open: true, type: "success", message: "Command successfully sent to the computers" });
      refetch();
    } catch (err) {
      handleRequestErrors(err);
    }
  };

  return (
    <DialogBasic open={open} onClose={onClose}>
      <UpdateDevicesTable
        data={Object.values(outdatedComputers)}
        loading={loading && !data}
        onRefetch={refetch}
        onSendCommand={handleSendCommand}
      />
    </DialogBasic>
  );
};

export default UpdateDevices;
