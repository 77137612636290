import React, { useContext, useState } from "react";
import clsx from "clsx";
// Material ui
import { makeStyles, MenuItem, MenuList, Tooltip, Typography } from "@material-ui/core";
// Icons
import { Add, ExitToApp } from "@material-ui/icons";
// Components
import ZendeskTicketForm from "./Modals/ZendeskTicketForm";
// Utils
import ZendeskContext from "../context/ZendeskContext";
import { getSupportUrl } from "../utils/zendeskUtils";
import { ZendeskIconButtonProps } from "./ZendeskIconButton";

interface Props extends ZendeskIconButtonProps {}

const useStyles = makeStyles((theme) => ({
  ticketsContainer: {
    display: "flex",
    alignItems: "center",
  },
  tickets: {
    flex: 1,
    padding: theme.spacing(0.5),
    textAlign: "center",
  },
  openTickets: { background: theme.palette.error.dark },
  pendingTickets: { background: theme.palette.primary.dark },
  holdTickets: { background: "#000" },
}));

const AccountIntegratedToZendeskMenu: React.FC<Props> = ({
  showTicketStatusAmount,
  targetDevice,
}) => {
  // Hooks
  const { integration, tickets } = useContext(ZendeskContext);
  const classes = useStyles();

  // States
  const [ticketForm, setTicketForm] = useState(false);

  return (
    <>
      {showTicketStatusAmount && (
        <div className={classes.ticketsContainer}>
          <Tooltip title="Open ticket(s)" placement="top">
            <Typography className={clsx(classes.tickets, classes.openTickets)}>
              {tickets?.open}
            </Typography>
          </Tooltip>

          <Tooltip title="Pending ticket(s)" placement="top">
            <Typography className={clsx(classes.tickets, classes.pendingTickets)}>
              {tickets?.pending}
            </Typography>
          </Tooltip>

          <Tooltip title="On hold ticket(s)" placement="top">
            <Typography className={clsx(classes.tickets, classes.holdTickets)}>
              {tickets?.hold}
            </Typography>
          </Tooltip>
        </div>
      )}

      <MenuList>
        <MenuItem onClick={() => setTicketForm(true)}>
          <Add className="mr-2" /> Create a new ticket
        </MenuItem>

        <a
          href={getSupportUrl(integration?.zendesk.domain || "")}
          target="_blank"
          rel="noopener noreferrer"
          className="text-white text-decoration-none"
        >
          <MenuItem>
            <ExitToApp className="mr-2" /> Go to Zendesk
          </MenuItem>
        </a>
      </MenuList>

      <ZendeskTicketForm
        open={ticketForm}
        onClose={() => setTicketForm(false)}
        device={targetDevice}
      />
    </>
  );
};

export default AccountIntegratedToZendeskMenu;
