import { Alert } from "@material-ui/lab";
import React from "react";

interface ErrorAlertProps {}

export const ErrorAlert: React.FC<ErrorAlertProps> = ({ children }) => {
  return (
    <Alert variant="filled" severity="error">
      {children ?? "An unexpected error happened. Please contact the support team"}
    </Alert>
  );
};
