import React from "react";
// Material ui
import {
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
// Icons
import { Search } from "@material-ui/icons";
import { FaFileCsv } from "react-icons/fa";

interface QrCodesTableHeaderProps {
  globalFilter: any;
  setGlobalFilter: (filterValue: any) => void;
  onExport: () => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    marginLeft: "auto",
    justifyContent: "center",
    alignItems: "center",
  },
  exportIcon: {
    marginLeft: theme.spacing(1),
  },
}));

export const QrCodesTableToolbar: React.FC<QrCodesTableHeaderProps> = ({
  globalFilter,
  setGlobalFilter,
  onExport,
}) => {
  // Hooks
  const classes = useStyles();

  return (
    <Toolbar>
      <div className={classes.container}>
        <TextField
          placeholder="Filter values"
          value={globalFilter || ""}
          onChange={(e) => setGlobalFilter(e.target.value || undefined)}
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />

        <Tooltip title="Export to CSV">
          <IconButton onClick={() => onExport()} className={classes.exportIcon}>
            <FaFileCsv />
          </IconButton>
        </Tooltip>
      </div>
    </Toolbar>
  );
};
