import React, { useState, useEffect, useContext, useRef } from "react";
import moment from "moment";
import marked from "marked";
// Material ui
import { DialogContent, Typography, DialogActions, Button, Grid, Divider } from "@material-ui/core";
// Components
import DialogBasic from "./DialogBasic";
import Loading from "../Loading";
import { DeviceItem } from "../DisplayDevices";
import DisplayInfo from "../DisplayInfo";
import DeviceModalHeader, { onDelete } from "./DeviceModalHeader";
// Utils
import { BasicDeviceInfo } from "../../@types/computer";
import api from "../../utils/api";
import useLocalStorage from "../../hooks/useLocalStorage";
import useCompanyPage from "../../hooks/useCompanyPage";
import CompanyContext from "../../context/CompanyContext";
import { getDeviceCards } from "../../utils/deviceUtils";
import useToast from "../../hooks/useToast";
import useRequestErrorHandler from "../../hooks/useRequestErrorHandler";

interface Props {
  open: boolean;
  onClose: () => void;
  deviceItem: DeviceItem;
  widthSize?: false | "md" | "xs" | "sm" | "lg" | "xl";
}

// Request a single device info
const fetchDevice = async (device: DeviceItem, token: string): Promise<BasicDeviceInfo> => {
  const { data } = await api.get(`/devices/${device.id}?type=${device.type}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return data.device as BasicDeviceInfo;
};

const OtherDevicesModal: React.FC<Props> = ({ open, onClose, deviceItem, widthSize = "sm" }) => {
  // Hooks
  const [token] = useLocalStorage("token");
  const company = useCompanyPage();
  const { selectedGroup, setDeviceCards } = useContext(CompanyContext);
  const toast = useToast();
  const handleRequestErrors = useRef(useRequestErrorHandler());

  // States
  const [loading, setLoading] = useState(true);
  const [device, setDevice] = useState<BasicDeviceInfo>();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const device = await fetchDevice(deviceItem, token);
        setDevice(device);
      } catch (err) {
        handleRequestErrors.current(err);
      } finally {
        setLoading(false);
      }
    };

    if (open) {
      fetchData();
    }
  }, [open, deviceItem, token]);

  const deleteDevice: onDelete = async (setLoading, closeConfirmationModal) => {
    if (!device) return;
    setLoading(true);

    try {
      // Delete the device
      await api.delete(`/devices/${device._id}`, { headers: { Authorization: `Bearer ${token}` } });

      // Update the devices data
      setDeviceCards(await getDeviceCards(selectedGroup?._id || "0", company._id, token));

      // Close both dialogs and display a snackbar
      toast({ open: true, type: "success", message: "Device deleted successfully" });
      closeConfirmationModal();
      onClose();
    } catch (err) {
      handleRequestErrors.current(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DialogBasic
        open={open}
        onClose={onClose}
        title={
          <DeviceModalHeader device={device} deviceItem={deviceItem} onDelete={deleteDevice} />
        }
        widthSize={widthSize}
      >
        <DialogContent>
          {loading ? (
            <Loading message="Loading device information" />
          ) : (
            <>
              {/* Brand and model */}
              <Grid container alignItems="center" spacing={2}>
                {/* Brand */}
                <Grid item xs={12} md={6}>
                  {device?.brand && <DisplayInfo title="Brand" message={device.brand} />}
                </Grid>

                {/* Model */}
                <Grid item xs={12} md={6} className="">
                  {device?.model && <DisplayInfo title="Model" message={device.model} />}
                </Grid>
              </Grid>

              {/* Serial number and warranty date */}
              {(device?.serialNumber || device?.warrantyDate) && (
                <Grid container spacing={2} alignItems="center">
                  {/* Serial number */}
                  {device.serialNumber && (
                    <Grid item md={6}>
                      <DisplayInfo
                        title="Serial number"
                        message={device.serialNumber}
                        secret={true}
                      />
                    </Grid>
                  )}

                  {/* Warranty date */}
                  {device.warrantyDate && (
                    <Grid item md={6}>
                      <DisplayInfo
                        title="Warranty"
                        message={`${moment(new Date(device.warrantyDate)).format(
                          "MMM Do YY"
                        )} (${moment(new Date(device.warrantyDate)).fromNow()})`}
                      />
                    </Grid>
                  )}
                </Grid>
              )}

              {/* Specific information */}
              {device?.specificInformation && (
                <Grid container spacing={2} alignItems="center">
                  {device.specificInformation.map((info, index) => (
                    <Grid key={index} item md={6}>
                      <DisplayInfo title={info.name} message={info.value} />
                    </Grid>
                  ))}
                </Grid>
              )}

              {/* Comments */}
              {device?.comments && (
                <div>
                  <Divider className="my-2" />
                  <Typography variant="body2">
                    <strong>Comments:</strong>
                  </Typography>

                  <div
                    dangerouslySetInnerHTML={{ __html: marked(device.comments, { breaks: true }) }}
                  />
                </div>
              )}
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </DialogBasic>
    </>
  );
};

export default OtherDevicesModal;
