import React, { useState, useContext, useMemo } from "react";
// Material-ui
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  TextField,
  InputAdornment,
  makeStyles,
} from "@material-ui/core";
// Material-icons
import { AddCircle, Search, Person, Business, Poll } from "@material-ui/icons";
import { AiOutlineQrcode } from "react-icons/ai";
// Components
import CustomCollapse from "../CustomCollapse";
import FormCompany from "../Modals/FormCompany";
import Can from "../Can";
// Context
import DataContext from "../../context/DataContext";

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const listUserItems = [
  { label: "Users", link: "/dashboard/users" },
  { label: "Groups", link: "/dashboard/users/groups" },
  { label: "Users activities", link: "/dashboard/users/activities" },
];

const ListCompanies = ({ companies, history, toggleDrawer, expand }) => {
  // Hooks
  const classes = useStyles();
  const { user } = useContext(DataContext);

  // States
  const [open, setOpen] = useState(false);
  const [listValues, setListValues] = useState(companies);
  const [searchValue, setSearchValue] = useState("");
  const [controlCollapse, setControlCollapse] = useState({
    users: false,
    companies: false,
  });

  useMemo(() => {
    if (expand !== undefined && !expand.open) {
      setControlCollapse({ users: false, companies: false });
    }
  }, [expand]);

  // Search for the company or device
  useMemo(() => {
    const searchTerm = searchValue.toLowerCase().trim();
    setListValues(companies.filter((val) => val.name.toLowerCase().includes(searchTerm)));
  }, [searchValue, companies]);

  const goTo = (url) => {
    history.push(url);
    if (toggleDrawer) toggleDrawer();
  };

  const handleLink = (compId) => {
    history.push(`/dashboard/company/${compId}`);
    if (toggleDrawer) toggleDrawer();
  };

  const toggleCollapse = (collapse) => {
    if (expand && !expand.open) {
      expand.set(true);
    }
    setControlCollapse({ ...controlCollapse, [collapse]: !controlCollapse[collapse] });
  };

  return (
    <>
      <Divider />

      {/* Dashboard */}
      <ListItem button onClick={() => goTo("/dashboard")}>
        <ListItemIcon>
          <Poll />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>

      <Divider />

      {/* Users */}
      <Can role={user.role} perform="users:visit">
        <div className={classes.root}>
          <CustomCollapse
            title="Users"
            icon={<Person />}
            open={controlCollapse.users}
            onChange={() => toggleCollapse("users")}
          >
            {listUserItems.map((userItem, index) => (
              <ListItem key={index} button onClick={() => goTo(userItem.link)}>
                <ListItemText className="ml-3 mr-2" primary={userItem.label} />
              </ListItem>
            ))}
          </CustomCollapse>
        </div>
      </Can>

      {/* QR Codes */}
      <Can role={user.role} perform="qr-code:visit">
        <>
          <ListItem button onClick={() => goTo("/dashboard/qrcodes")}>
            <ListItemIcon>
              <AiOutlineQrcode style={{ width: 24, height: 24 }} />
            </ListItemIcon>

            <ListItemText primary="QR codes" style={{ whiteSpace: "nowrap" }} />
          </ListItem>

          <Divider />
        </>
      </Can>

      {/* Companies */}
      <div className={classes.root}>
        <CustomCollapse
          title="Companies"
          icon={<Business />}
          open={controlCollapse.companies}
          onChange={() => toggleCollapse("companies")}
        >
          <div className="text-center pl-4 pr-4">
            <TextField
              id="search"
              placeholder="Search"
              fullWidth
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <Can role={user.role} perform="companies:add">
            <ListItem button onClick={() => setOpen(true)}>
              <ListItemIcon>
                <AddCircle />
              </ListItemIcon>

              <ListItemText primary="Add company" />
            </ListItem>
          </Can>

          {listValues
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((comp, index) => (
              <ListItem key={index} button onClick={() => handleLink(comp._id)}>
                <ListItemText className="ml-2 mr-2" primary={comp.name} />
              </ListItem>
            ))}
        </CustomCollapse>
      </div>

      <FormCompany open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default ListCompanies;
