import React, { useMemo } from "react";
import { createContext, useState } from "react";
import { User } from "../@types/users";
import { Company } from "../@types/company";
import { client } from "../constants/configs";

interface System {
  connectorVersion: string;
}

interface DataContextProps {
  companies: Company[];
  user: User;
  system: System;
  setCompanies: React.Dispatch<React.SetStateAction<Company[]>>;
  setUser: React.Dispatch<React.SetStateAction<User>>;
  setSystem: React.Dispatch<React.SetStateAction<System>>;
}

const DataContext = createContext<DataContextProps>({
  companies: [],
  user: null!,
  system: null!,
  setCompanies: () => {},
  setUser: () => {},
  setSystem: () => {},
});

export const DataProvider: React.FC = ({ children }) => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [user, setUser] = useState<User>(null!);
  const [system, setSystem] = useState<System>({ connectorVersion: client.currentVersion });

  const values = useMemo(
    () => ({ companies, user, system, setCompanies, setUser, setSystem }),
    [companies, system, user]
  );

  return <DataContext.Provider value={values}>{children}</DataContext.Provider>;
};

export default DataContext;
