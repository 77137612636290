import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
// Material ui
import {
  Divider,
  TextField,
  Button,
  Grid,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
// Icons
import { Search } from "@material-ui/icons";
// Components
import ActivitiesTable from "../components/Tables/ActivitiesTable";
import Loading from "../components/Loading";
import { check } from "../components/Can";
import ExportSplitButton from "../components/ExportSplitButton";
// Hooks
import useLocalStorage from "../hooks/useLocalStorage";
// Utils
import api, { getUsers } from "../utils/api";
import DataContext from "../context/DataContext";
import SelectUserModal from "../components/Modals/SelectUserModal";
import useRequestErrorHandler from "../hooks/useRequestErrorHandler";

const useStyles = makeStyles((theme) => ({
  btnSearch: {
    width: "100%",
    height: "100%",
  },
}));

const addField = (label, value, setValue, options, getOptionLabel = (opt) => opt) => {
  return { label, value, setValue, options, getOptionLabel };
};

const UserActivity = () => {
  //Hooks
  const [token] = useLocalStorage("token", "");
  const history = useHistory();
  const classes = useStyles();
  const { companies, user } = useContext(DataContext);
  const handleRequestErrors = useRequestErrorHandler();

  // State
  const [usersInput, setUsersInput] = useState({ autoComplete: null, input: "" });
  const [resourcesInput, setResourcesInput] = useState({ autoComplete: null, input: "" });
  const [companyInput, setCompanyInput] = useState({ autoComplete: null, input: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [activities, setActivities] = useState([]);
  const [users, setUsers] = useState([]);
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [qtdSelect, setQtdSelect] = useState("10");
  const [selectUserModal, setSelectUserModal] = useState(false);
  const [selectedExportFileType, setSelectedExportFileType] = useState("");

  const tableHead = ["User", "Resource", "Action", "Company", "Timestamp"];
  const resources = ["Dashboard", "Users", "Companies", "Services", "User groups"].sort();
  const qtdValues = [10, 25, 50, 75, 100];

  const queryFields = [
    addField("Users", usersInput, setUsersInput, users, (opt) => opt.email),
    addField("Resources", resourcesInput, setResourcesInput, resources),
    addField("Company", companyInput, setCompanyInput, companiesOptions, (opt) => opt.name),
  ];

  useEffect(() => {
    if (!check(user.role, "users:visit")) {
      history.push("/dashboard");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await fetchActivities();
      await fetchUsers();
      setIsLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCompaniesOptions([{ id: false, name: "System" }, ...companies]);
  }, [companies]);

  // Get the activities based on the query
  const fetchActivities = async (query = "") => {
    setIsLoading(true);
    try {
      const { data } = await api.get(`/userActivity/?qtd=${qtdSelect}&${query}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setActivities(data.activities);
    } catch (err) {
      handleRequestErrors(err);
    } finally {
      setIsLoading(false);
    }
  };

  // Get all the users
  const fetchUsers = async () => {
    try {
      const { data } = await getUsers(token);
      setUsers(data.users.sort((a, b) => (a.email > b.email ? 1 : -1)));
    } catch (err) {
      handleRequestErrors(err);
    }
  };

  const handleSearch = () => {
    let query = "";
    query += usersInput.autoComplete ? `user=${usersInput.autoComplete._id}&` : "";
    query += resourcesInput.autoComplete ? `resource=${resourcesInput.autoComplete}&` : "";

    // Check if the user selected a company and if the company is not the "System"
    if (companyInput.autoComplete) {
      query += companyInput.autoComplete._id
        ? `company=${companyInput.autoComplete._id}`
        : "onlySystem=true";
    }

    fetchActivities(query);
  };

  const handleExport = (format) => {
    setSelectedExportFileType(format);
    setSelectUserModal(true);
  };

  return (
    <div className="m-3">
      <div className="d-flex flex-wrap align-items-center">
        <h3 className="mt-auto mb-auto mr-auto align-items-center">User activity</h3>

        <ExportSplitButton onClick={handleExport} />
      </div>

      <Divider className="mt-3 mb-4" />

      <Grid container spacing={3} className="mb-2">
        {queryFields.map((field, index) => {
          const { label, value, setValue, options, getOptionLabel } = field;

          return (
            <Grid key={index} item xs={12} sm={4} md={3}>
              <Autocomplete
                value={value.autoComplete}
                onChange={(e, newVal) => setValue({ ...value, autoComplete: newVal })}
                inputValue={value.input}
                onInputChange={(e, newVal) => setValue({ ...value, input: newVal })}
                options={options}
                getOptionLabel={getOptionLabel}
                disabled={isLoading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    inputProps={{ ...params.inputProps, autoComplete: "new-password" }}
                  />
                )}
              />
            </Grid>
          );
        })}

        <Grid item xs={12} sm={12} md>
          <FormControl variant="outlined" fullWidth disabled={isLoading}>
            <InputLabel id="qtd-select-label">Quantity</InputLabel>
            <Select
              id="qtd-select"
              labelId="qtd-select-label"
              label="Quantity"
              value={qtdSelect}
              onChange={(e) => setQtdSelect(e.target.value)}
            >
              {qtdValues.map((qtd) => (
                <MenuItem key={qtd} value={qtd}>
                  {qtd}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm>
          <Button
            variant="outlined"
            startIcon={<Search />}
            size="large"
            className={classes.btnSearch}
            disabled={isLoading}
            onClick={handleSearch}
          >
            Search
          </Button>
        </Grid>
      </Grid>

      {isLoading ? <Loading /> : <ActivitiesTable tableHead={tableHead} data={activities} />}

      <SelectUserModal
        open={selectUserModal}
        onClose={() => setSelectUserModal(false)}
        users={users}
        formatType={selectedExportFileType}
      />
    </div>
  );
};

export default UserActivity;
