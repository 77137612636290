import React from "react";
// Material ui
import {
  Card,
  CardContent,
  CircularProgress,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { SvgIconComponent } from "@material-ui/icons";

interface Props {
  icon: SvgIconComponent;
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  tooltip?: string;
  type?: "info" | "warning";
  width?: number;
  borderWidth?: number | string;
  onClick?: () => void;
  loading?: boolean;
}

interface StyleProps {
  cardWidth: number;
  borderWidth: number | string;
  type?: "info" | "warning";
  click: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  card: ({ cardWidth, borderWidth, type, click }) => ({
    margin: theme.spacing(1),
    width: cardWidth,
    minHeight: 180,
    cursor: click ? "pointer" : "auto",
    borderBottomStyle: "solid",
    borderBottomWidth: type ? borderWidth : 0,
    borderBottomColor: type ? theme.palette[type].main : "none",
  }),
  cardContent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(2),
  },
  icon: { fontSize: theme.typography.h3.fontSize },
}));

const InfoCard: React.FC<Props> = ({
  icon: Icon,
  title,
  subtitle,
  tooltip,
  width = 250,
  borderWidth = 5,
  type,
  onClick,
  loading = false,
}) => {
  const classes = useStyles({ cardWidth: width, borderWidth, type, click: !!onClick });

  return (
    <Card className={classes.card} classes={{ root: "p-0" }} onClick={onClick} title={tooltip}>
      <CardContent className={classes.cardContent}>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <div className={classes.iconContainer}>
              <Icon className={classes.icon} />
            </div>

            {typeof title === "string" ? (
              <Typography variant="subtitle1">{title}</Typography>
            ) : (
              title
            )}

            {typeof subtitle === "string" ? (
              <Typography variant="h6">{subtitle}</Typography>
            ) : (
              subtitle
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default InfoCard;
