import useSWR from "swr";
import { fetcher } from "utils/fetcher";
import { Company } from "../@types/company";
import useRequestErrorHandler from "./useRequestErrorHandler";

type UseCompanies = () => {
  companies: Company[];
  isLoading: boolean;
  error: any;
  mutate: (data?: any, shouldRevalidate?: boolean) => Promise<any>;
};

export const useCompanies: UseCompanies = () => {
  const { data, error, mutate } = useSWR<{ companies: Company[] }>("/company", fetcher);
  const handleRequestError = useRequestErrorHandler();

  if (error) {
    handleRequestError(error);
  }

  return {
    companies: data?.companies ?? [],
    isLoading: !data && !error,
    error,
    mutate,
  };
};
