import React from "react";
// Material ui
import { SelectProps, FormControl, InputLabel, Select, FormHelperText } from "@material-ui/core";
// Formik
import { FieldAttributes, useField } from "formik";

type CustomSelect = SelectProps & FieldAttributes<{}>;

const CustomSelect: React.FC<CustomSelect> = ({ id, label, variant, required, ...props }) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";

  return (
    <FormControl fullWidth variant={variant} error={!!errorText}>
      <InputLabel id={`${id}-label`} required={required}>
        {label}
      </InputLabel>

      <Select {...props} {...field} labelId={`${id}-label`} id={id} label={label} />

      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

export default CustomSelect;
