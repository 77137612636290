import React, { useContext, useEffect, useRef, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
// Material ui
import { makeStyles, Toolbar } from "@material-ui/core";
// Components
import Loading from "../components/Loading";
import DrawerMenu from "../components/Drawer";
import Navbar from "../components/Navbar";
import Routes from "../dashboardRoutes";
// Utils
import DataContext from "../context/DataContext";
import useLocalStorage from "../hooks/useLocalStorage";
import api from "../utils/api";
import useRequestErrorHandler from "../hooks/useRequestErrorHandler";
import ZendeskContext from "../context/ZendeskContext";
import { UserGroup, User } from "../@types/users";
import { Company } from "../@types/company";
import { User as ZendeskUser, UserTickets, ZendeskIntegration } from "../@types/zendesk";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
  },
  contentContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));

const getCurrentUserData = async (token: string) => {
  const { data } = await api.get("/users/one", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data.user as User & { userGroup?: UserGroup };
};

const getCompanies = async (token: string) => {
  const { data } = await api.get("/company", { headers: { Authorization: `Bearer ${token}` } });

  return data.companies as Company[];
};

const getZendeskUser = async (token: string) => {
  try {
    const { data } = await api.get("/zendesk/users/me", {
      headers: { Authorization: `Bearer ${token}` },
    });

    return data as { user: ZendeskUser; integration: ZendeskIntegration; tickets: UserTickets };
  } catch (err) {
    return null;
  }
};

const Dashboard = () => {
  // Hooks
  const classes = useStyles();
  const { companies } = useContext(DataContext);
  const mainCtx = useRef(useContext(DataContext));
  const zendeskCtx = useRef(useContext(ZendeskContext));
  const history = useHistory();
  const [token] = useLocalStorage("token");
  const isCurrent = useRef(true);
  const requestErrorHandler = useRef(useRequestErrorHandler());

  // States
  const [openDrawer, setOpenDrawer] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    return () => {
      isCurrent.current = false;
    };
  }, []);

  useEffect(() => {
    if (!isCurrent.current) return;
    setLoading(true);

    (async () => {
      try {
        const { setUser, setCompanies } = mainCtx.current;
        const [user, companies, zendesk] = await Promise.all([
          getCurrentUserData(token),
          getCompanies(token),
          getZendeskUser(token),
        ]);

        setUser(user);
        setCompanies(companies);
        zendeskCtx.current.setUser(zendesk?.user || null);
        zendeskCtx.current.setIntegration(zendesk?.integration || null);
        zendeskCtx.current.setTickets(zendesk?.tickets || null);
        setLoading(false);
      } catch (err) {
        requestErrorHandler.current(err);
      }
    })();
  }, [history, token]);

  // Open or close the drawer
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  if (token.trim() === "") return <Redirect to="/" />;

  return loading ? (
    <div className="w-100 h-100">
      <Loading message="Loading data..." style={{ height: "100%" }} />
    </div>
  ) : (
    <div className={classes.root}>
      <Navbar toggleDrawer={toggleDrawer} />
      <DrawerMenu
        open={openDrawer}
        toggleDrawer={toggleDrawer}
        // TODO: Get them inside the component
        companies={companies}
        history={history}
      />

      <div className={classes.contentContainer}>
        <Toolbar className={classes.toolbar} />
        <Routes />
      </div>
    </div>
  );
};

export default Dashboard;
