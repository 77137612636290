import React, { useMemo } from "react";
import { Form, Formik, FormikConfig } from "formik";
import * as yup from "yup";
// Material ui
import { Box, Button } from "@material-ui/core";
// Components
import CustomAutocomplete from "components/Formik/CustomAutocomplete";
// Hooks
import { useCompanies } from "hooks/useCompanies";
// Utils
import { IQrCodeActionFormsProps } from "./QrCodeActionFormsProps";
import { Company } from "../../../@types/company";

type OpenCompanyPageActionProps = IQrCodeActionFormsProps;

const validationSchema = yup.object({
  company: yup.object({
    _id: yup.string().required(),
    name: yup.string().required(),
  }),
});

export const OpenCompanyPageAction: React.FC<OpenCompanyPageActionProps> = ({
  isLoading,
  onSubmit,
}) => {
  // Hooks
  const { companies, isLoading: isLoadingCompanies } = useCompanies();
  const initialValues = useMemo(
    () => ({
      company: { _id: "0", name: "None" },
    }),
    []
  );

  const handleSubmit: FormikConfig<typeof initialValues>["onSubmit"] = (
    formData,
    { setFieldError }
  ) => {
    if (formData.company._id === "0") return setFieldError("company", "Invalid company");

    onSubmit({ id: formData.company._id });
  };

  return (
    <Box mt={2}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <CustomAutocomplete
              name="company"
              label="Company"
              variant="outlined"
              size="small"
              options={isLoadingCompanies ? [] : [{ _id: "0", name: "None" }, ...companies]}
              getOptionLabel={(opt: Company) => opt.name}
              getOptionSelected={(option, value) => option._id === value._id}
              loading={isLoadingCompanies}
              disabled={isLoading}
            />

            <Box mt={2}>
              <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
                Submit
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
