import React from "react";
// Material ui
import { TextField, TextFieldProps } from "@material-ui/core";
// Formik
import { FieldAttributes, useField } from "formik";

type CustomTextField = TextFieldProps & FieldAttributes<{}>;

const CustomTextField: React.FC<CustomTextField> = ({ helperText, ...props }) => {
  const [field, meta] = useField<{}>(props);
  const errorText = meta.error && meta.touched ? meta.error : "";

  return (
    <TextField {...props} {...field} helperText={errorText || helperText} error={!!errorText} />
  );
};

export default CustomTextField;
