import React from "react";
import { Redirect, useParams } from "react-router-dom";
// Material ui
import { Box } from "@material-ui/core";
// Components
import Loading from "components/Loading";
// Hooks
import { useQrCode } from "hooks/useQrCode";
import { ErrorAlert } from "components/ErrorAlert";

export const QrCodeAction: React.FC = () => {
  // Hooks
  const { qrCodeId } = useParams<{ qrCodeId: string }>();
  const { qrCode, isLoading, error } = useQrCode(qrCodeId);

  if (isLoading) return <Loading message="Loading QR code" />;

  if (error) {
    if (error.status === 403) return <Redirect to="/dashboard" />;

    return (
      <Box p={4}>
        <ErrorAlert>{error.message}</ErrorAlert>
      </Box>
    );
  }

  if (qrCode.status === "disabled") return <Redirect to="/dashboard/qrcodes" />;

  if (qrCode.status === "active" && qrCode.action) {
    return <Redirect to={qrCode.action.prepared?.url ?? "/dashboard"} />;
  }

  return <Redirect to={`/dashboard/qrcodes/${qrCodeId}/edit`} />;
};
