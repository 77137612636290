import { QrCodeStatus } from "../@types/QrCode";

interface QrCodeStatusValue {
  name: QrCodeStatus;
  displayName: string;
}

export function qrCodeStatuses(): Record<QrCodeStatus, QrCodeStatusValue>;
export function qrCodeStatuses(status: QrCodeStatus): QrCodeStatusValue;
export function qrCodeStatuses(status?: QrCodeStatus) {
  const statuses: Record<QrCodeStatus, QrCodeStatusValue> = {
    active: { name: "active", displayName: "Active" },
    pending: { name: "pending", displayName: "Pending" },
    disabled: { name: "disabled", displayName: "Disabled" },
  };

  return status ? statuses[status] : statuses;
}
