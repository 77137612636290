import React, { useState, useEffect, useContext } from "react";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
// Formik
import { Formik, Form, FormikHelpers } from "formik";
// Material ui
import { DialogContent, DialogActions, Button } from "@material-ui/core";
// Components
import DialogBasic from "./DialogBasic";
import CustomTextField from "../Formik/CustomTextField";
import CustomCheckbox from "../Formik/CustomCheckbox";
// Utils
import { Company } from "../../@types/company";
import useLocalStorage from "../../hooks/useLocalStorage";
import api from "../../utils/api";
import { AxiosResponse } from "axios";
import CompanyContext from "../../context/CompanyContext";
import useToast from "../../hooks/useToast";
import useRequestErrorHandler from "../../hooks/useRequestErrorHandler";
import DataContext from "../../context/DataContext";

interface Props {
  open: boolean;
  onClose: () => void;
  company?: Company;
}

const FormCompany: React.FC<Props> = ({ open, onClose, company }) => {
  // Hooks
  const [token] = useLocalStorage("token");
  const history = useHistory();
  const companyCtx = useContext(CompanyContext);
  const { companies, setCompanies } = useContext(DataContext);
  const toast = useToast();
  const handleRequestErrors = useRequestErrorHandler();

  // States
  const [isEditing, setIsEditing] = useState(false);

  const initialValues = {
    name: company?.name || "",
    limitDevices: company?.maxDevices !== undefined && company?.maxDevices !== null,
    maxDevices: company?.maxDevices ?? 50,
  };

  const minDevices = companyCtx?.totalDevices || 1;
  const validationSchema = yup.object({
    name: yup.string().required("Company name is required"),
    limitDevices: yup.boolean().required(),
    maxDevices: yup.mixed().when("limitDevices", {
      is: true,
      then: yup
        .number()
        .integer()
        .min(minDevices, `The value must be greater than or equal to ${minDevices}`)
        .required('Max devices is required if "Limit Devices" is selected'),
    }),
  });

  useEffect(() => setIsEditing(company ? true : false), [company]);

  // Send request to create/edit a company
  const handleSubmit = async (
    data: typeof initialValues,
    { setSubmitting }: FormikHelpers<typeof initialValues>
  ) => {
    setSubmitting(true);
    const requestData = {
      name: data.name,
      maxDevices: data.limitDevices ? data.maxDevices : null,
    };

    try {
      let responseData: AxiosResponse<any>;
      const requestConfig = { headers: { Authorization: `Bearer ${token}` } };

      if (!isEditing) {
        responseData = await api.post("/company", requestData, requestConfig);
      } else {
        responseData = await api.put(`/company/${company?._id}`, requestData, requestConfig);
      }

      const { company: responseCompany } = responseData.data;
      setCompanies(
        !isEditing
          ? [...companies, responseCompany]
          : companies.map((comp) => (comp._id === company?._id ? responseCompany : comp))
      );

      toast({
        open: true,
        type: "success",
        message: `Company successfully ${!isEditing ? "created" : "edited"}`,
      });

      history.push(`/dashboard/company/${responseCompany._id}`);
      onClose();
    } catch (err) {
      handleRequestErrors(err);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <DialogBasic
        open={open}
        onClose={onClose}
        title={`${!isEditing ? "Add company" : `Edit ${company?.name}`}`}
        widthSize="md"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values }) => (
            <Form>
              <DialogContent>
                <CustomTextField
                  name="name"
                  label="Company name"
                  fullWidth
                  required
                  disabled={isSubmitting}
                />

                <div className="d-flex flex-wrap align-items-center mt-3">
                  <CustomCheckbox
                    name="limitDevices"
                    label="Limit devices"
                    disabled={isSubmitting}
                    color="primary"
                    labelClassName="mb-0"
                  />

                  {values.limitDevices && (
                    <CustomTextField
                      name="maxDevices"
                      label="How many devices"
                      type="number"
                      required
                      disabled={isSubmitting}
                      inputProps={{ min: 0 }}
                      size="small"
                      className="flex-grow-1"
                    />
                  )}
                </div>
              </DialogContent>

              <DialogActions>
                <Button onClick={onClose}>Cancel</Button>

                <Button type="submit" disabled={isSubmitting}>
                  {!isEditing ? "Add" : "Edit"}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogBasic>
    </>
  );
};

export default FormCompany;
