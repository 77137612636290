import React, { useState } from "react";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
// Material ui
import { DialogContent, DialogActions, Button, CircularProgress } from "@material-ui/core";
// Components
import DialogBasic from "./DialogBasic";
import UserActivitiesPDF from "../UserActivitiesPDF";
import AutoCompleteVirtualized from "../AutoCompleteVirtualized";
// Utils
import { Activity } from "../../@types/computer";
import { User } from "../../@types/users";
import api from "../../utils/api";
import useLocalStorage from "../../hooks/useLocalStorage";
import { exportDataToCsv } from "../../utils/functions";
import useToast from "../../hooks/useToast";
import useRequestErrorHandler from "../../hooks/useRequestErrorHandler";

interface Props {
  open: boolean;
  onClose: () => void;
  users: User[];
  formatType: "csv" | "pdf";
}

const fetchUserActivities = async (
  userId: string,
  token: string
): Promise<{ success: boolean; activities: Activity[] }> => {
  const { data } = await api.get(`/userActivity/getAllTimeActivities/${userId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return data;
};

const SelectUserModal: React.FC<Props> = ({ open, onClose, users, formatType }) => {
  // Hooks
  const [token] = useLocalStorage("token");
  const toast = useToast();
  const handleRequestErrors = useRequestErrorHandler();

  // States
  const [userInput, setUserInput] = useState<{ autoComplete: null | User; input: string }>({
    autoComplete: null,
    input: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadBtn = async () => {
    setIsLoading(true);
    let isValidToken = true;

    try {
      const { _id: id, name } = userInput.autoComplete!;
      const { activities } = await fetchUserActivities(id, token);

      if (activities.length <= 0) {
        return toast({
          open: true,
          type: "warning",
          message: "The user doesn't have any activities saved yet",
        });
      }

      if (formatType === "csv") {
        downloadCSV(activities, name);
      } else {
        const pdfBlob = await pdf(UserActivitiesPDF(userInput.autoComplete!, activities)).toBlob();

        saveAs(pdfBlob, `${name} - Activities.pdf`);
      }
    } catch (err) {
      handleRequestErrors(err);
    } finally {
      if (isValidToken) {
        setIsLoading(false);
      }
    }
  };

  const downloadCSV = (activities: Activity[], userName: string) => {
    const headers = ["User", "Resource", "Action", "Company", "Timestamp"];
    const data = activities.map((activity) => {
      const { user, resource, action, company, timestamp } = activity;

      const companyValue = company?.name ?? "System";

      return [user?.name, resource, action, companyValue, timestamp];
    });

    exportDataToCsv(headers, data, `${userName} - Activities`);
  };

  return (
    <>
      <DialogBasic open={open} onClose={onClose} title="Select a user" widthSize="md">
        <DialogContent>
          <AutoCompleteVirtualized
            label="Users"
            value={userInput}
            setInput={setUserInput}
            disabled={isLoading}
            options={users}
            getOptionLabel={(user) => user.email}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>

          <Button onClick={handleDownloadBtn} disabled={isLoading}>
            Download {formatType}
          </Button>

          {isLoading && <CircularProgress />}
        </DialogActions>
      </DialogBasic>
    </>
  );
};

export default SelectUserModal;
