import { useEffect, useRef } from "react";
import { io, Socket } from "socket.io-client";
import { backend } from "../utils/configs";
import useCompanyPage from "./useCompanyPage";
import useLocalStorage from "./useLocalStorage";

const useSocket = () => {
  // Hooks
  const socket = useRef<Socket | null>(null);
  const [token] = useLocalStorage("token");
  const company = useCompanyPage();

  useEffect(() => {
    if (!socket.current?.connected && company) {
      socket.current = io(backend.url, { query: { bearerToken: token, companyId: company._id } });
    }

    return () => {
      socket.current?.disconnect();
    };
  }, [company, token]);

  return socket;
};

export default useSocket;
