import React, { useEffect, useState, useContext, useRef } from "react";
import copy from "copy-to-clipboard";
// Material ui
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  DialogContent,
  TableBody,
  Button,
  SvgIcon,
  Typography,
  DialogActions,
  Tooltip,
} from "@material-ui/core";
// Icons
import { ReactComponent as CopyContent } from "../../assets/copy-content.svg";
import { Delete } from "@material-ui/icons";
// Components
import DialogBasic from "./DialogBasic";
import Loading from "../Loading";
import ConfirmationModal from "./Confirmation";
import Can from "../Can";
// Utils
import api from "../../utils/api";
import useCompanyPage from "../../hooks/useCompanyPage";
import useLocalStorage from "../../hooks/useLocalStorage";
import CompanyContext from "../../context/CompanyContext";
import DataContext from "../../context/DataContext";
import useRequestErrorHandler from "../../hooks/useRequestErrorHandler";

interface Props {
  open: boolean;
  onClose: () => void;
}

interface CopyCodeButtonProps {
  onClick: () => void;
}

interface PendingCodeRes {
  id: string;
  company: string;
  timestamp: number;
}

const getPendingCodes = async (companyId: string, token: string) => {
  const { data } = await api.get(`/devices/pendingCodes/${companyId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return data.codes;
};

const CopyCodeButton: React.FC<CopyCodeButtonProps> = ({ onClick }) => {
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleClick = () => {
    if (!openTooltip) {
      setOpenTooltip(true);
    }

    onClick();
  };
  return (
    <Tooltip
      title="Copied!"
      open={openTooltip}
      onClose={() => setOpenTooltip(false)}
      placement="top"
      arrow
      leaveDelay={1000}
      interactive
    >
      <Button
        color="primary"
        variant="contained"
        size="small"
        className="mr-2"
        onClick={handleClick}
      >
        <SvgIcon>
          <CopyContent />
        </SvgIcon>
      </Button>
    </Tooltip>
  );
};

const PendingCodes: React.FC<Props> = ({ open, onClose }) => {
  // Hooks
  const company = useCompanyPage();
  const [token] = useLocalStorage("token");
  const { setTotalDevices, setTotalPendingCodes } = useContext(CompanyContext);
  const { user } = useContext(DataContext);
  const handleRequestErrors = useRef(useRequestErrorHandler());

  // States
  const [pendingCodes, setPendingCodes] = useState<PendingCodeRes[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    open: false,
    title: "",
    selectedCode: "",
    message: <></>,
  });
  const tableHeaders = ["Code", "Generated at", "Actions"];

  useEffect(() => {
    const fetchCodes = async () => {
      setLoading(true);

      try {
        setPendingCodes(await getPendingCodes(company._id, token));
      } catch (err) {
        handleRequestErrors.current(err);
      } finally {
        setLoading(false);
      }
    };

    if (open) {
      fetchCodes();
    }
  }, [company._id, open, token]);

  // Copy the computer code to the clipboard
  const copyCode = (code: string) => {
    copy(code);
  };

  // Close confirmation modal
  const closeConfirmationModal = () => setConfirmationModal({ ...confirmationModal, open: false });

  // Open confirmation dialog to confirm the delete operation
  const confirmDelete = (code: string) => {
    setConfirmationModal({
      open: true,
      title: "Delete activation code",
      selectedCode: code,
      message: (
        <span>
          Would you like to delete the code <strong>{code}</strong>
        </span>
      ),
    });
  };

  const deleteCode = async (code: string) => {
    setLoadingDelete(true);

    try {
      const reqConfigs = { headers: { Authorization: `Bearer ${token}` } };

      const { data } = await api.delete(`/devices/pendingCodes/${code}`, reqConfigs);

      setPendingCodes(data.pendingCodes);
      // @ts-ignore
      setTotalDevices((oldVal: number) => oldVal - 1);
      // @ts-ignore
      setTotalPendingCodes((oldVal: number) => oldVal - 1);
      closeConfirmationModal();
    } catch (err) {
      handleRequestErrors.current(err);
    } finally {
      setLoadingDelete(false);
    }
  };

  return (
    <>
      <DialogBasic open={open} onClose={onClose} title="Activation codes">
        <DialogContent>
          {loading ? (
            <Loading message="Loading codes" />
          ) : pendingCodes.length <= 0 ? (
            <Typography variant="h5" className="text-center mt-3">
              No code found
            </Typography>
          ) : (
            <TableContainer className="MuiPaper-outlined MuiPaper-rounded">
              <Table>
                <TableHead>
                  <TableRow>
                    {tableHeaders.map((head) => (
                      <TableCell key={head}>
                        <strong>{head}</strong>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {pendingCodes.map((code) => (
                    <TableRow key={code.id}>
                      <TableCell component="th" scope="row">
                        {code.id}
                      </TableCell>
                      <TableCell>{new Date(code.timestamp).toLocaleString()}</TableCell>
                      <TableCell>
                        <CopyCodeButton onClick={() => copyCode(code.id)} />

                        <Can role={user.role} perform="devices:delete">
                          <Button
                            color="secondary"
                            variant="contained"
                            size="small"
                            onClick={() => confirmDelete(code.id)}
                          >
                            <Delete />
                          </Button>
                        </Can>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>

        <DialogActions>
          <Button color="primary" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </DialogBasic>

      {/* Confirmation Modal */}
      <ConfirmationModal
        open={confirmationModal.open}
        onClose={closeConfirmationModal}
        title={confirmationModal.title}
        message={confirmationModal.message}
        onYes={() => deleteCode(confirmationModal.selectedCode)}
        yesDisabled={loadingDelete}
        yesLoading={loading}
      />
    </>
  );
};

export default PendingCodes;
