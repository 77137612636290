import React from "react";
import { formatDuration } from "date-fns";
// Material ui
import { Grid, makeStyles, Icon, Divider } from "@material-ui/core";
// Components
import DisplayInfo from "../../DisplayInfo";
import DisplayConnectorVersion from "../../DisplayConnectorVersion";
import DisplayComputerComments from "./DisplayComputerComments";
import CustomCollapse from "../../CustomCollapse";
import ProgressBar from "../../ProgressBar";
// Utils
import { Computer } from "../../../@types/computer";
import { Domain, Update } from "@material-ui/icons";
import { getSize, getSpacePercentage } from "../../../utils/functions";

interface Props {
  computer: Computer;
}

const useStyles = makeStyles((theme) => ({
  info: {
    minHeight: "48px",
  },
  disksLine: {
    marginBottom: "8px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "8px",
    },
  },
  line: {
    margin: "12px 0 8px 0",
  },
}));

const formatUptime = (duration) => {
  const hourInSeconds = 3600;
  const days = Math.floor(duration / (hourInSeconds * 24));
  const hours = Math.floor((duration % (hourInSeconds * 24)) / hourInSeconds);
  const minutes = Math.floor((duration % hourInSeconds) / 60);

  return formatDuration({ days, hours, minutes });
};

const SystemInfo: React.FC<Props> = ({ computer }) => {
  // Hooks
  const classes = useStyles();

  return (
    <>
      <div className="container">
        <Grid container spacing={1} justify="space-between" alignItems="center">
          <Grid item md={4}>
            <DisplayInfo
              title="Computer name"
              message={computer.computerName}
              className={classes.info}
              icon={<Icon className="fas fa-tag" style={{ fontSize: "1.25em" }} />}
            />
          </Grid>

          <Grid item md={4}>
            <DisplayInfo
              title="OS"
              message={computer.os}
              className={classes.info}
              icon={<Icon className="fab fa-windows" style={{ fontSize: "1.25em" }} />}
            />
          </Grid>

          <Grid item md={4}>
            <DisplayInfo
              title="CPU"
              message={computer.processor}
              icon={<Icon className="fas fa-microchip" style={{ fontSize: "1.25em" }} />}
              className={classes.info}
            />
          </Grid>

          <Grid item md={4}>
            <DisplayInfo
              title="Domain"
              message={computer.domain}
              icon={<Domain />}
              className={classes.info}
            />
          </Grid>

          <Grid item md={4}>
            <DisplayInfo
              title="Serial number"
              message={computer.serial}
              icon={<Icon className="fas fa-fingerprint" style={{ fontSize: "1.25em" }} />}
              secret
            />
          </Grid>

          {computer.computerUptime && (
            <Grid item md={4}>
              <DisplayInfo
                title="Up time"
                message={formatUptime(computer.computerUptime)}
                icon={<Update />}
              />
            </Grid>
          )}

          <Grid item md={4}>
            <DisplayConnectorVersion computer={computer} displayIcon={true} />
          </Grid>
        </Grid>

        <DisplayComputerComments computer={computer} />

        <Divider className={classes.disksLine} />

        {computer.cpu && (
          <>
            <CustomCollapse title="CPU" className="p-2">
              <ProgressBar title="Utilization" percentage={computer.cpu.usage} />

              <div className="d-flex flex-wrap justify-content-between">
                <div>
                  <DisplayInfo title="Brand" message={computer.cpu.brand} />

                  <DisplayInfo title="Manufacturer" message={computer.cpu.manufacturer} />

                  <DisplayInfo title="Vendor" message={computer.cpu.vendor} />
                </div>

                <div>
                  {computer.cpu.cores && (
                    <DisplayInfo title="Cores" message={computer.cpu.cores.toString()} />
                  )}

                  {computer.cpu.physicalCores && (
                    <DisplayInfo
                      title="Physical cores"
                      message={computer.cpu.physicalCores.toString()}
                    />
                  )}
                </div>

                <div>
                  {Object.entries(computer.cpu.cache).map(
                    (cache, index) =>
                      parseInt(cache[1]) !== 0 && (
                        <DisplayInfo
                          key={index}
                          title={`${cache[0].toUpperCase()} cache`}
                          message={getSize(parseFloat(cache[1]))}
                        />
                      )
                  )}
                </div>
              </div>
            </CustomCollapse>

            <Divider className={classes.line} />
          </>
        )}

        <CustomCollapse title="Disks" className="p-2">
          {computer.disks.map((disk, index) => (
            <ProgressBar
              key={index}
              title={`${disk.fs} - ${getSize(disk.size - disk.used)} free of ${getSize(disk.size)}`}
              percentage={getSpacePercentage(disk.used, disk.size)}
            />
          ))}
        </CustomCollapse>

        <Divider className={classes.line} />

        <CustomCollapse title="Memory" className="p-2">
          <ProgressBar
            title={`Using ${getSize(computer.memory.used)} of ${getSize(computer.memory.total)}`}
            percentage={getSpacePercentage(computer.memory.used, computer.memory.total)}
          />
        </CustomCollapse>
      </div>
    </>
  );
};

export default SystemInfo;
