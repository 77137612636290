import React, { useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
// Material ui
import { makeStyles, Typography, Divider, Button } from "@material-ui/core";
// Icons
import { Business, Group } from "@material-ui/icons";
// Components
import UserGroupsForm from "../Modals/UserGroupsForm";
import Header from "./Header";
import UsersTable from "../Tables/UsersTable";
import CompaniesTable from "../Tables/CompaniesTable";
import InfoCard from "../InfoCard";
import UserForm from "../Modals/UserForm";
// Utils
import UserGroupContext from "../../context/UserGroupsContext";
import { User } from "../../@types/users";

const useStyles = makeStyles((theme) => ({
  noGroupRoot: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  groupRoot: {
    width: "100%",
    padding: theme.spacing(2),
  },
  middleLine: {
    width: "80%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

const Content: React.FC = () => {
  // Hooks
  const classes = useStyles();
  const { id, type } = useParams<{ id: string; type: string }>();
  const history = useHistory();
  const {
    state: { selectedGroup },
    dispatch,
  } = useContext(UserGroupContext);

  // States
  const [userGroupModal, setUserGroupModal] = useState(false);
  const [userFormModal, setUserFormModal] = useState<{ open: boolean; user?: User }>({
    open: false,
    user: undefined,
  });

  const handleEditUserSubmit = (newUsers: User[]) => {
    const currentUsersId = selectedGroup.users.map((user) => user._id);

    dispatch({
      type: "UPDATE_USERS_LIST",
      payload: newUsers.filter((user) => currentUsersId.includes(user._id)),
    });
  };

  return (
    <>
      {!id && !type ? (
        <div className={classes.noGroupRoot}>
          <Typography variant="h5" className="text-center">
            Click in a group to see more information about it
          </Typography>

          <Divider className={classes.middleLine} />

          <Button variant="outlined" onClick={() => setUserGroupModal(true)}>
            Add Group
          </Button>
        </div>
      ) : (
        <div className={classes.groupRoot}>
          <Header />
          {type ? (
            type === "users" ? (
              // Users page
              <div className="mt-4 px-4">
                <UsersTable
                  data={selectedGroup.users}
                  loading={selectedGroup.loading}
                  onEdit={(user) => setUserFormModal({ open: true, user })}
                />
              </div>
            ) : (
              // Companies page
              <div className="mt-4 px-4">
                <CompaniesTable data={selectedGroup.companies} loading={selectedGroup.loading} />
              </div>
            )
          ) : (
            // Dashboard page
            <div className="d-flex flex-row flex-wrap justify-content-center text-center mt-3">
              <InfoCard
                icon={Business}
                title={<Typography variant="h6">{selectedGroup.companies.length}</Typography>}
                subtitle="Companies"
                type="info"
                onClick={() => history.push(`/dashboard/users/groups/${id}/companies`)}
              />

              <InfoCard
                icon={Group}
                title={<Typography variant="h6">{selectedGroup.users.length}</Typography>}
                subtitle="Users"
                type="info"
                onClick={() => history.push(`/dashboard/users/groups/${id}/users`)}
              />
            </div>
          )}
        </div>
      )}

      <UserGroupsForm open={userGroupModal} onClose={() => setUserGroupModal(false)} />

      <UserForm
        open={userFormModal.open}
        onClose={() => setUserFormModal({ open: false, user: undefined })}
        user={userFormModal.user}
        onSubmitSuccess={handleEditUserSubmit}
      />
    </>
  );
};

export default Content;
